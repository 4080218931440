import { apiPost } from '../../apis/api';
import { REGIST_RECEPTION } from './types';

export const registReception = (data) => {
    const request = apiPost("/chargerSetup", data).then(response => response);
   
    return {
        type: REGIST_RECEPTION,
        payload: request
    }
}