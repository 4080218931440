/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as themes from '../../styles/themes';
import * as schema from '../../validations/schemas/RegisterSchema';
import SEOULEV_LOGO from '../../assets/images/logo.png';
import AUTHENTICATION_IMG from '../../assets/images/authentication_img.png';
import ARROW from '../../assets/images/arrow.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalAddress from '../../components/Modal/ModalAddress';
import { useDispatch } from 'react-redux';
import { ResponseResult } from '../../apis/response';
import { notFoundPolicy, paycardStoreFailAlertMessage, serverErrAlertMessage } from '../../validations/messages/messages';
import { useEffect } from 'react';
import { confirmLgnId, registUser } from '../../stores/actions/user_action';
import { getCarList } from '../../stores/actions/car_action';
import { background_gray1, black, button_text, inputbox_stroke, stroke_gray1, white } from '../../styles/colors';
import { DESKTOP, dt_point, dt_point_up, MOBILE, register_point, tb_point } from '../../styles/media-queries';
import ModalAlert from '../../components/Modal/ModalAlert';
import ModalPolicy from '../../components/Modal/ModalPolicy';
import { ediDateNoFormat, makeEncryptData, makeMoid, TodayFormatYYYYMMDDHHmmssSSS, todayNoFoarmat } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { registUserDone, registUserIng } from '../../stores/actions/data_action';
import { useSelector } from 'react-redux';
import { getPolicy } from '../../stores/actions/policy_action';
import CHECKBOX_TRUE from '../../assets/images/checkbox_true.png';
import CHECKBOX_FALSE from '../../assets/images/checkbox.png';
import NavBar from '../../components/NavBar/NavBar';

function RegisterPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const smartropay = window.smartropay;
    const { state, search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [PaycardResult, setPaycardResult] = useState(null);
    const regist_data = useSelector(state => state.data);

    // 약관
    const [Service, setService] = useState("");
    // const [Privacy, setPrivacy] = useState("");
    const [PrivacyAgree, setPrivacyAgree] = useState("");
    const [ServiceLocation, setServiceLocation] = useState("");
    const [Promotion, setPromotion] = useState("");


    const [PolicyStatus, setPolicyStatus] = useState("")
    const [ModalPolicyShow, setModalPolicyShow] = useState(false);
    const [policyTitle, setPolicyTitle] = useState("");
    const [ModalContent, setModalContent] = useState("");
    const [Moid, setMoid] = useState("");
    const [EdiDate, setEdiDate] = useState("");
    const [EncryptData, setEncryptData] = useState("");

    // 주소 검색
    const [modalAddressShow, setModalAddressShow] = useState(false);

    const [StipulrationCheckList, setStipulrationCheckList] = useState([false, false, false, false, false]); // 약관
    const [StipulationId, setStipulationId] = useState([null, null, null, null]); // 약관 ID
    const [AllCheck, setAllCheck] = useState(false); // 모두 동의
    const [ConfirmCss, setConfirmCss] = useState([authenticationWrap]);
    const [StipulationCss, setStipulationCss] = useState([stipulationWrap, themes.hide]);
    const [FormCss, setFormCss] = useState([registerFormStyle, themes.hide]);

    const [modalShow, setModalShow] = useState(false);
    const [ResMsg, setResMsg] = useState();

    const [KgState, setKgState] = useState(false);
    const [KgName, setKgName] = useState("");
    const [KgPhone, setKgPhone] = useState("");
    const [UserMallId, setUserMallId] = useState("");

    // console.log("KgPhone : " + KgPhone);

    // console.log(FormCss[1].styles);

    // 차량 정보
    const [CarCompany, setCarCompany] = useState([]);
    const [Car, setCar] = useState([]);
    const [CarModel, setCarModel] = useState([]);
    const [Loaded, setLoaded] = useState([false, false]);

    const { handleSubmit, register, formState: { errors, isValid }, getValues, setValue } = useForm({
        resolver: yupResolver(schema.registerValidation),
        mode: 'onChange',
    });

    const { MCASH_PAYMENT } = window;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://auth.mobilians.co.kr/js/ext/ext_inc_comm.js";
        script.async = false;
        script.addEventListener('load', () => setLoaded([true, Loaded[1]]));

        const script2 = document.createElement('script');
        script2.src = "https://pay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=" + todayNoFoarmat();
        script2.async = false;
        script2.addEventListener('load', () => setLoaded([true, true]));
        document.body.appendChild(script);
        document.body.appendChild(script2);
    }, []);

    useEffect(() => {
        if (!Loaded[0] || !Loaded[1]) {
            return;
        } else {
            const moid = makeMoid();
            // console.log(moid);
            const ediDate = ediDateNoFormat();

            setMoid(moid);
            setEdiDate(ediDate);
            setEncryptData(makeEncryptData(ediDate, moid));
        }
    }, [Loaded]);

    useEffect(() => {

        if (search !== "") {
            let result = searchParams.get("result");
            if (result) {
                let result_json = JSON.parse(result);

                if ("Resultcd" in result_json) {
                    // 본인인증 응답이 온 경우  
                    // console.log(result_json);
                    // console.log(result_json.Resultmsg)
                } else if ("ResultCode" in result_json) {

                    // 결제카드 등록 응답이 온 경우
                    window.history.replaceState({}, null, window.location.pathname);
                    // console.log(regist_data.data);
                    setConfirmCss(themes.hide);
                    setStipulationCss([stipulationWrap, themes.hide]);
                    setFormCss([registerFormStyle, themes.show]);

                    dispatch(getCarList()).then(
                        response => {
                            if (response.payload.result === ResponseResult.OK) {
                                setCar(response.payload.data);
                                let carCompanyArray = [];
                                response.payload.data.map((obj, index) => {
                                    if (carCompanyArray.indexOf(obj.nm) === -1) {
                                        carCompanyArray.push(obj.nm);
                                    }
                                });

                                setCarCompany(carCompanyArray);
                                setPaycardResult(JSON.parse(result));

                                if (Object.keys(regist_data).length > 0) {
                                    setKgPhone(regist_data.data.phone);
                                    setKgName(regist_data.data.nm);

                                    setValue("LgnId", regist_data.data.lgnId, { shouldValidate: true });
                                    setValue("LgnIdConfirm", regist_data.data.LgnIdConfirm, { shouldValidate: true });
                                    setValue("Password", regist_data.data.lgnPassword, { shouldValidate: true });
                                    setValue("PasswordConfirm", regist_data.data.lgnPasswordConfirm, { shouldValidate: true });
                                    setValue("Phone", regist_data.data.phone, { shouldValidate: true });
                                    setValue("Rdnmadr", regist_data.data.rdnmadr, { shouldValidate: true });
                                    setValue("Adres", regist_data.data.adres, { shouldValidate: true });
                                    setValue("AdresDetail", regist_data.data.adresDetail, { shouldValidate: true });
                                    setValue("ZipCode", regist_data.data.zipCode, { shouldValidate: true });
                                    setValue("Email", regist_data.data.email, { shouldValidate: true });
                                    setValue("Nm", regist_data.data.nm, { shouldValidate: true });
                                    setValue("CarCompanyName", regist_data.data.carCompanyName, { shouldValidate: true });
                                    if (regist_data.data.carCompanyName !== "null") {
                                        // console.log(regist_data.data.carCompanyName);
                                        setCarModel(response.payload.data.filter((item, index) => {
                                            if (item.nm === regist_data.data.carCompanyName) {
                                                return item.nm === regist_data.data.carCompanyName;
                                            }
                                        }));
                                    }
                                    setValue("CarModelId", regist_data.data.carModelId, { shouldValidate: true });
                                    setValue("CarNo", regist_data.data.carNo, { shouldValidate: true });
                                    setUserMallId(regist_data.data.mallUserId);
                                    setStipulrationCheckList([
                                        regist_data.data.agreeStipulation[0].agreeYn === "Y" ? true : false,
                                        regist_data.data.agreeStipulation[1].agreeYn === "Y" ? true : false,
                                        regist_data.data.agreeStipulation[2].agreeYn === "Y" ? true : false,
                                        regist_data.data.agreeStipulation[3].agreeYn === "Y" ? true : false
                                    ]);
                                    setStipulationId([
                                        regist_data.data.agreeStipulation[0].stipulationId,
                                        regist_data.data.agreeStipulation[1].stipulationId,
                                        regist_data.data.agreeStipulation[2].stipulationId,
                                        regist_data.data.agreeStipulation[3].stipulationId,
                                    ]);
                                }

                                dispatch(registUserDone());
                            } else {
                                alert(response.payload.msg);
                            }
                        }
                    )
                }
            } else {
                let kg_result = searchParams.get("kg");
                // searchParams.delete("kg");

                window.history.replaceState({}, null, window.location.pathname);

                if (kg_result) {
                    let kg_result_json = JSON.parse(kg_result);
                    // console.log(kg_result_json);

                    if (kg_result_json.Resultcd === "0000") {
                        // 본인인증 성공
                        setKgState(true);
                        setKgName(kg_result_json.Name);
                        setKgPhone(kg_result_json.No);
                    } else {
                        alert(kg_result_json.Resultmsg);
                    }
                }
            }
        }
    }, [state]);

    useEffect(() => {
        // 본인인증 성공시
        if (KgState) {
            const loadPolicyAsync = async () => {
                try {
                    let getService = await dispatch(getPolicy("이용약관"));
                    let getPrivacyAgree = await dispatch(getPolicy("개인정보 수집 및 이용 동의"));
                    let getServiceLocation = await dispatch(getPolicy("위치기반서비스 이용약관"));
                    let getPromotion = await dispatch(getPolicy("마케팅 정보 수신 동의"));

                    setService(getService.payload.data.cn ? getService.payload.data.cn : "-");
                    setPrivacyAgree(getPrivacyAgree.payload.data.cn ? getPrivacyAgree.payload.data.cn : "-");
                    setServiceLocation(getServiceLocation.payload.data.cn ? getServiceLocation.payload.data.cn : "-");
                    setPromotion(getPromotion.payload.data.cn ? getPromotion.payload.data.cn : "-");
                    setStipulationId(getService.payload.data.id, getPrivacyAgree.payload.data.id, getServiceLocation.payload.data.id, getPromotion.payload.data.id);
                } catch (e) {
                    // console.log(e);
                    alert(serverErrAlertMessage);
                }
            }
            loadPolicyAsync();
            setConfirmCss(themes.hide);
            setStipulationCss([stipulationWrap, themes.show]);
        }
    }, [KgState]);


    useEffect(() => {
        if (PolicyStatus.indexOf(ResponseResult.FAIL) > -1) {
            alert(notFoundPolicy);
            navigate(-1);
        }
    }, [PolicyStatus]);

    useEffect(() => {
        if (PaycardResult !== null) {
            if (PaycardResult.ResultCode === "3001") {
                // 성공 코드
                setValue("PaycardNo", PaycardResult.DisplayCardNo, { shouldValidate: true });
                setValue("PaycardCo", PaycardResult.IssuerCardNm, { shouldValidate: true });
                setValue("Billingkey", PaycardResult.BillTokenKey, { shouldValidate: true });
                setValue("Tid", PaycardResult.Tid, { shouldValidate: true });

                let reqParams = {
                    "id": parseInt(PaycardResult.MallUserId),
                    "paycardCo": PaycardResult.IssuerCardNm,
                    "paycardNo": PaycardResult.DisplayCardNo,
                    "billingkey": PaycardResult.BillTokenKey,
                    "tid": PaycardResult.Tid
                }
            } else {
                if (PaycardResult.ResultMsg) {
                    setResMsg(paycardStoreFailAlertMessage + " (" + PaycardResult.ResultMsg + ")");
                    setModalShow(true);
                }
            }
        }
    }, [PaycardResult]);

    const onChangeLoginIdHandler = (data) => {
        setValue("LgnId", data.target.value, { shouldValidate: true });
        setValue("LgnIdConfirm", "fail", { shouldValidate: true });
    }

    const addressModal = (modal) => {
        setModalAddressShow(modalAddressShow);
    };

    const onAddressModalHandler = (event) => {
        if (!modalAddressShow) {
            setModalAddressShow(true);
        }
    }

    // const requstPay = () => {
    //     MCASH_PAYMENT(document.payForm);
    // }

    const requstPay = () => {
        smartropay.init({
            mode: process.env.REACT_APP_SMARTROPAY_MODE,
            actionUri: '/ssb/interface.do'
        });

        smartropay.payment({
            FormId: 'paycardForm'
        });
    }

    const onClickPolicyHandler = (data, title) => {
        setPolicyTitle(title);
        setModalContent(data);
        setModalPolicyShow(true);
    }

    const onConfirmClickHadnler = (e) => {
        e.preventDefault();
        window.name = "op1";
        MCASH_PAYMENT(document.payForm);
    }

    const onAgreeClickHandler = () => {
        // 약관 동의 후 "다음" 버튼 클릭시 
        setUserMallId(TodayFormatYYYYMMDDHHmmssSSS());
        // console.log("UserMallId : " + UserMallId);
        dispatch(getCarList()).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    setCar(response.payload.data);
                    let carCompanyArray = [];
                    response.payload.data.map((obj, index) => {
                        if (carCompanyArray.indexOf(obj.nm) === -1) {
                            carCompanyArray.push(obj.nm);
                        }
                    });
                    setCarCompany(carCompanyArray);
                } else {
                    alert(response.payload.msg);
                }
            }
        )

        setStipulationCss([stipulationWrap, themes.hide]);
        setFormCss([registerFormStyle, themes.show]);
    }

    const onChangeCarCompanyHandler = (event) => {
        setCarModel(Car.filter((item, index) => {
            let cnt = 1;
            if (item.nm === event.currentTarget.value) {
                if (cnt === 1) {
                    setValue("CarModelId", item.id, { shouldValidate: true });
                }
                cnt++;
                return item.nm === event.currentTarget.value;
            }
        }));
    }

    const onClickLgnIdConfirmHandler = () => {
        // 아이디 중복확인 요청
        let lgnId = getValues("LgnId");

        if (lgnId === "") {
            alert("아이디를 입력해주세요.");
        } else {
            let reqParams = {
                "lgnId": lgnId
            }

            dispatch(confirmLgnId(reqParams)).then(
                response => {
                    // console.log(response);
                    setModalShow(true);
                    setResMsg(response.payload.msg);
                    if (response.payload.result === ResponseResult.OK) {
                        setValue("LgnIdConfirm", "ok", { shouldValidate: true });
                    } else {
                        // alert(response.payload.msg);
                    }
                }
            )
        }
    }

    const onClickPaycardHandler = () => {
        // 입력값들 확인
        // console.log("UserMallId : " + UserMallId);

        let reqParams = {
            "ty": "MEMBER",
            "lgnId": getValues("LgnId"),
            "LgnIdConfirm": getValues("LgnIdConfirm"),
            "lgnPassword": getValues("Password"),
            "lgnPasswordConfirm": getValues("PasswordConfirm"),
            "mallUserId": UserMallId,
            "phone": KgPhone,
            "rdnmadr": getValues("Rdnmadr"),
            "adres": getValues("Adres"),
            "adresDetail": getValues("AdresDetail"),
            "zipCode": getValues("ZipCode"),
            "email": getValues("Email"),
            "nm": KgName,
            "smsRcptnAgreYn": StipulrationCheckList[4] ? "Y" : "N",
            "emlRcptnAgreYn": StipulrationCheckList[4] ? "Y" : "N",
            "carCompanyName": getValues("CarCompanyName"),
            "carModelId": getValues("CarModelId"),
            "carNo": getValues("CarNo").replace(" ", ""),
            "agreeStipulation": [
                {
                    stipulationId: StipulationId[1],
                    agreeYn: StipulrationCheckList[1] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[2],
                    agreeYn: StipulrationCheckList[2] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[3],
                    agreeYn: StipulrationCheckList[3] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[4],
                    agreeYn: StipulrationCheckList[4] ? "Y" : "N"
                }
            ]
        }

        dispatch(registUserIng(reqParams));
        requstPay();
    }

    const onSubmitHandler = (data) => {
        let reqParams = {
            "ty": "MEMBER",
            "lgnId": data.LgnId,
            "lgnPassword": data.Password,
            "mallUserId": UserMallId,
            "phone": data.Phone,
            "rdnmadr": data.Rdnmadr,
            "adres": data.Adres,
            "adresDetail": data.AdresDetail,
            "zipCode": data.ZipCode,
            "email": data.Email,
            "nm": data.Nm,
            "smsRcptnAgreYn": StipulrationCheckList[3] ? "Y" : "N",
            "emlRcptnAgreYn": StipulrationCheckList[3] ? "Y" : "N",
            "carModelId": data.CarModelId,
            "carNo": data.CarNo.replace(" ", ""),
            "paycardCo": data.PaycardCo,
            "paycardNo": data.PaycardNo,
            "billingkey": data.Billingkey,
            "tid": data.Tid,
            "agreeStipulation": [
                {
                    stipulationId: StipulationId[1],
                    agreeYn: StipulrationCheckList[1] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[2],
                    agreeYn: StipulrationCheckList[2] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[3],
                    agreeYn: StipulrationCheckList[3] ? "Y" : "N"
                },
                {
                    stipulationId: StipulationId[4],
                    agreeYn: StipulrationCheckList[4] ? "Y" : "N"
                }
            ]
        }

        dispatch(registUser(reqParams)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    navigate("/register/complete", { state: { Nm: getValues("Nm"), LgnId: getValues("LgnId") } });
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }


    const onCheckHandler = (index) => {
        switch (index.target.id) {
            case "stipulation0":
                setStipulrationCheckList([!StipulrationCheckList[0], StipulrationCheckList[1], StipulrationCheckList[2], StipulrationCheckList[3], StipulrationCheckList[4]]);
                if (StipulrationCheckList[1] && StipulrationCheckList[2] && StipulrationCheckList[3] && StipulrationCheckList[4]) {
                    setAllCheck(true);
                }

                if (StipulrationCheckList[0] && AllCheck) {
                    setAllCheck(false);
                }
                break;
            case "stipulation1":
                setStipulrationCheckList([StipulrationCheckList[0], !StipulrationCheckList[1], StipulrationCheckList[2], StipulrationCheckList[3], StipulrationCheckList[4]]);
                if (StipulrationCheckList[0] && StipulrationCheckList[2] && StipulrationCheckList[3] && StipulrationCheckList[4]) {
                    setAllCheck(true);
                }

                if (StipulrationCheckList[1] && AllCheck) {
                    setAllCheck(false);
                }
                break;
            case "stipulation2":
                setStipulrationCheckList([StipulrationCheckList[0], StipulrationCheckList[1], !StipulrationCheckList[2], StipulrationCheckList[3], StipulrationCheckList[4]]);
                if (StipulrationCheckList[0] && StipulrationCheckList[2] && StipulrationCheckList[3] && StipulrationCheckList[4]) {
                    setAllCheck(true);
                }

                if (StipulrationCheckList[2] && AllCheck) {
                    setAllCheck(false);
                }
                break;
            case "stipulation3":
                setStipulrationCheckList([StipulrationCheckList[0], StipulrationCheckList[1], StipulrationCheckList[2], !StipulrationCheckList[3], StipulrationCheckList[4]]);
                if (StipulrationCheckList[0] && StipulrationCheckList[1] && StipulrationCheckList[3] && StipulrationCheckList[4]) {
                    setAllCheck(true);
                }

                if (StipulrationCheckList[3] && AllCheck) {
                    setAllCheck(false);
                }
                break;
            case "stipulation4":
                setStipulrationCheckList([StipulrationCheckList[0], StipulrationCheckList[1], StipulrationCheckList[2], StipulrationCheckList[3], !StipulrationCheckList[4]]);
                if (StipulrationCheckList[0] && StipulrationCheckList[1] && StipulrationCheckList[2] && StipulrationCheckList[3]) {
                    setAllCheck(true);
                }

                if (StipulrationCheckList[4] && AllCheck) {
                    setAllCheck(false);
                }
                break;
            case "all":
                setStipulrationCheckList([!AllCheck, !AllCheck, !AllCheck, !AllCheck, !AllCheck]);
                setAllCheck(!AllCheck);
                break;
            default:
                break;
        }
    }

    // 본인 인증이전 UI
    const confirmBox = (
        <Card css={ConfirmCss}>
            <Card.Body>
                <Link to="/">
                    <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" />
                </Link>
                <h1 css={authenticationTitle}>
                    회원가입
                </h1>
                <div>
                    <img src={AUTHENTICATION_IMG} alt="register_img" css={authenticationImgWrap} />
                    <div css={authenticationText}>
                        휴대전화 본인인증 후 회원가입을 시작합니다.
                    </div>

                    <Form id="payForm" onSubmit={onConfirmClickHadnler} name="payForm" method="post" acceptCharset='euc-kr'>
                        <input type="hidden" name="CASH_GB" id="CASH_GB" value="CI" />
                        <input type="hidden" name="CI_SVCID" id="CI_SVCID" value={process.env.REACT_APP_KG_SERVICE_ID} />
                        <input type="hidden" name="Cryptyn" id="Cryptyn" value="Y" />
                        <input type="hidden" name="Keygb" id="Keygb" value={1} />
                        <input type="hidden" name="CALL_TYPE" id="CALL_TYPE" value="P" />
                        <input type="hidden" name="LOGO_YN" id="LOGO_YN" value="N" />
                        <input type="hidden" name="CI_Mode" id="CI_Mode" value="61" />
                        <input type="hidden" name="DI_CODE" id="DI_CODE" value="<%=DI_CODE%>" />
                        <input type="hidden" name="Siteurl" id="Siteurl" value="<%=Siteurl%>" />
                        <input type="hidden" name="SUB_CPID" id="SUB_CPID" value="<%=SUB_CPID%>" />
                        <input type="hidden" name="Callback" id="Callback" size="30" value="<%=Callback%>" />
                        <input type="hidden" name="Smstext" id="Smstext" size="30" value="<%=Smstext%>" />
                        <input type="hidden" name="Lmstitle" id="Lmstitle" size="40" value="<%=Lmstitle%>" />
                        <input type="hidden" name="Tradeid" id="Tradeid" size="30" value="<%=Tradeid%>" />
                        <input type="hidden" name="PAY_MODE" id="PAY_MODE" size="30" value="10" />
                        <input type="hidden" name="Okurl" id="Okurl" size="30" value={process.env.REACT_APP_API_URL + "/users/register/result"} />
                        <input type="hidden" name="Cryptokurl" id="Cryptokurl" size="30" value="Y" />
                        <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                        <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                        <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
                        <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                        <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                        <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + "/users/register/result"} />
                        <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                        <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                        <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                        <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                        <div css={css`width: 200px; margin: 0 auto; height: 48px;`}>
                            <button type="submit" css={themes.BtnOutLineBlue}>본인 인증하기</button>
                        </div>
                    </Form>
                </div>
            </Card.Body>
        </Card>
    );

    // 약관 동의 UI
    const stipulationBox = (
        <Card css={StipulationCss}>
            <Card.Body css={DESKTOP}>
                <Link to="/">
                    <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" />
                </Link>
                <h1 css={stipulationTitle}>
                    SEOULEV 약관에 동의해주세요.
                </h1>

                <div className="agree_box">
                    <div className="policy all">
                        <input type="checkbox" id="all" name="all" onChange={onCheckHandler} checked={AllCheck} />
                        <label htmlFor="all" css={stipulationBoldText}>SEOULEV 약관에 모두 동의합니다.</label>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation0" name="stipulation0" onChange={onCheckHandler} checked={StipulrationCheckList[0]} />
                        <label htmlFor="stipulation0" css={stipulationText}>(필수) 만 14세 이상입니다.</label>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation1" name="stipulation1" onChange={onCheckHandler} checked={StipulrationCheckList[1]} />
                        <label htmlFor="stipulation1" css={stipulationText}>(필수) 이용약관</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(Service, "이용약관") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation2" name="stipulation2" onChange={onCheckHandler} checked={StipulrationCheckList[2]} />
                        <label htmlFor="stipulation2" css={stipulationText}>(필수) 개인정보 수집 및 이용 동의</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(PrivacyAgree, "개인정보 수집 및 이용 동의") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation3" name="stipulation3" onChange={onCheckHandler} checked={StipulrationCheckList[3]} />
                        <label htmlFor="stipulation3" css={stipulationText}>(필수) 위치기반서비스 이용약관</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(ServiceLocation, "위치기반서비스 이용약관") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>
                    <div className="policy">
                        <input type="checkbox" id="stipulation4" name="stipulation4" onChange={onCheckHandler} checked={StipulrationCheckList[4]} />
                        <label htmlFor="stipulation4" css={stipulationText}>(선택) 마케팅 정보 수신 동의</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(Promotion, "마케팅 정보 수신 동의") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>
                    <div css={css`width: 282px; height: 48px; margin: 0 auto; margin-top: 2rem;`}>
                        <button css={themes.BtnOutLineBlue} onClick={onAgreeClickHandler} disabled={(StipulrationCheckList[0] && StipulrationCheckList[1] && StipulrationCheckList[2] && StipulrationCheckList[3]) ? false : true}>다음</button>
                    </div>
                </div>
            </Card.Body>

            <Card.Body css={MOBILE}>
                <Link to="/">
                    <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" />
                </Link>
                <h1 css={stipulationTitle}>
                    SEOULEV 약관에 동의해주세요.
                </h1>

                <div css={AgreeBoxMobileWrap}>
                    <div className="policy all">
                        <input type="checkbox" id="all" name="all" onChange={onCheckHandler} checked={AllCheck} />
                        <label htmlFor="all" css={stipulationBoldText}>SEOULEV 약관에 모두 동의합니다.</label>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation0" name="stipulation0" onChange={onCheckHandler} checked={StipulrationCheckList[0]} />
                        <label htmlFor="stipulation0" css={stipulationText}>(필수) 만 14세 이상입니다.</label>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation1" name="stipulation1" onChange={onCheckHandler} checked={StipulrationCheckList[1]} />
                        <label htmlFor="stipulation1" css={stipulationText}>(필수) 이용약관</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(Service, "이용약관") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation2" name="stipulation2" onChange={onCheckHandler} checked={StipulrationCheckList[2]} />
                        <label htmlFor="stipulation2" css={stipulationText}>(필수) 개인정보 수집 및 이용 동의</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(PrivacyAgree, "개인정보 수집 및 이용 동의") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>

                    <div className="policy">
                        <input type="checkbox" id="stipulation3" name="stipulation3" onChange={onCheckHandler} checked={StipulrationCheckList[3]} />
                        <label htmlFor="stipulation3" css={stipulationText}>(필수) 위치기반서비스 이용약관</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(ServiceLocation, "위치기반서비스 이용약관") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>
                    <div className="policy">
                        <input type="checkbox" id="stipulation4" name="stipulation4" onChange={onCheckHandler} checked={StipulrationCheckList[4]} />
                        <label htmlFor="stipulation4" css={stipulationText}>(선택) 마케팅 정보 수신 동의</label>
                        <div css={css`float:right; cursor: pointer; padding-top: 4px;`} onClick={e => { onClickPolicyHandler(Promotion, "마케팅 정보 수신 동의") }}>
                            <img src={ARROW} alt="more" />
                        </div>
                    </div>
                    <div css={css`width: 282px; height: 48px; margin: 0 auto; margin-top: 2rem;`}>
                        <button css={themes.BtnOutLineBlue} onClick={onAgreeClickHandler} disabled={(StipulrationCheckList[0] && StipulrationCheckList[1] && StipulrationCheckList[2] && StipulrationCheckList[3]) ? false : true}>다음</button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );

    // 회원가입 FORM UI
    const formBox = (
        <React.Fragment>
            {
                FormCss[1].styles.indexOf("display: block") !== -1
                && <NavBar />
            }
            <Form css={FormCss} onSubmit={handleSubmit(onSubmitHandler)}>
                <div css={css`min-width: ${register_point}`}>
                    <h1 css={css`font-weight: 700; font-size: 24px; line-height: 24px; text-align: center;`}>
                        회원가입
                    </h1>
                    <div css={css`margin-top: 32px; border-top: 1px solid ${stroke_gray1};`}>
                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                아이디 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <div css={css`display: flex; height: 48px;`}>
                                    <Form.Control {...register("LgnId")} css={css`margin-right: 8px;`} autoComplete="on" isInvalid={errors.LgnIdConfirm?.message || errors.LgnId?.message} onChange={onChangeLoginIdHandler} />
                                    <Form.Control type="hidden" {...register("LgnIdConfirm")} value="fail" />
                                    <div css={css`width: 100%; max-width: 110px`}>
                                        <button css={themes.BtnOutLineBlue} disabled={errors.LgnId?.message} type="button" onClick={onClickLgnIdConfirmHandler}>중복 확인</button>
                                    </div>
                                </div>
                                {
                                    <Form.Control.Feedback type="invalid" css={(errors.LgnId?.message || errors.LgnIdConfirm?.message) && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                        {
                                            errors.LgnId?.message ? "× " + errors.LgnId?.message : "× " + errors.LgnIdConfirm?.message
                                        }
                                    </Form.Control.Feedback>
                                }
                            </div>
                        </div>
                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                비밀번호 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control type="password" {...register("Password")} autoComplete="off" isInvalid={!!errors.Password?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.Password?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                비밀번호 확인 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control type="password" {...register("PasswordConfirm")} autoComplete="off" isInvalid={!!errors.PasswordConfirm?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.PasswordConfirm?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                이름
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control type="text" {...register("Nm")} value={KgName ? KgName : getValues("Nm") || ''} readOnly={true} isInvalid={!!errors.Nm?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.Nm?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                휴대전화번호
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control type="text" {...register("Phone")} value={KgPhone ? KgPhone : getValues("Phone") || ''} readOnly={true} isInvalid={!!errors.Phone?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.Phone?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                이메일 주소 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control {...register("Email")} placeholder="이메일을 입력해주세요." isInvalid={!!errors.Email?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.Email?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                주소 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <div css={css`display: flex; height: 48px;`}>
                                    <Form.Control {...register("Rdnmadr")} css={css`margin-right: 8px;`} readOnly={true} isInvalid={!!errors.Rdnmadr?.message} />
                                    <Form.Control type="hidden" {...register("Adres")} />
                                    <Form.Control type="hidden" {...register("ZipCode")} />
                                    <div css={css`width: 100%; max-width: 110px`}>
                                        <button css={themes.BtnOutLineBlue} onClick={onAddressModalHandler} type="button">주소 찾기</button>
                                    </div>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    × {errors.Rdnmadr?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div"></Form.Label>
                            <div className="input_div">
                                {/*  isValid={getValues("AdresDetail")!== "" && !errors.AdresDetail?.message}  */}
                                <Form.Control type="text" {...register("AdresDetail")} isInvalid={!!errors.AdresDetail?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.AdresDetail?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row" css={css`margin-top: 8px !important; `}>
                            <Form.Label column sm="2" className="label_div"></Form.Label>
                            <ul css={css`list-style: inherit; padding: 0 0 0 17px; font-weight: 400; font-size: 13px; line-height: 19px; color:${button_text}`}>
                                <li>입력하신 주소로 멤버십카드가 자동 발송됩니다. 정확한 주소를 입력해주세요.</li>
                                <li>회원가입시 발급되는 멤버십카드는 분실 및 파손시 서비스 담당자를 통해 재발급 받으실 수 있고, 재발급 비용은 별도 청구됩니다.</li>
                            </ul>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                차량제조사 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                {CarCompany.length > 0 && <Form.Select {...register("CarCompanyName")} onChange={onChangeCarCompanyHandler} isInvalid={!!errors.CarCompanyName?.message} defaultValue={getValues("CarCompanyName") && getValues("CarCompanyName")}>
                                    <option value="null" key="car_00">제조사를 선택해주세요. </option>
                                    {
                                        CarCompany.map((obj, index) => {
                                            return (
                                                <option value={obj} key={"car_company_" + index}>{obj}</option>
                                            );
                                        })
                                    }
                                </Form.Select>}
                                <Form.Control.Feedback type="invalid">
                                    × {errors.CarCompanyName?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                차량 종류 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <Form.Select {...register("CarModelId")} isInvalid={!!errors.CarModelId?.message} defaultValue={getValues("CarModelId") && getValues("CarModelId")}>
                                    {CarModel.length === 0 && <option value="null">차량 종류를 선택해주세요.</option>}
                                    {
                                        CarModel.map((obj, index) => {
                                            return (
                                                <option value={obj.id} key={"car_model_" + index}>{obj.modelNm}</option>
                                            );
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    × {errors.CarModelId?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                차량 번호 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <Form.Control type="text" {...register("CarNo")} placeholder="차량 번호를 입력해주세요." isInvalid={!!errors.CarNo?.message} />
                                <Form.Control.Feedback type="invalid">
                                    × {errors.CarNo?.message}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="form_row">
                            <Form.Label column sm="2" className="label_div">
                                결제카드 등록 <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="input_div">
                                <div css={css`display: flex; height: 48px;`}>
                                    <Form.Control {...register("PaycardNo")} css={css`margin-right: 8px;`} value={PaycardResult ? PaycardResult.DisplayCardNo : ""} readOnly={true} placeholder="클릭하여 입력해주세요." />
                                    <Form.Control {...register("PaycardCo")} type="hidden" value={PaycardResult ? PaycardResult.IssuerCardNm : ""} readOnly={true} placeholder="클릭하여 입력해주세요." />
                                    <Form.Control {...register("Billingkey")} type="hidden" value={PaycardResult ? PaycardResult.BillTokenKey : ""} readOnly={true} placeholder="클릭하여 입력해주세요." />
                                    <Form.Control {...register("Tid")} type="hidden" value={PaycardResult ? PaycardResult.Tid : ""} readOnly={true} placeholder="클릭하여 입력해주세요." />

                                    <div css={css`width: 100%; max-width: 110px`}>
                                        <button css={themes.BtnOutLineBlue} type="button" onClick={onClickPaycardHandler}>등록</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form_row" css={css`justify-content: space-between;`}>
                            <div css={css`width: 48%; height: 48px;`}>
                                <Link to="/"><button css={themes.BtnFullBlue2}>취소</button></Link>
                            </div>
                            <div css={css`width: 48%; height: 48px;`}>
                                <button css={themes.BtnFullBlue2} onClick={onSubmitHandler} disabled={!isValid}>완료</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <Form id="paycardForm" name="paycardForm" method="post">
                <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
                <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                <input type="hidden" name="MallUserId" maxLength={20} value={UserMallId} placeholder="" />
                <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + "/users/register/result"} />
                <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                <input type="hidden" name="IsPwdPass" value="Y" />
            </Form>
            <ModalAlert
                show={modalShow}
                onClickHandler={() => setModalShow(false)}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div css={registWrap} className="regist">
                {confirmBox}
                {stipulationBox}
                {formBox}
            </div>
            <ModalAddress
                addressModal={addressModal}
                show={modalAddressShow}
                onHide={() => setModalAddressShow(false)}
                setModalAddressShow={setModalAddressShow}
                setValue={setValue}
            />
            <ModalPolicy
                show={ModalPolicyShow}
                onHide={() => setModalPolicyShow(false)}
                onClickHandler={e => setModalPolicyShow(false)}
                policyTitle={policyTitle}
                content={ModalContent}
            />
        </React.Fragment>
    );
};

const registWrap = css`
    position: relative;
    width: 100%;
    height: 100vh;
    background: ${white};
`;

const authenticationWrap = css`
    text-align: center;
    border: none; 

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    // padding:10%; 
    // width:100%;
    // height:100%;
    // min-width: ${tb_point};
`;

const logoWrap = css`
    margin-bottom: 21px;
`;

const authenticationTitle = css`
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: ${black};
`;

const stipulationTitle = css`
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: ${black};
`;

const stipulationBoldText = css`
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${button_text};
`;

const AgreeBoxMobileWrap = css`
    margin: 45px auto;
    border: 1px solid ${inputbox_stroke};
    border-radius: 10px;
    width: 100%;
    max-width: 512px;
    padding: 5%;

    .policy {
        text-align: left;
        padding-bottom: 2rem;
    }

    .all {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: ${button_text};
        padding-bottom: 3rem;
        input[type="checkbox"]+label {
            padding-top: 3px;
        }
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type='checkbox']:checked+label {
        background: url(${CHECKBOX_TRUE}) no-repeat 0 0px / contain;
        height:26px;
    }

    input[type="checkbox"]+label {
        :hover {
            cursor: pointer;
        }
        height:26px;
        padding-left: 44px;
        padding-top: 6px;
        background: url(${CHECKBOX_FALSE}) no-repeat 0 0px / contain;
    }  
`;

const stipulationText = css`
    font-weight: 400;
    font-size: 16px;
    // line-height: 225.3%;
    color: ${button_text};
`;

const authenticationText = css`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: ${black};
    padding: 0 0 36px 0;
`;

const authenticationImgWrap = css`
    padding: 33px 0 24px 0;
`;

const stipulationWrap = css`
    text-align: center;
    border: none; 

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    // padding:10%; 
    // width:100%;
    // height:100%;
    // min-width: ${tb_point};
`;

const registerFormStyle = css`
    background: ${background_gray1};

    @media (max-width: ${dt_point}) {
        padding: 115px 10%;
    }

    @media all and (min-width: ${dt_point_up}) {
        padding: 115px 30%;
    }
`;

export default RegisterPage