/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Form, TabContent } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ResponseResult } from '../../apis/response';
import Footer from '../../components/Footer/Footer2';
import ModalAddress from '../../components/Modal/ModalAddress';
import ModalAlert from '../../components/Modal/ModalAlert';
import NavBar from '../../components/NavBar/NavBar';
import Tab from '../../components/Tab/Tab';
import { reception_tab } from '../../configs/base';
import { getPolicy } from '../../stores/actions/policy_action';
import { registReception } from '../../stores/actions/reception_action';
import { getUser } from '../../stores/actions/user_action';
import { inputbox_stroke, stroke_gray1 } from '../../styles/colors';
import { BtnFullBlue2, BtnOutLineBlue } from '../../styles/themes';
import receptionValidation from '../../validations/schemas/ReceptionSchema';

function ReceptionPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [modalShow, setModalShow] = useState(false);
    const [UserInfo, setUserInfo] = useState({});
    const [ResMsg, setResMsg] = useState();
    const [Policy, setPolicy] = useState();

    // 주소 검색
    const [modalAddressShow, setModalAddressShow] = useState(false);

    const { handleSubmit, register, formState: { errors, isValid }, setValue } = useForm({
        resolver: yupResolver(receptionValidation),
        mode: 'onChange',
    });

    const [IsComplete, setIsComplete] = useState(isValid);

    const addressModal = (modal) => {
        setModalAddressShow(modalAddressShow);
    };

    const onAddressModalHandler = (event) => {
        if (!modalAddressShow) {
            setModalAddressShow(true);
        }
    }

    const onSubmitHandler = (data) => {
        let reqParams = {
            "nm": data.Nm,
            "cttpc": data.Cttpc,
            "rdnmadr": data.Rdnmadr,
            "adres": data.Adres,
            "adresDetail": data.AdresDetail,
            "cn": data.Cn
        }

        dispatch(registReception(reqParams)).then(
            response => {
                setModalShow(true);

                if (response.payload.result === ResponseResult.OK) {
                    setResMsg(<div>설치 문의가 정상적으로 접수되었습니다.<br /> 담당자 확인 후 신속히 연락드리겠습니다. <br />감사합니다.</div>);
                } else {
                    setResMsg(response.payload.msg);
                }
            }
        )
    }

    useEffect(() => {
        if (user.id) {
            //로그인 상태일 때 회원정보 조회
            dispatch(getUser(user.id)).then(
                response => {
                    if (response.payload.result === ResponseResult.OK) {
                        // console.log(response.payload.data);
                        setValue("Nm", response.payload.data.nm, { shouldValidate: true });
                        setValue("Cttpc", response.payload.data.phone, { shouldValidate: true });
                        setUserInfo(response.payload.data);
                    }
                }
            )
        }

        dispatch(getPolicy("개인정보 수집 및 이용 동의 (설치 문의)")).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    if (Object.keys(response.payload.data).length > 0) {
                        setPolicy(response.payload.data.cn);
                    } else {
                        setPolicy("-");
                    }
                }
            }
        )
    }, []);

    useEffect(() => {
        setIsComplete(isValid);
    }, [isValid]);

    return (
        <React.Fragment>
            <NavBar />
            <div className="reception_form">
                <Card>
                    <Card.Body>
                        {/* <h1 css={css`padding: 2% 5%;`}>설치안내</h1>
                        <div css={css`padding: 0 5%;`}> */}
                        <h1 css={css`padding: 2.6vh 3.3vw;`}>설치안내</h1>
                        <div css={css`padding: 0 3.3vw;`}>
                            <Tab list={reception_tab} active={1} />
                        </div>
                        <TabContent>
                            <Form onSubmit={handleSubmit(onSubmitHandler)}>
                                <div css={css`margin: 99px 15% 0 15%;`}>
                                    <div css={css`border-top: 1px solid ${stroke_gray1}; border-bottom: 1px solid ${stroke_gray1}; padding: 30px 0 25px 0`}>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div">
                                                이름 <span className="text-danger">*</span>
                                            </Form.Label>
                                            <div className="input_div">
                                                <Form.Control {...register("Nm")} type="text" autoComplete="off" readOnly={UserInfo.id && true} isInvalid={!!errors.Nm?.message} />
                                                <Form.Control.Feedback type="invalid" css={errors.Nm?.message && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                                    × {errors.Nm?.message}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div">
                                                휴대전화번호 <span className="text-danger">*</span>
                                            </Form.Label>
                                            <div className="input_div">
                                                <Form.Control {...register("Cttpc")} type="text" autoComplete="off" readOnly={UserInfo.id && true} isInvalid={!!errors.Cttpc?.message} />
                                                <Form.Control.Feedback type="invalid" css={errors.Cttpc?.message && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                                    × {errors.Cttpc?.message}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div">
                                                설치희망주소 <span className="text-danger">*</span>
                                            </Form.Label>
                                            <div className="input_div">
                                                <div css={css`display: flex; height: 48px;`}>
                                                    <Form.Control css={css`margin-right: 8px;`} type="text" {...register("Rdnmadr")} disabled={true} isInvalid={!!errors.Rdnmadr?.message || errors.Adres?.message} />
                                                    <Form.Control type="hidden" {...register("Adres")} defaultValue={UserInfo && UserInfo.adres} />
                                                    <div css={css`width: 100%; max-width: 110px`}>
                                                        <button type="button" css={BtnOutLineBlue} onClick={onAddressModalHandler}>주소검색</button>
                                                    </div>
                                                </div>
                                                <Form.Control.Feedback type="invalid" css={(errors.Rdnmadr?.message || errors.Adres?.message) && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                                    × {errors.Rdnmadr?.message
                                                        ? errors.Rdnmadr?.message
                                                        : errors.Adres?.message
                                                            ? errors.Adres?.message
                                                            : ""
                                                    }
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div">
                                                상세주소
                                            </Form.Label>
                                            <div className="input_div">
                                                <Form.Control {...register("AdresDetail")} type="text" isInvalid={!!errors.AdresDetail?.message} />
                                                <Form.Control.Feedback type="invalid" css={errors.AdresDetail?.message && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                                    × {errors.AdresDetail?.message}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div">
                                                문의내용
                                            </Form.Label>
                                            <div className="text_div">
                                                <Form.Control {...register("Cn")} as="textarea" rows={8} isInvalid={!!errors.Cn?.message} />
                                                <Form.Control.Feedback type="invalid" css={errors.Cn?.message && css`display: block; padding-top: 4px; align-items: left; text-align: left`}>
                                                    × {errors.Cn?.message}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div"></Form.Label>
                                            <div className="input_div" css={css`overflow: auto; border-radius: 10px; border: 1px solid ${inputbox_stroke}; min-height: 88px; padding:20px`} dangerouslySetInnerHTML={{ __html: Policy }}>
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <Form.Label column sm="2" className="label_div"></Form.Label>
                                            <div>
                                                <input type="checkbox" {...register("Agree")} id="agree" label="개인정보 수집 및 이용에 동의합니다." />
                                                <label htmlFor="agree" css={css`width: 100%; font-weight: 400; font-size: 16px; line-height: 16px;`}>
                                                    개인정보 수집 및 이용에 동의합니다. (필수)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div css={css`height: 48px; width: 30%; margin: 17px auto`}>
                                        <button type="submit" css={BtnFullBlue2} disabled={!IsComplete}>문의하기</button>
                                    </div>
                                </div>
                            </Form>
                        </TabContent>
                        <ModalAddress
                            addressModal={addressModal}
                            show={modalAddressShow}
                            onHide={() => setModalAddressShow(false)}
                            setModalAddressShow={setModalAddressShow}
                            setValue={setValue}
                        />
                        <ModalAlert
                            show={modalShow}
                            onClickHandler={e => navigate("/")}
                            oneButtonTitle={"확인"}
                            alertContent={ResMsg}
                            onHide={() => setModalShow(false)}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default ReceptionPage;