/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from 'react-router';
import { button_text } from '../../styles/colors';
import { BtnOutLineBlue } from '../../styles/themes';

export default function ModalAlert(props) {
    const { oneButtonTitle, alertContent } = props;

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
        >
        <Modal.Body className="container1 text-center" css={css`padding: 73px 92px 73px 92px`}>
            <Row css={css`font-weight: 400; font-size: 16px; line-height: 24px; color: ${button_text};  margin-bottom: 52px; `}>
                <div css={css`text-align: center;`}>
                    {alertContent}
                </div>
            </Row>
            <Row css={css`width: 100%; height: 48px; margin: 0`}>
                <button css={BtnOutLineBlue} onClick={e => props.onClickHandler ? props.onClickHandler() : window.location.reload()}>{oneButtonTitle}</button>
            </Row>
        </Modal.Body>
      </Modal>
    );
};