import { apiGet } from '../../apis/api';
import { GET_POLICY } from './types';

export const getPolicy = (data) => {
    const request = apiGet("/stipulations/"+data).then(response => response);
   
    return {
        type: GET_POLICY,
        payload: request
    }
}