/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import STORY_IMAGE1 from '../../assets/images/story_image1.png';
import STORY_IMAGE2 from '../../assets/images/story_image2.png';
import STORY_IMAGE3 from '../../assets/images/main_image2.png';
import STORY_IMAGE4 from '../../assets/images/story_image3.png';
import STORY_FRAME from '../../assets/images/story_frame.png';
import STORY_FRAME_MOBILE from '../../assets/images/story_frame_mobile.png';
import SCROLL_DOWN from '../../assets/images/Chevron double down.png';
import LOGO from '../../assets/images/logo_black.svg';
import LOGO_MOBILE from '../../assets/images/logo_black.svg';

// import TARDIS from '../../assets/images/tardis.svg';
// import COSTEL from '../../assets/images/costel.svg';
// import AUTOCRYPT from '../../assets/images/autocrypt.svg';
// import DAYOUPLUS from '../../assets/images/dayouplus.svg';
// import LS_ELECTRIC from '../../assets/images/ls_electric.svg';
// import SMARTRO from '../../assets/images/smartro.svg';

import PARTNER_TARDIS from '../../assets/images/partner_tardis.png';
import PARTNER_HUMAX from '../../assets/images/partner_humax.png';
import PARTNER_KAKAOMOBILITY from '../../assets/images/partner_kakaomobility.png';
import PARTNER_COSTEL from '../../assets/images/partner_costel.png';
import PARTNER_KEPCOMCS from '../../assets/images/partner_kepcomcs.png';
import PARTNER_SMARTRO from '../../assets/images/partner_smartro.png';
import PARTNER_KOOLSIGN from '../../assets/images/partner_koolsign.png';
import PARTNER_AUTOCRYPT from '../../assets/images/partner_autocrypt.png';
import PARTNER_KAEA from '../../assets/images/partner_kaea.png';


import Footer from '../../components/Footer/Footer2';
import { black2, white } from '../../styles/colors';
import { DESKTOP, dt_point, MOBILE } from '../../styles/media-queries';
import { PartnerContent, PartnerMobileContent, PartnerMobileWrap, PartnerWrap, ScrollWrap } from '../../styles/themes';

function IntroducePage2() {
    return (
        <React.Fragment>
            <NavBar />
            <div>
                <div css={StoryBannerWrap}>
                    <div css={DESKTOP}>
                        <div css={StoryContent}>
                            <h1>
                                Brand story
                            </h1>
                            <h3>
                                브랜드 스토리
                            </h3>
                            <h5>
                                SEOULEV는 서울도시가스그룹이 런칭한 <br />
                                전기차충전사업 브랜드입니다.
                            </h5>
                        </div>
                        <div css={ScrollWrap}>
                            <p>SCROLL</p>
                            <img src={SCROLL_DOWN} alt="scroll_icon" />
                        </div>
                    </div>

                    <div css={MOBILE}>
                        <div css={StoryMobileContent}>
                            <h1>
                                Brand story
                            </h1>
                            <h3>
                                브랜드 스토리
                            </h3>
                            <h5>
                                SEOULEV는 <br />
                                서울도시가스그룹이 런칭한 <br />
                                전기차충전사업 브랜드입니다.
                            </h5>
                        </div>
                    </div>
                </div>
                <div css={StoryImageWrap}></div>
                <div css={StoryMainBannerWrap}>
                    <div css={[DESKTOP, StoryMainBannerContent]}>
                        <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                            에너지 인프라 공급 경험을 활용하여 지역(SEOUL)기반으로 365일 24시간 <br />
                            항상 전기차충전소(EV station) 관리·유지한다는 의미로 <br />
                            고객에게 최상의 전기차 충전 서비스를 제공하고자 합니다.
                        </h5>
                    </div>

                    <div css={[MOBILE, StoryMainBannerMobileContent]}>
                        <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                            SEOULEV는 서울도시가스 그룹이 런칭한 전기차충전 사업브랜드 입니다. <br />
                            에너지 인프라 공급 경험을 활용하여 지역(SEOUL)기반으로 365일 24시간 <br />
                            항상 전기차충전소(EV station) 관리·유지한다는 의미로 <br />
                            고객에게 최상의 전기차 충전 서비스를 제공하고자 합니다.
                        </h5>
                    </div>
                </div>
                <div css={StoryBanner2Wrap}>
                    <div css={[DESKTOP, StoryBannerContent2]}>
                        <img css={LogoWrap} src={LOGO} alt="logo" style={{ width: 300 }} />
                        <div css={css`width: 100%; margin: 0 auto; max-width: 1280px;`}>
                            <img src={STORY_FRAME} alt="story_frame" />
                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                개별 충전소가 아닌 충전소와 충전소를 연결하여 에너지 서비스 자원화하고, <br />
                                충전소를 에너지 인프라 공급체계 안으로 결합하여 체계적인 관리·운영 환경을 제공하겠습니다. <br />
                                그 과정에서 소비자에게 더 많은 혜택을 제공하고 더 편리하고 더 친환경적인 서비스를 제공하여 전기차 충전의 선도모델을 만들겠습니다.
                            </h5>
                        </div>
                    </div>

                    <div css={[MOBILE, StoryBannerMobileContent2]}>
                        <div>
                            <img css={LogoWrap} src={LOGO_MOBILE} alt="logo_mobile" style={{ width: 200 }} />
                        </div>
                        <div>
                            <img style={{ width: "100%", maxWidth: "350px" }} src={STORY_FRAME_MOBILE} alt="story_frame_mobile" />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                개별 충전소가 아닌 충전소와 충전소를 연결하여 <br />
                                에너지 서비스 자원화하고, <br />
                                충전소를 에너지 인프라 공급체계 안으로 결합하여 <br />
                                체계적인 관리·운영 환경을 제공하겠습니다. <br />
                                그 과정에서 소비자에게 더 많은 혜택을 제공하고 <br />
                                더 편리하고 더 친환경적인 서비스를 제공하여 <br />
                                전기차 충전의 선도모델을 만들겠습니다.
                            </h5>
                        </div>
                    </div>
                </div>
                <div css={StoryBanner3Wrap}>
                    <div css={[DESKTOP, StoryBannerContent3]}>
                        <h5>
                            전기차충전운영사업은 서울도시가스그룹이 책임지고 <br />
                            20년 이상 CNG충전소 운영 노하우를 가진 충전전문계열사 서울씨엔지가 <br />
                            시공과 유지보수를 담당합니다.
                        </h5>
                    </div>

                    <div css={[MOBILE, StoryBannerMobileContent3]}>
                        <h5>
                            전기차충전운영사업은 서울도시가스그룹이 책임지고 <br />
                            20년 이상 CNG충전소 운영 노하우를 가진 충전전문계열사 <br />
                            서울씨엔지가 시공과 유지보수를 담당합니다.
                        </h5>
                    </div>
                </div>
                <div css={DESKTOP}>
                    <div css={PartnerWrap}>
                        <div css={PartnerContent}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <h1>
                                        Partners
                                    </h1>
                                    <h5>
                                        주요 파트너사
                                    </h5>
                                </div>
                                <div className="logos">
                                    <div className="logo_div" style={{ marginBottom: "30px" }}>
                                        <div>
                                            <img src={PARTNER_TARDIS} alt="tardis" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_HUMAX} alt="humax" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KAKAOMOBILITY} alt="kakaoMobility" />
                                        </div>
                                    </div>
                                    <div className="logo_div" style={{ marginBottom: "30px" }}>
                                        <div>
                                            <img src={PARTNER_COSTEL} alt="costel" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KEPCOMCS} alt="kepcoMCS" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_SMARTRO} alt="smartro" />
                                        </div>
                                    </div>
                                    <div className="logo_div">
                                        <div>
                                            <img src={PARTNER_KOOLSIGN} alt="koolsign" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_AUTOCRYPT} alt="autocrypt" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KAEA} alt="KAEA" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={MOBILE}>
                    <div css={PartnerMobileWrap}>
                        <div css={PartnerMobileContent}>
                            <div>
                                <h1>
                                    Partners
                                </h1>
                                <h5>
                                    주요 파트너사
                                </h5>
                            </div>
                            <div className="logos">
                                <img src={PARTNER_TARDIS} alt="tardis" style={{ width: "140px" }} />
                                <img src={PARTNER_HUMAX} alt="humax" style={{ width: "140px" }} />
                                <img src={PARTNER_KAKAOMOBILITY} alt="kakaoMobility" style={{ width: "140px" }} />
                                <img src={PARTNER_COSTEL} alt="costel" style={{ width: "140px" }} />
                                <img src={PARTNER_KEPCOMCS} alt="kepcoMCS" style={{ width: "140px" }} />
                                <img src={PARTNER_SMARTRO} alt="smartro" style={{ width: "140px" }} />
                                <img src={PARTNER_KOOLSIGN} alt="koolsign" style={{ width: "140px" }} />
                                <img src={PARTNER_AUTOCRYPT} alt="autocrypt" style={{ width: "140px" }} />
                                <img src={PARTNER_KAEA} alt="KAEA" style={{ width: "140px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const StoryBannerWrap = css`
    background-image: url(${STORY_IMAGE1});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    color: ${white};
    position: relative;
    width: 100%;
    height: 100vh;
`;

const StoryImageWrap = css`
    background-image: url(${STORY_IMAGE2});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    @media (min-width: ${dt_point}) {  height: 100vh; };
    @media (max-width: ${dt_point}) { height: 50vh };
`;

const StoryMainBannerWrap = css`
    position: relative;
    background-image: url(${STORY_IMAGE3});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
`;

const StoryBanner2Wrap = css`
    position: relative;
    background: ${white};
    width: 100%;
    height: 100vh;
`;

const StoryBanner3Wrap = css`
    position: relative;
    background-image: url(${STORY_IMAGE4});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
`;

const LogoWrap = css`
    padding-bottom: 9.6vh;
`;

const StoryMainBannerContent = css`
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    text-align: center;
    color: ${white};

    h5 {
        @media screen and (min-width: 1250px) {
            font-size: 22px;
        }
        font-weight: 500;
        font-size: 1.7vw;
        line-height: 38px;
        letter-spacing: -0.03em;
    }
`;

const StoryMainBannerMobileContent = css`
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    text-align: center;
    color: ${white};
    width: 77%;

    h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        // font-size: 1.7vw;
        // line-height: 38px;
        letter-spacing: -0.03em;
    }
`;

const StoryBannerContent2 = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;

    h5 {
        @media screen and (min-width: 1300px) {
            font-size: 22px;
            // font-size: 1.2em;

        }

        padding-top: 8.6vh;
        font-size: 1.7vw;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: -0.03em;
        color: ${black2};
    }
`;

const StoryBannerMobileContent2 = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    h5 {
        padding-top: 6%;

        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.03em;
        color: ${black2};
    }
`;

const StoryBannerContent3 = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};
    text-align: center;

    h5 {
        @media screen and (min-width: 1300px) {
            font-size: 22px;
        }

        font-weight: 500;
        font-size: 1.7vw;
        line-height: 38px;
        letter-spacing: -0.03em;
    }
`;

const StoryBannerMobileContent3 = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};
    text-align: center;
    width: 100%;

    h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.03em;
    }
`;

const StoryMobileContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width:100%;
    padding-top: 3vh;
    h1 {
        padding-bottom:  10px;
        @media (max-width: 550px) {
            font-size: 36px;
        };
        
        font-weight: 700;
        font-size: 6.5vw;

        line-height: 34px;
        letter-spacing: -0.01em;
    }

    h3 {
        padding-bottom: 6.3vh;

        @media (max-width: 550px) {
            font-size: 20px;
        };

        font-weight: 700;
        font-size: 3.5vw;
        line-height: 22px;
        letter-spacing: -0.03em;
    }

    h5 {
        @media (max-width: 550px) {
            font-size: 16px;
            line-height: 24px;
        };

        font-weight: 500;
        font-size: 3vw;
        line-height: 150%;
        letter-spacing: -0.03em;
    }
`;

const StoryContent = css`
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    text-align: center;
    font-weight: 700;
    padding-top: 3vh;
    h1 {
        font-size: 68px;
        line-height: 70px;
        letter-spacing: -0.01em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        
        margin-bottom: 10px;
    }

    h3 {
        font-size: 26px;
        line-height: 33px;
        letter-spacing: -0.03em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        margin-bottom: 148px;
    }

    h5 {
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.03em;
    }
`;

export default IntroducePage2;