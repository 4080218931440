export const default_location = {
  lat: 37.5474433,
  lng: 126.8723892,
};

export const mypage_tab = [
  {
    nm: "이용내역",
    link: "/mypage/charging?currentPage=1",
  },
  {
    nm: "포인트",
    link: "/mypage/point?page=1",
  },
  {
    nm: "회원정보 관리",
    link: "/mypage/info",
  },
  {
    nm: "회원카드 관리",
    link: "/mypage/membercard",
  },
  {
    nm: "결제카드 관리",
    link: "/mypage/paycard",
  },
];

export const support_tab = [
  {
    nm: "공지사항",
    link: "/support/notice?currentPage=1",
  },
  {
    nm: "이용안내",
    link: "/support/service_guide",
  },
  {
    nm: "자주 하는 질문",
    link: "/support/faq?currentPage=1",
  },
];

export const reception_tab = [
  {
    nm: "설치 안내",
    link: "/reception_guide",
  },
  {
    nm: "설치 문의",
    link: "/reception",
  },
];

// 충전기 완속/급속 기준값
export const charger_speed = 50;
