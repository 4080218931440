/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ResponseResult } from '../../apis/response';
import NavBar from '../../components/NavBar/NavBar';
import { getPolicy } from '../../stores/actions/policy_action';
import { notFoundPolicy } from '../../validations/messages/messages';

function PolicyDetailPage() {
    const policyNm = window.location.pathname.split("/").pop();
    const [Policy, setPolicy] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // 특정 약관 조회하기
        let nm = "";

        switch (policyNm) {
            case "service":
                // 이용약관
                nm = "이용약관";
                break;
            case "privacy":
                // 개인정보처리방침
                nm = "개인정보처리방침";
                break;
            case "service_location":
                // 위치기반서비스 이용약관
                nm = "위치기반서비스 이용약관";
                break;
            default:
                break;
        }

        dispatch(getPolicy(nm)).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    if (Object.keys(response.payload.data).length !== 0) {
                        setPolicy(response.payload.data.cn);
                    } else {
                        alert(notFoundPolicy);
                        navigate(-1);
                    }
                } else {
                    alert(response.payload.msg);
                    navigate(-1);
                }
            }
        )
    }, []);

    return (
        <React.Fragment>
            <NavBar />
            <div className="my-auto" css={css`padding: 5em;`}>
                <div className="detail-content" dangerouslySetInnerHTML={{ __html: Policy }} css={cnStyle}>
                </div>
            </div>
        </React.Fragment>
    );
}
const cnStyle = css`
    width:100%;
    line-height:1.5;
    img {
        max-width: 100% !important;
        height: auto !important;
    }
`;

export default PolicyDetailPage;