import { EDIT_USER_DONE, EDIT_USER_ING, REGIST_USER_DONE, REGIST_USER_ING } from "./types";

export const registUserIng = (data) => {
    // 회원가입 진행 중 상태
    // console.log(data);
    return {
        type: REGIST_USER_ING,
        payload: data
    }
}

export const registUserDone = () => {
    return {
        type: REGIST_USER_DONE,
        payload: false
    }
}

export const editUserIng = (data) => {
    // 회원정보 변경 중 상태
    return {
        type: EDIT_USER_ING,
        payload: data
    }
}

export const editUserDone = (data) => {
    // 회원정보 변경 중 상태
    return {
        type: EDIT_USER_DONE,
        payload: false
    }
}