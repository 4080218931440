import { apiGet, apiPost } from '../../apis/api';
import {
    GET_CHARGER_TO_QR, QRPAY_PAYMENT,
    // QRPAY_PAYMENT
} from './types';

export function getChargerToQr(qrcode) {
    const request = apiGet("/qrPay/" + qrcode).then(response => response);

    return  {
        type: GET_CHARGER_TO_QR,
        payload: request
    }
}

// export function qrpayPayment(data) {
//     const request = apiPost("/qrPay/payment", data).then(response => response);

//     return {
//         type: QRPAY_PAYMENT,
//         payload: request
//     }
// }