import * as yup from "yup";
import * as messages from "../messages/messages";

export const registerValidation = yup.object({
    LgnId: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^[a-z0-9]{3,20}$/, "3~20글자 영문, 숫자를 입력해주세요."),
    LgnIdConfirm: yup
        .string()
        .oneOf(["ok"], '아이디 중복검사를 해주세요.'),
    Password: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    PasswordConfirm: yup
        .string()
        .required(messages.requiredMessage)
        .oneOf([yup.ref('Password'), null], "비밀번호가 일치하지 않습니다."),
    Nm: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^[a-zA-Zㄱ-힣0-9]*$/, "특수문자는 입력이 불가능합니다.")
        .max(100, "100자리 이하로 입력해주세요."),
    Phone: yup
        .string()
        .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요.")
        .required(messages.requiredMessage),
    Email: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, messages.emailTypeMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    Rdnmadr: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    Adres: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    AdresDetail: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    CarCompanyName: yup
        .string()
        .required(messages.requiredMessage),
    CarModelId: yup
        .string()
        .required(messages.requiredMessage),
    CarNo: yup
        .string()
        .required(messages.requiredMessage)
        .test(
            "match",
            messages.carNumberMessage,
            value => {
                if (value.match(/^\d{2,3}[가-힣]\s?\d{4}$/)) {
                    return true;
                }

                if (value.match(/^[가-힣]{2}\s?\d{2,3}[가-힣]\s?\d{4}$/)) {
                    return true;
                }

                return false
            }
        ),
    PaycardNo: yup
        .string()
        .required(messages.requiredMessage),
}).required();
