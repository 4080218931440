import { apiGetParams, apiPost, privateApiDelete, privateApiGet, privateApiPost } from '../../apis/api';
import { AUTH_CODE_CONFIRM, AUTH_CODE_REQ, CHECK_PHONE, CONFIRM_LGN_ID, DELETE_USER, FIND_ID, FIND_PASSWORD, GET_USER, GET_USER_MEMBERCARD, GET_USER_PATCARD, LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN, REGIST_USER, UPDATE_PAYCARD, UPDATE_USER_INFO, UPDATE_USER_PASSWORD } from './types';

export const loginUser = (data) => {
    // 로그인
    const request = apiPost("/auth/getToken", data).then(response => response);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export const logoutUser = () => {
    // 로그아웃
    return {
        type: LOGOUT_USER,
        payload: false
    }
}

export const refreshToken = (data) => {
    // 토큰 리프래쉬
    return {
        type: REFRESH_TOKEN,
        payload: false
    }
}

export const updatePaycard = (data) => {
    // 결제카드 변경
    const request = privateApiPost("/users/changePaycard", data).then(response => response);

    return {
        type: UPDATE_PAYCARD,
        payload: request
    }
}

export const confirmLgnId = (data) => {
    // 아이디 중복체크
    const request = apiPost("/users/duplicateId", data).then(response => response);

    return {
        type: CONFIRM_LGN_ID,
        payload: request
    }
}

export const registUser = (data) => {
    // 회원 가입
    const request = apiPost("/users/store", data).then(response => response);

    return {
        type: REGIST_USER,
        payload: request
    }
}

export const getUser = (id) => {
    // 회원 정보 상세 조회
    const request = privateApiGet("/users/show/" + id).then(response => response);

    return {
        type: GET_USER,
        payload: request
    }
}

export const getUserMembercard = (id) => {
    // 회원 멤버십카드 상세 조회
    const request = privateApiGet("/users/showMembershipCard/" + id).then(response => response);

    return {
        type: GET_USER_MEMBERCARD,
        payload: request
    }
}

export const getUserPaycard = (id) => {
    // 회원 결제카드 상세 조회
    const request = privateApiGet("/users/showPaycard/" + id).then(response => response);

    return {
        type: GET_USER_PATCARD,
        payload: request
    }
}

export const updateUserInfo = (data) => {
    // 회원 정보 변경
    const request = privateApiPost("/users/update", data).then(response => response);

    return {
        type: UPDATE_USER_INFO,
        payload: request
    }
}

export const updateUserPassword = (data) => {
    // 회원 비밀번호 변경
    const request = privateApiPost("/users/changePassword", data).then(response => response);

    return {
        type: UPDATE_USER_PASSWORD,
        payload: request
    }
}

export const deleteUser = (id) => {
    // 회원 탈퇴
    const request = privateApiDelete("/users/delete/" + id).then(response => response);

    return {
        type: DELETE_USER,
        payload: request
    }
}

export const AuthCodeReq = (data) => {
    // 인증번호 요청(전송)
    const request = apiPost("/auth/issueAuthcode", data).then(response => response);

    return {
        type: AUTH_CODE_REQ,
        payload: request
    }
}

export const AuthCodeConfirm = (data) => {
    // 인증번호 확인
    const request = apiPost("/auth/checkAuthcode", data).then(response => response);

    return {
        type: AUTH_CODE_CONFIRM,
        payload: request
    }
}

export const FindId = (data) => {
    // 아이디 찾기
    const request = apiGetParams("/auth/findID", data).then(response => response);

    return {
        type: FIND_ID,
        payload: request
    }
}

export const FindPassword = (data) => {
    // 비밀번호 찾기 (변경)
    const request = apiPost("/auth/changePassword", data).then(response => response);

    return {
        type: FIND_PASSWORD,
        payload: request
    }
}

export const checkPhone = async (data) => {
    //본인인증 후 전화번호 중복 체크
    const request = await apiPost("/auth/checkPhone", data).then(response => response);

    return {
        type: CHECK_PHONE,
        payload: request
    };
}
