/** @jsxImportSource @emotion/react */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import persistedReducer from "./stores/reducers/index";
import ReduxThunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import GlobalStyle from './styles/global';
import createCache from "@emotion/cache";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CacheProvider } from "@emotion/react";
import ScrollTop from './components/ScrollTop/ScrollTop';
import QuickMenu from './components/QuickMenu/QuickMenu';

// 기존
const store = createStore(persistedReducer, applyMiddleware(promiseMiddleware, ReduxThunk));
const persistor = persistStore(store);

// // 추가
// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch


if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const cacheNoPrefixer = createCache({
  key: "noprefixer",
  stylisPlugins: []
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider
    store={store}
  >
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <CacheProvider
          value={cacheNoPrefixer}
        >
          <ScrollTop />
          <GlobalStyle />
          <App />
          <QuickMenu />
        </CacheProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
