import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function TempPage(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let result = searchParams.get("result");
        // console.log(result);
        if (result) {
            if (props.path === "/mypage/info") {
                let urlarr = window.location.href.split("/");
                window.opener.location.href = urlarr[0] + "/mypage/info?kg=" + result;
                window.close();
            } else {
                let urlarr = window.location.href.split("/");
                window.opener.location.href = urlarr[0] + "/register?kg=" + result;
                window.close();
            }
        }
    }, []);

    return (
        <React.Fragment></React.Fragment>
    );
}
export default TempPage