/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from 'react-router';
import MODAL_ARROW_ICON from '../../assets/images/modal_arrow.png';
import { BtnOutLineBlue } from '../../styles/themes';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../../validations/schemas/UpdatePasswordSchema';
import { updateUserPassword } from '../../stores/actions/user_action';
import React, { useState } from 'react';
import { ResponseResult } from '../../apis/response';
import { useEffect } from 'react';

export default function ModalPasswordChange(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [IsComplete, setIsComplete] = useState(false);
    const [ResultUI, setResultUI] = useState();

    const { handleSubmit, register, formState:{ errors, isValid }, setValue} = useForm({
        resolver: yupResolver(updatePasswordSchema),
        mode: 'onChange',
    });

    const onSubmitHandler = (data) => {
        let reqParams = {
            "id": props.id,
            "lgnPassword": data.Password,
            "changePassword": data.UpdatePassword,
        }

        dispatch(updateUserPassword(reqParams)).then(
            response => {
                setIsComplete(true);
                if (response.payload.result === ResponseResult.OK) {
                    setResultUI(
                        <React.Fragment>
                            <div css={css`font-weight: 400; font-size: 16px; line-height: 24px;`}>
                                {response.payload.msg}
                            </div>
                            <div css={css`width: 200px; padding: 45px 0; margin: 0 auto;`}>
                                <button onClick={e => {navigate("/login")}} css={[BtnOutLineBlue, css` height: 48px;`]}>로그인 화면으로</button>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    setResultUI(
                        <React.Fragment>
                            <div css={css`font-weight: 400; font-size: 16px; line-height: 24px;`}>
                                {response.payload.msg}
                            </div>
                            <div css={css`width: 200px; padding: 45px 0; margin: 0 auto;`}>
                                <button onClick={e => {setIsComplete(false)}} css={[BtnOutLineBlue, css` height: 48px;`]}>뒤로가기</button>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        )
    }

    useEffect(() => {
        setValue("Password", "");
        setValue("UpdatePassword", "");
        setValue("UpdatePasswordConfirm", "");
    }, [IsComplete]);

    useEffect(() => {
        setValue("Password", "");
        setValue("UpdatePassword", "");
        setValue("UpdatePasswordConfirm", "");
        setTimeout(() => setIsComplete(false), 500);
    }, [props.onHide]);
   
    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
            className={!IsComplete ? "mypage-modal" : "mypage-modal-alert"}
            onHide={props.onHide}
        >
        <div className="title">
            <img src={MODAL_ARROW_ICON} onClick={props.onHide} alt="close_icon" css={css`&:hover{cursor:pointer}`}/>
            <h1>비밀번호 변경</h1>
        </div>
        <Modal.Body className="container1 text-center">
            {
                !IsComplete ?
                    <Form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div css={css`margin-bottom: 32px;`}>
                        <div className="label_div">
                            <p>기존 비밀번호</p>
                        </div>
                        <div className="input_div">
                            <Form.Control type="password" {...register("Password")} isInvalid={!!errors.Password?.message } placeholder="기존 비밀번호 입력" autoComplete="on" />
                        </div>
                    </div>
                    <div>
                        <div className="label_div">
                            <p>비밀번호 재설정</p>
                        </div>
                        <div className="input_div" css={css`margin-bottom: 8px;`}>
                            <Form.Control type="password" {...register("UpdatePassword")} isInvalid={!!errors.UpdatePassword?.message } placeholder="비밀번호 입력" autoComplete="off"/>
                    </div>
                        <div className="input_div" css={css`margin-bottom: 11px;`}>
                            <Form.Control type="password" {...register("UpdatePasswordConfirm")} isInvalid={!!errors.UpdatePasswordConfirm?.message } placeholder="비밀번호 재입력" autoComplete="off" />
                        </div>
                        <small>비밀번호는 8~16자리의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해 주세요.</small>       
                    </div>
                    <div css={css`width: 200px; padding-top: 64px; margin: 0 auto;`}>
                        <button disabled={!isValid} type="submit" css={[BtnOutLineBlue, css` height: 48px;`]}>변경하기</button>
                    </div>
                </Form>

                : ResultUI
            }
        </Modal.Body>
      </Modal>
    );
};