import * as yup from "yup";
import * as messages from "../messages/messages";

export const qrChargingInfoValidation = yup.object({
  Phone: yup
    .string()
    .required(messages.requiredMessage)
    .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, "11~13글자의 핸드폰 번호를 입력해주세요."), /// ///^[0-9-]{10,13}$/
  kwhPay: yup.string().nullable().oneOf(["3000", "5000", "10000", "20000", "30000"], messages.requiredMessage),
});
