/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal, Row } from "react-bootstrap";
import { useNavigate } from 'react-router';
import { button_text } from '../../styles/colors';
import CLOSE_ICON from '../../assets/images/modal_close_icon.png';
import { BtnOutLineBlue, BtnOutLineGray } from '../../styles/themes';

export default function ModalRegisterReq(props) {
    const navigate = useNavigate();

    const onClickRegisterPageHandler = () => {
        navigate("/register");
    }

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
        >
        <div>
            <img src={CLOSE_ICON} onClick={props.onHide} alt="close_icon" css={css`margin:32px 32px 48px 0; float:right; &:hover{cursor:pointer}`}/>
        </div>
        <Modal.Body className="container1 text-center" css={css`padding: 0 53px 63px 53px`}>
            <Row css={css`font-size: 16px; line-height: 24px; color: ${button_text};  margin-bottom: 64px;`}>
                <h5 css={css`font-weight: 700;  margin-bottom: 8px;`}>가입된 회원정보가 없습니다.</h5>
                <h5 css={css`font-weight: 400;`}>회원가입을 하시겠습니까?</h5>
            </Row>
            <Row css={css`display: flex; justify-content: space-evenly;`}>
                <div css={css`width: 50%; height: 48px`}>
                    <button css={[BtnOutLineGray, css`font-weight: 700; font-size: 13px; line-height: 19px;`]} onClick={props.onHide}>취소</button>
                </div>
                <div css={css`width: 50%; height: 48px`}>
                    <button css={[BtnOutLineBlue, css`font-weight: 700; font-size: 13px; line-height: 19px;`]} onClick={onClickRegisterPageHandler}>회원가입</button>
                </div>
            </Row>
        </Modal.Body>
      </Modal>
    );
};