import DaumPostcode from "react-daum-postcode";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function ModalAddress(props) {
  const [modalAddressShow, setModalAddressShow] = useState(props.show);

  useEffect(() => {
      setModalAddressShow(props.show)
  }, [props.show]);
  
  const onCloseModalHandler = () => {
      props.setModalAddressShow(false);
  }

  const handleComplete = (data) => {
      props.setModalAddressShow(false);
      props.setValue("Rdnmadr", data.roadAddress, { shouldValidate: true });
      props.setValue("Adres", data.jibunAddress === "" ? data.autoJibunAddress : data.jibunAddress, { shouldValidate: true });
      props.setValue("ZipCode", data.zonecode, { shouldValidate: true });
  }

  return (
    <Modal
      show={modalAddressShow}
      onHide={onCloseModalHandler}
      aria-labelledby="contained-modal-title-vcenter"
      autoFocus
      fullscreen="md-down"
      className="address"
      >
      <Modal.Header closeButton> </Modal.Header>
      <DaumPostcode onComplete={handleComplete}/>
    </Modal>
  );
}