/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const dt_xl_point = "1400px";
export const dt_point = "1024px";
export const dt_point_up = "1025px";
export const dt_point_down = "1023px";

export const mb_point = "375px";

export const tb_point = "768px";
export const tb_point_up = "769px";
// export const footer_point = "1190px";
export const footer_point = "1348px";
export const footer_point_up = "1349px";
export const mypage_filter_point = "950px";
export const register_point = "500px";


export const dt_xl = '@media (min-width: 1400px)'
export const dt = '@media (min-width: 1025px) and (max-width: 1399px)';
export const tb = '@media (min-width: 769px) and (max-width: 1024px)';
export const mb = '@media (max-width: 769px)';

// 949px 이하일때
export const mypage_filter_tb = '@media (max-width: 949px)';
// 950px 이상일때
export const mypage_filter_dt = '@media all and (min-width: 950px)';

export const mypage_tab_tb = '@media (max-width: 1135px)';
export const reception_tb = '@media (max-width: 1135px)';

export const MOBILE = css`@media (min-width: ${dt_point}) { display: none }; @media (max-width: ${dt_point}) { display: block; }`;
export const DESKTOP = css`@media (min-width: ${dt_point}) { display: block }; @media (max-width: ${dt_point}) { display: none; }`;