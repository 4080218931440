/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from 'react-router';
import MODAL_ARROW_ICON from '../../assets/images/modal_arrow.png';
import { BtnOutLineBlue } from '../../styles/themes';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../../validations/schemas/UpdatePasswordSchema';
import { deleteUser, updateUserPassword } from '../../stores/actions/user_action';
import React, { useState } from 'react';
import { ResponseResult } from '../../apis/response';
import { useEffect } from 'react';
import { disabled_gray2, typo_light_blue } from '../../styles/colors';

export default function ModalLeaveUser(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [IsComplete, setIsComplete] = useState(false);
    const [ResultUI, setResultUI] = useState();
    const [Agree, setAgree] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsComplete(false), 500);
    }, [props.onHide]);

    const onClickLeaveHandler = (data) => {
        dispatch(deleteUser(props.id)).then(
            response => {
                // console.log(response);
                setIsComplete(true);
                if (response.payload.result === ResponseResult.OK) {
                    setResultUI(
                        <React.Fragment>
                            <div css={css`font-weight: 400; font-size: 16px; line-height: 24px;`}>
                                {response.payload.msg}
                            </div>
                            <div css={css`width: 200px; padding: 45px 0; margin: 0 auto;`}>
                                <button onClick={e => { navigate("/login") }} css={[BtnOutLineBlue, css` height: 48px;`]}>로그인 화면으로</button>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    setResultUI(
                        <React.Fragment>
                            <div css={css`font-weight: 400; font-size: 16px; line-height: 24px;`}>
                                {response.payload.msg}
                            </div>
                            <div css={css`width: 200px; padding: 45px 0; margin: 0 auto;`}>
                                <button onClick={e => { props.onHide() }} css={[BtnOutLineBlue, css` height: 48px;`]}>확인</button>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        )
    }

    const onChangeAgreeHandler = () => {
        setAgree(!Agree);
    }

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
            className={!IsComplete ? "mypage-modal" : "mypage-modal-alert"}
            onHide={props.onHide}
        >
            <div className="title">
                <img src={MODAL_ARROW_ICON} onClick={props.onHide} alt="close_icon" css={css`&:hover{cursor:pointer}`} />
                <h1>SEOULEV 회원 탈퇴</h1>
            </div>
            <Modal.Body>
                {
                    !IsComplete ?
                        <React.Fragment>
                            <div css={css`margin-bottom: 32px;`}>
                                <div css={css`font-weight: 400; font-size: 16px; line-height: 16px; color: ${disabled_gray2};`}>회원 탈퇴에 앞서 <strong css={css`color: ${typo_light_blue}`}>유의사항 및 안내</strong>를 반드시 읽고 진행해주세요.</div>
                                <div css={css`padding: 28px 0 33px 15px; font-weight: 400; font-size: 13px; line-height: 24px;`}>
                                    <ul css={css`list-style: inherit`}>
                                        <li>회원 탈퇴 시 고객님께서 보유하셨던 포인트는 모두<br />소멸되어 환불처리가 되지 않습니다.</li>
                                        <li>결제할 잔액이 남아있는 회원의 경우 관련 결제가<br />완료된 이후에 탈퇴가 가능합니다.</li>
                                        <li>탈퇴 시 3개월간 재가입이 제한됩니다.</li>
                                    </ul>
                                </div>
                                <div>
                                    <input type="checkbox" id="agree" label="개인정보 수집 및 이용에 동의합니다." onChange={onChangeAgreeHandler} />
                                    <label htmlFor="agree" css={css`width: 100%; font-weight: 400; font-size: 16px; line-height: 16px;`}>
                                        위 안내를 모두 확인했습니다.
                                    </label>
                                </div>
                            </div>
                            <div css={css`width: 100%; padding-top: 70px; display:flex; justify-content: space-around`}>
                                <button type="button" onClick={e => props.onHide()} css={[BtnOutLineBlue, css`width: 45%; height: 48px;`]}>탈퇴 취소</button>
                                <button disabled={!Agree} onClick={onClickLeaveHandler} type="button" css={[BtnOutLineBlue, css`width: 45%; height: 48px;`]}>확인</button>
                            </div>
                        </React.Fragment>
                        : ResultUI
                }
            </Modal.Body>
        </Modal>
    );
};