/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer2";
import NavBar from "../../components/NavBar/NavBar";
import { getChargerToQr } from "../../stores/actions/qr_action";
import { button_text } from "../../styles/colors";
import { BtnFullBlue2, BtnQRBlue } from "../../styles/themes";
import CHECKBOX_FALSE from "../../assets/images/checkbox.png";
import CHECKBOX_TRUE from "../../assets/images/checkbox_true.png";

function QRpayDetailPage() {
  const dispatch = useDispatch();
  const { qrcode } = useParams();
  let navigate = useNavigate();
  let [searchQrcode, setSearchQrcode] = useState(qrcode);
  let [charger, setCharger] = useState({});

  useEffect(() => {
    dispatch(getChargerToQr(qrcode)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        setCharger(response.payload.data);
      } else {
        if (response.payload.msg === "해당 충전기의 정보가 존재하지 않습니다.") {
          navigate("/QRpay", { state: { qrcode: qrcode } });
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getChargerToQr(qrcode)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        setCharger(response.payload.data);
      } else {
        if (response.payload.msg === "해당 충전기의 정보가 존재하지 않습니다.") {
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/QRpay?qrcode=" + qrcode;
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
          let urlarr = window.location.href.split("/");
          window.location.href = urlarr[0] + "/QRpay";
        }
      }
    });
  }, [dispatch, qrcode]);

  const onClickQRSearchHandler = () => {
    navigate("/QRpay/" + searchQrcode);
  };

  const onChangeQRcodeHandler = (event) => {
    setSearchQrcode(event.currentTarget.value);
  };

  const onClickPayHandler = (event) => {
    if (window.confirm("충전기 정보를 충분히 확인하였습니까?")) {
      navigate("charging_info", {
        state: { qrcode: qrcode, charger: charger },
      });
    }
  };

  const onKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      onClickQRSearchHandler();
    }
  };

  const ing_div = (
    <div>
      <label className="col-sm-1 col-1 col-form-label">
        <div className="orange-circle"></div>
      </label>
      <span className="col-11 col-form-label">사용중</span>
    </div>
  );

  const ready_div = (
    <div
      css={css`
        display: flex;
      `}
    >
      <label className="col-sm-1 col-1 col-form-label">
        <div className="green-circle"></div>
      </label>
      <span className="col-11 col-form-label">사용가능</span>
    </div>
  );

  const no_div = (
    <div>
      <span className="col-11 col-form-label text-danger">현재 사용할 수 없는 충전기입니다.</span>
    </div>
  );

  return (
    <>
      <NavBar isNavBtnVisible={false} />
      <div css={QRpayWrap}>
        <Card>
          <Card.Body>
            <h1
              className="text-center"
              css={css`
                padding: 5%;
              `}
            >
              비회원 QR 결제
            </h1>
            <div
              className="text-center mb-3"
              css={css`
                padding: 5% 3% 3% 3%;
              `}
            >
              <small className="text-muted">* 충전소 정보를 확인해 주세요</small>
            </div>
            <div
              css={css`
                padding: 0% 5% 5% 5%;
              `}
            >
              <div
                css={css`
                  width: 100%;
                  height: 48px;
                  margin: 0;
                `}
              >
                {
                  // 01: 운영 , 02: 시범운영 모드일 때, 상태체크
                  charger.chargerMode === "01" || charger.chargerMode === "02" ? (
                    charger.sttus === "AA" || charger.sttus === "AB" ? (
                      <button type="button" css={BtnFullBlue2} className="btn btn-main rounded-pill mb-2 pay" onClick={onClickPayHandler}>
                        결제하기
                      </button>
                    ) : (
                      <button type="button" className="btn btn-main rounded-pill mb-2 pay" onClick={onClickPayHandler} disabled>
                        결제하기
                      </button>
                    )
                  ) : (
                    <button type="button" css={BtnFullBlue2} className="btn btn-main rounded-pill mb-2 pay" onClick={onClickPayHandler} disabled>
                      결제하기
                    </button>
                  )
                }
              </div>
              <div className="mb-2 mt-2">
                <div className="row">
                  {
                    // 01: 운영 , 02: 시범운영 모드일 때, 상태체크
                    charger.chargerMode === "01" || charger.chargerMode === "02"
                      ? {
                          AA: ready_div,
                          AB: ready_div,
                          AC: ing_div,
                          AD: ing_div,
                          AL: ing_div,
                          AE: ing_div,
                          AF: ing_div,
                          AG: no_div,
                          AH: no_div,
                          AI: no_div,
                          AJ: no_div,
                          AK: no_div,
                        }[charger.sttus]
                      : no_div
                  }
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">큐알코드 번호</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">{charger.qrcode}</label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">충전소명</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">{charger.stationNm}</label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">충전소 주소</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">{charger.stationRdnmadr ? charger.stationRdnmadr : charger.stationAdres ?? "-"}</label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">충전기 종류</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">
                      {
                        {
                          AA: "플러그없음",
                          AB: "AC완속",
                          AC: "DC콤보X1",
                          AD: "DC차데모",
                          AE: "AC3",
                          AF: "DC콤보+DC차데모",
                          AG: "DC콤보+AC3",
                          AH: "DC차데모+AC3",
                          AI: "DC콤보+DC차데모+AC3",
                          AJ: "무선 충전",
                          AK: "DC급속(버스)",
                          AL: "AC급속(버스)",
                          AM: "급속(이륜차, e-mobility)",
                          AN: "완속(이륜차, e-mobility)",
                          AO: "콘센트(콘센트)",
                          AP: "DC콤보X2",
                        }[charger.plugTy]
                      }
                    </label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">이용가능 시간</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">{charger.ormpm}</label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">서비스 기관</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">SEOULEV</label>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-5 col-form-label">요금</label>
                  <div className="col-sm-9 col-7">
                    <label className="col-form-label">{charger.price} 원/kWh</label>
                  </div>
                </div>
              </div>

              <div className="row item-center mb-4">
                <div div="" className="col-sm-12">
                  <small className="form-text text-muted">* 충전소 정보가 다른 경우 번호를 재검색해 주세요</small>
                </div>
                <div className="col-sm-12 col-lg-12 mt-3">
                  <InputGroup>
                    <FormControl
                      placeholder="다른 큐알코드 번호로 검색"
                      name="qrcode"
                      onChange={onChangeQRcodeHandler}
                      onKeyDown={onKeyDownHandler}
                    />
                    <button css={BtnQRBlue} id="button-addon2" onClick={onClickQRSearchHandler}>
                      검색
                    </button>
                  </InputGroup>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div
        css={css`
          width: 100%;
          height: 100vh;
        `}
      ></div>
    </>
  );
}

const QRpayWrap = css`
  padding: 3% 15%;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background: url(${CHECKBOX_TRUE}) no-repeat 0 0px / contain;
    height: 26px;
  }

  input[type="checkbox"] + label {
    :hover {
      cursor: pointer;
    }
    height: 26px;
    padding-left: 44px;
    padding-top: 6px;
    background: url(${CHECKBOX_FALSE}) no-repeat 0 0px / contain;
  }

  .green-circle {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background-color: #27ae60;
  }

  .orange-circle {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background-color: #f2994a;
  }

  .card {
    border: none;

    h1 {
      padding-bottom: 32px;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }

    .card-body {
      padding: 0;

      .form_row {
        display: flex;
        padding-bottom: 24px;

        .input_div {
          width: 100%;
          height: 48px;
        }

        .label_div {
          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${button_text};

          min-width: 115px;
        }

        .text_div {
          width: 100%;
        }

        textarea {
          border-radius: 10px;
        }

        input[type="text"] {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
`;

export default QRpayDetailPage;
