import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import user from './user_reducer';
import data from './data_reducer';

const persistConfig = {
    key: "root",
    storage: storageSession,
    // auth, certify, article 3개의 reducer 중에 user reducer만 sessionStorage에 저장
    // whitelist: ["user", "qr"]
    whitelist: ["user", "data"]
};

export const rootReducer = combineReducers({
    user,
    data
});

export default persistReducer(persistConfig, rootReducer);