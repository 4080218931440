import { apiGet } from '../../apis/api';
import { GET_CAR_LIST } from './types';

export const getCarList = () => {
    // FAQ 목록 조회
    const request = apiGet("/cars").then(response => response);

    return {
        type: GET_CAR_LIST,
        payload: request
    }
}