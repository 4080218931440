/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useState } from "react";
import { Card, Form, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer2";
import NavBar from "../../components/NavBar/NavBar";
import { getChargerToQr } from "../../stores/actions/qr_action";

import { button_text } from "../../styles/colors";
import { BtnQRBlue } from "../../styles/themes";

function QRpayPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let [isSearchChager, setIsSearchChager] = useState("");
  const params = new URLSearchParams(window.location.search);
  let qrcode = params.get("qrcode");

  if (qrcode) {
    dispatch(getChargerToQr(qrcode)).then((response) => {
      if (response.payload.result === "OK") {
        // 조회 성공
        if (response.payload.data.qrcode) {
          navigate(response.payload.data.qrcode);
        } else {
          alert("비회원 현장결제를 지원하지 않는 충전기입니다.");
        }
      } else {
        setIsSearchChager(false);
      }
    });
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let qrcode = event.currentTarget.qrcode.value;
    dispatch(getChargerToQr(qrcode)).then((response) => {
      // console.log(response);
      if (response.payload.result === "OK") {
        // 조회 성공
        setIsSearchChager(true);
        let path = qrcode;
        navigate(path);
      } else {
        setIsSearchChager(false);
      }
    });
  };

  return (
    <>
      <NavBar />
      <div css={QRpayWrap}>
        <Card>
          <Card.Body>
            <h1
              className="text-center"
              css={css`
                padding: 5%;
              `}
            >
              비회원 QR 결제
            </h1>
            <div
              css={css`
                padding: 5%;
              `}
            >
              <Form onSubmit={onSubmitHandler}>
                <InputGroup>
                  <FormControl
                    placeholder="충전기에 부착된 QR번호를 입력해주세요"
                    name="qrcode"
                    className={isSearchChager === false ? "is-invalid" : ""}
                  />
                  <input type="hidden" name="isSearchChager" />
                  <button type="submit" css={BtnQRBlue} id="button-addon2">
                    검색
                  </button>
                  {!isSearchChager && (
                    <div className="invalid-feedback">
                      유효하지 않은 큐알코드/충전기 입니다.
                    </div>
                  )}
                </InputGroup>
              </Form>
              <div className="col-sm-12 mt-2">
                <small className="form-text text-muted">
                  큐알코드 번호는 충전기에서 확인할 수 있습니다.
                </small>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div
        css={css`
          width: 100%;
          height: 100vh;
        `}
      >
        <Footer />
      </div>
    </>
  );
}

const QRpayWrap = css`
  padding: 3% 15%;
  min-height: 500px;

  input[type="checkbox"] {
    display: none;
  }

  .card {
    border: none;

    h1 {
      padding-bottom: 32px;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }

    .card-body {
      padding: 0;

      .form_row {
        display: flex;
        padding-bottom: 24px;

        .input_div {
          width: 100%;
          height: 48px;
        }

        .label_div {
          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${button_text};

          min-width: 115px;
        }

        .text_div {
          width: 100%;
        }

        textarea {
          border-radius: 10px;
        }

        input[type="text"] {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
`;

export default QRpayPage;
