/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ResponseResult } from '../../apis/response';
import Footer from '../../components/Footer/Footer2';
import NavBar from '../../components/NavBar/NavBar';
import Pagenate from '../../components/Pagenate/Pagenate';
import ScrollTop from '../../components/ScrollTop/ScrollTop';
import Tab from '../../components/Tab/Tab';
import { support_tab } from '../../configs/base';
import { getNoticeList } from '../../stores/actions/notice_action';
import { UtctoLocaleFormmatter } from '../../utils/utils';

function NoticeListPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);

    const [Notice, setNotice] = useState([]);
    const [Keyword, setKeyword] = useState(params.get("search") ?? "");
    const [SearchType, setSearchType] = useState(params.get("searchType") ?? "전체");

    const [currentPage, setCurrentPage] = useState(params.get("currentPage") ?? "1");
    const [totalPage, setTotalPage] = useState();
    const cPage = params.get("currentPage");

    useEffect(() => {

        let reqParams = {
            "currentPage": currentPage
        }

        if (Keyword !== "") {
            reqParams = {
                ...reqParams,
                "searchType": SearchType,
                "searchText": Keyword
            }
        }

        dispatch(getNoticeList(reqParams)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    setNotice(response.payload.data.data);
                    setTotalPage(parseInt(response.payload.data.totalPage));
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }, []);

    useEffect(() => {

        let reqParams = {
            "currentPage": cPage
        }

        if (Keyword !== "") {
            reqParams = {
                ...reqParams,
                "searchType": SearchType,
                "searchText": Keyword
            }
        }

        dispatch(getNoticeList(reqParams)).then(
            response => {

                if (response.payload.result === ResponseResult.OK) {
                    setNotice(response.payload.data.data);
                    setCurrentPage(parseInt(response.payload.data.currentPage));
                    setTotalPage(parseInt(response.payload.data.totalPage));

                } else {
                    alert(response.payload.msg);
                }

            }
        )

    }, [currentPage, cPage, params.get("search"), params.get("searchType")])

    const onChangeKeywordHandler = (event) => {
        setKeyword(event.target.value);
    }

    const onChangeSearchTypeHandler = (event) => {
        setSearchType(event.target.value);
    }

    const onClickSearchHandler = () => {
        if (Keyword) {
            let reqParams = {
                "currentPage": 1,
                "searchType": SearchType,
                "searchText": Keyword
            }

            dispatch(getNoticeList(reqParams)).then(
                response => {
                    // console.log(response);
                    if (response.payload.result === ResponseResult.OK) {
                        setNotice(response.payload.data.data);
                        setTotalPage(parseInt(response.payload.data.totalPage));
                        navigate("?currentPage=1&searchType=" + SearchType + "&search=" + Keyword);
                    } else {
                        alert(response.payload.msg);
                    }
                }
            )

        } else {
            let reqParams = {
                "currentPage": currentPage
            }
            dispatch(getNoticeList(reqParams)).then(
                response => {
                    // console.log(response);
                    if (response.payload.result === ResponseResult.OK) {
                        setNotice(response.payload.data.data);
                        setTotalPage(parseInt(response.payload.data.totalPage));
                    } else {
                        alert(response.payload.msg);
                    }
                }
            )
        }
    }

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            onClickSearchHandler();
        }
    }

    // 페이지 클릭
    const onClickPageChangeHandler = (page) => {
        setCurrentPage(page);
        if (params.get("search")) {
            navigate("?currentPage=" + page + "&searchType=" + params.get("searchType") + "&search=" + params.get("search"));
        } else {
            navigate("?currentPage=" + page);
        }
    }

    // 상세페이지 이동
    const onClickNoticeDetailHandler = (event) => {
        navigate(event.currentTarget.id);
    }

    return (
        <React.Fragment>
            <NavBar />
            <div className="notice">
                <Card>
                    <Card.Body>
                        <div className="menu_tab">
                            <h1>고객센터</h1>
                            <Tab list={support_tab} active={0}></Tab>
                        </div>
                        <div className="content notice_list">
                            <Row className="mb-5">
                                <Col sm={2} css={css`margin-left: auto;`}>
                                    <Form.Select onChange={onChangeSearchTypeHandler} defaultValue={SearchType}>
                                        <option value="전체">전체</option>
                                        <option value="제목">제목</option>
                                        <option value="내용">내용</option>
                                    </Form.Select>
                                </Col>
                                <Col sm={5}>
                                    <InputGroup css={css`cursor: pointer;`}>
                                        <Form.Control autoComplete="off" onChange={onChangeKeywordHandler} onKeyDown={onKeyDownHandler} value={Keyword} />
                                        <InputGroup.Text onClick={onClickSearchHandler}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="text-center">순번</th>
                                        <th>제목</th>
                                        <th className="text-center">작성일시</th>
                                    </tr>
                                </thead>
                                <tbody css={bodyStyle}>
                                    {
                                        Notice.length !== 0
                                            ? Notice.map((n, index) => {
                                                return (
                                                    <tr key={"tr_" + index} id={n.id.toString()} onClick={onClickNoticeDetailHandler} css={css`cursor:pointer`}>
                                                        <td className="text-center" css={css`width: 10%`}>{n.indexNumber}</td>
                                                        <td css={css`width: 70%`}>{n.sj}</td>
                                                        <td className="text-center">{UtctoLocaleFormmatter(n.regDt)}</td>
                                                    </tr>
                                                );
                                            })
                                            : <tr><td colSpan={3} css={css`text-align: center;`}>공지사항이 존재하지 않습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagenate currentPage={currentPage} totalPage={totalPage} onClickPageChange={onClickPageChangeHandler} />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const bodyStyle = css`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
`;

export default NoticeListPage;

