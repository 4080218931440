/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import { background_gray1, black, black2, button_text, charger_gray, charger_gray2, disabled_gray2, green, inputbox_befor_typed, inputbox_stroke, light_blue, mypage_subtitle_blue, primary, scroll_gray, stroke_gray1, stroke_gray2, typo_light_blue, white } from './colors';
import ACCODIAN_SHOW from '../assets/images/accodian_show.png';
import ACCODIAN_HIDE from '../assets/images/accodian_hide.png';
import CHECKBOX_FALSE from '../assets/images/checkbox.png';
import CHECKBOX_TRUE from '../assets/images/checkbox_true.png';
import { dt_point, mypage_tab_tb } from './media-queries';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
      // reset
      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      caption, tbody, tfoot, thead,
      article, aside, canvas, details, embed, 
      figure, figcaption, footer, header, hgroup, 
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
      }
      /* HTML5 display-role reset for older browsers */
      article, aside, details, figcaption, figure, 
      footer, header, hgroup, menu, nav, section {
          display: block;
      }
      body {
          line-height: 1;
      }
      ol, ul {
          list-style: none;
      }
      blockquote, q {
          quotes: none;
      }
      blockquote:before, blockquote:after,
      q:before, q:after {
          content: '';
          content: none;
      }
      table {
          border-collapse: collapse;
          border-spacing: 0;
      }
      // reset

      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 700;
      //   src: url('../assets/fonts/NotoSansKR/NotoSansKR-Bold.otf') format('otf');
      // }

      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 400;
      //   src: url('../assets/fonts/NotoSansKR/NotoSansKR-Regular.otf') format('otf');
      // }

      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 300;
      //   src: url('../assets/fonts/NotoSansKR/NotoSansKR-Light.otf') format('otf');
      // }

      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 100;
      //   src: url('../assets/fonts/NotoSansKR/NotoSansKR-Thin.otf') format('otf');
      // }

      // @font-face {
      //   font-family: 'Spoqa Han Sans Neo';
      //   font-style: normal;
      //   font-weight: 700;
      //   src: url('../assets/fonts/SpoqaHanSansNeo/Spoqa Han Sans Neo Bold.woff2') format('woff2'),
      //   url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
      //   url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
      // }
    
      // @font-face {
      //     font-family: 'Spoqa Han Sans Neo';
      //     font-style: normal;
      //     font-weight: 400;
      //     src: url('../assets/fonts/SpoqaHanSansNeo/Spoqa Han Sans Neo Regular.woff2') format('woff2'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
      // }
      
      // @font-face {
      //     font-family: 'Spoqa Han Sans Neo';
      //     font-style: normal;
      //     font-weight: 300;
      //     src: url('../assets/fonts/SpoqaHanSansNeo/Spoqa Han Sans Neo Light.woff2') format('woff2'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff') format('woff'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
      // }
      
      // @font-face {
      //     font-family: 'Spoqa Han Sans Neo';
      //     font-style: normal;
      //     font-weight: 100;
      //     src: url('../assets/fonts/SpoqaHanSansNeo/Spoqa Han Sans Neo Thin.woff2') format('woff2'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff') format('woff'),
      //     url('../assets/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf') format('truetype');
      // }
   
      body, h1, h2, h3, h4, h5, h6, input, textarea, select {
        font-family: 'Noto Sans KR', sans-serif;
      }

      html, body, #root {
        width: 100%;
        height: 100%;
      }

      body {
        margin: 0;
        background: #FAFAFA;
        min-width: 375px;
      }

    .swiper {
      color: black;
      width: 100%;
      height: 100%;
    }

    // .swiper-slide {
    //   margin: 0;
    // }

    // .swiper-slide {
    //   text-align: center;
    //   font-size: 18px;
    //   background: #fff;

    //   /* Center slide text vertically */
    //   display: -webkit-box;
    //   display: -ms-flexbox;
    //   display: -webkit-flex;
    //   display: flex;
    //   -webkit-box-pack: center;
    //   -ms-flex-pack: center;
    //   -webkit-justify-content: center;
    //   justify-content: center;
    //   -webkit-box-align: center;
    //   -ms-flex-align: center;
    //   -webkit-align-items: center;
    //   align-items: center;
    // }

    // .swiper-slide img {
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }

   
    .swiper-pagination-bullet {
      background: transparent !important;
      border: 1px solid ${white};
      opacity: 1;
      width: 12px;
      height:12px;
    }

    .swiper-pagination-bullet-active {
      background: ${white} !important;
    }

    .swiper-pagination {
      margin-top: 10%;
    }

    .swiper-slide {
      width: 80%;
    }

    // .swiper-slide:nth-child(2n) {
    //   width: 60%;
    // }

    // .swiper-slide:nth-child(3n) {
    //   width: 40%;
    // }

      #root {
        // height: 100vh;
        display: flex;
        flex-direction: column;
      }

      // body {
      //   height: 100vh;
      // }

      a {
        text-decoration: none;
        color:#555;
      }

      ul {
        list-style: none;
      }

      .header_menu ul li {
        visibility: hidden;
      }

      .header_menu_wrap {
        :hover {
          .header_menu li ul li {
            visibility: visible;
          }
        }
      }

      // 사이드메뉴
      .sidebar {
        z-index: 20;
        background-color: ${primary};
        color: ${white};
        width: 287px;
        height: 100vh;
        padding: 0;
        position: fixed;
        right: -10px;
        top: 0;
        box-shadow: -10px 0px 4px rgba(0, 0, 0, 0.25);
        transition: transform 150ms ease-in;
        @media (min-width: ${dt_point}) { display: none }; @media (max-width: ${dt_point}) { display: block }
      }

      .sidebar_hidden {
        transform: translateX(18rem);
        transition: transform 200ms ease-out;
      }

      // 회원가입
      .regist {

        .text_box {
          // width: 500px;
          margin: 0 auto;
          background: ${charger_gray};
          border: 1px solid ${inputbox_stroke};

          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${typo_light_blue};
          border-radius: 10px;

          padding: 12px 5%;
        }

        table {
          margin: 2rem auto;
          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${black};
          // min-width: 500px;
          vertical-align: middle;

          th {
            border-right: solid 1px ${stroke_gray1};
            height: 60px;
          }

          tr {
            border-top: solid 1px ${stroke_gray1};
            border-bottom: solid 1px ${stroke_gray1};
          }
        }

        form {
          .form_row {
            display: flex;
            margin-top: 24px !important;
            
            select {
              border-radius: 10px;
              width: 100%;
              height: 100%;
              padding: 8px 21px;
              
              font-weight: 400;
              font-size: 16px;
              line-height: 225.3%;
              color: ${inputbox_befor_typed};
            }

            input {
              border-radius: 10px;
              width: 100%;
              height: 100%;
              padding: 8px 21px;
  
              ::placeholder {
                font-weight: 400;
                font-size: 16px;
                line-height: 225.3%;
                color: ${inputbox_befor_typed};
              }
    
              :focus {
                outline: none;
                border: 1px solid ${typo_light_blue};
              }
            }


            .input_div {
              width: 100%;
              height: 48px;
            }

            .label_div {
              font-weight: 400;
              font-size: 16px;
              line-height: 225.3%;
              color: ${button_text};
              min-width: 115px;
            }
          }
        }

        .agree_box {
          margin: 45px auto;
          border: 1px solid ${inputbox_stroke};
          border-radius: 10px;
          width: 100%;
          max-width: 512px;
          padding: 72px 45px;

          .policy {
            text-align: left;
            padding-bottom: 2rem;
          }

          .all {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: ${button_text};
            padding-bottom: 3rem;
            input[type="checkbox"]+label {
              padding-top: 3px;
            }
          }

          input[type="checkbox"] {
            display: none;
          }
    
          input[type='checkbox']:checked+label {
            background: url(${CHECKBOX_TRUE}) no-repeat 0 0px / contain;
            height:26px;
          }
    
          input[type="checkbox"]+label {
            :hover {
              cursor: pointer;
            }
            height:26px;
            padding-left: 44px;
            padding-top: 6px;
            background: url(${CHECKBOX_FALSE}) no-repeat 0 0px / contain;
          }
        }
      }

      // 페이지네이션 pagination
      .page-item.active .page-link {
        background-color: transparent;
        color: ${typo_light_blue};
        text-decoration-line: underline;
      }

      .page-link {
        border: none;
        color: ${black};
        :hover {
          background: transparent;
        }
      }

      .page-item {
        :hover {
          color: ${typo_light_blue};
        }
      }

      // .modal {
      //   height: 100vh;
      // }

      .modal-open {
        // padding-right: 0 !important;
        // overflow: auto !important;
      }

      .nav-tabs .nav-link {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        // border: 1px solid ${stroke_gray1};
        border: none;
        padding: 1.05vh 2.72vw;
      }

      .nav-link {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${black2};

        :hover {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color : ${black2};
        }
      }

      .nav-link.active {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: ${black2};
        border: none;
      }

      // .nav-item {
      //   width: 160px;
      // }

      .nav-tabs {
        border-bottom: none;

        .col-sm-2 {
          width: auto;
        }
      }

      .accordion-button:not(.collapsed) {
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        color: ${black2};
        background-color: ${white};
      }

      .accordion-collapse {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: ${black2};
        background: ${background_gray1};
      }
      .accordion-body {
        padding: 24px 31px 24px 31px;
      }

      // .accordion-button:not(.collapsed)::after {
      //   background-image: url(${ACCODIAN_SHOW});
      //   width: 8px;
      //   height: 4px;
      // }

      // .accordion-button::after {
      //   background-image: url(${ACCODIAN_SHOW});
      //   width: 8px;
      //   height: 4px;
      // }
      
      

      .charging::-webkit-scrollbar {
        height: 8px;
      }

      .charging::-webkit-scrollbar-track {
        background: ${scroll_gray};
      }

      .charging::-webkit-scrollbar-thumb {
        background: ${light_blue}; 
        border-radius: 12px;
      }

      .faq_list::-webkit-scrollbar {
        width: 0px;
      }

      .faq_list::-webkit-scrollbar-track {
        background: ${scroll_gray};
      }

      .faq_list::-webkit-scrollbar-thumb {
        background: ${light_blue}; 
        border-radius: 12px;
      }

      .charger_list::-webkit-scrollbar {
        width: 8px;
      }

      .charger_list::-webkit-scrollbar-track {
        background: ${scroll_gray};
      }

      .charger_list::-webkit-scrollbar-thumb {
        background: ${light_blue}; 
        border-radius: 12px;
      }

      .station_list::-webkit-scrollbar {
        width: 8px;
      }

      .station_list::-webkit-scrollbar-track {
        background: ${scroll_gray};
      }

      .station_list::-webkit-scrollbar-thumb {
        background: ${light_blue}; 
        border-radius: 12px;
      }

      .login, .find_lgnid, .find_password {
        input {
          width: 100%;
          height: 64px;
          background: ${white};
          border: 1px solid ${inputbox_stroke};
          border-radius: 10px;
          padding: 14px 29px 14px 29px;

          ::placeholder {
            font-weight: 400;
            font-size: 16px;
            line-height: 225.3%;
            color: ${inputbox_befor_typed};
          }

          :focus {
            outline: none;
            border: 1px solid ${typo_light_blue};
          }
        }
      }

      input::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }

      input[type="date"] {
        position: relative;
      }

      input[type="date"]::-webkit-calendar-picker-indicator 
      {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
      }

      input[type="date"] {
        border: 1px solid ${stroke_gray2};
        border-radius: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        padding: 4px 11px;
        min-width: 95px;
        height: 28px;
      }
      
      .tab {
        display: table;
        border-collapse: collapse;
 
        .active_div {
          border-bottom: 1px solid transparent !important;
        }

        .tab_div {
          display: table-cell;
          border: 1px solid ${stroke_gray1};
          background: ${background_gray1};
          min-width: 113px;
          
          :hover {
            background: ${white};
            border-bottom: none;
          }
        }

        ${mypage_tab_tb} {
          .nav-item {
            width: 100%;
          }
        }
      }

      .mypage-modal-alert {
        .title {
          display: flex;
          padding: 32px 36px;
          align-items: center;

          h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            color: ${disabled_gray2};
            margin: 0 auto;
          }
        }
      }

      
      // 마이페이지 팝업
      .mypage-modal {
        
        input[type="checkbox"] {
          display: none;
        }
  
        input[type='checkbox']:checked+label {
          background: url(${CHECKBOX_TRUE}) no-repeat 0 0px / contain;
          height:26px;
        }
  
        input[type="checkbox"]+label {
          :hover {
            cursor: pointer;
          }
          height:26px;
          padding-left: 44px;
          padding-top: 6px;
          background: url(${CHECKBOX_FALSE}) no-repeat 0 0px / contain;
        }

        .title {
          display: flex;
          padding: 32px 36px;
          align-items: center;

          h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            color: ${disabled_gray2};
            // margin-left: 155px;
            margin: 0 auto;
          }
        }

        .modal-content {
          height: 500px;
        }

        .modal-body {
          padding: 0 32px 32px 32px;
        }

        form {
          small {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: ${button_text};
          }

          p {
            text-align: left;
            padding-bottom: 16px;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: ${disabled_gray2};
          }

          .label_div {
            width: 100%;
          }

          input {
            background: ${white};
            border: 1px solid ${stroke_gray1};
            border-radius: 5px;
            width: 100%;
            height: 100%;
            padding: 8px 21px;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            ::placeholder {
              font-weight: 400;
              font-size: 16px;
              line-height: 225.3%;
              color: ${inputbox_befor_typed};
            }
  
            :focus {
              outline: none;
              border: 1px solid ${typo_light_blue};
            }
          }

          .input_div {
            height: 44px;
            width: 100%;
          }
        }
      }

      .service {
        .section {
          padding: 5% 5% 5% 20%;
        }

        section {
          padding: 5% 5% 5% 14.27vw;

          .arrow {
            padding: 0 10px 0 10px;
          }

          .blue_text {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: ${light_blue};
            padding-top: 10px;
          }

          // .number {
          //   font-weight: 200; 
          //   font-size: 50px; 
          //   line-height: 58px; 
          //   color: ${green};
          // }

          // .title {
          //   font-weight: 300;
          //   font-size: 32px;
          //   line-height: 100%;
          //   color: ${black};
          //   margin-bottom: 17px;
          // }

          // .text {
          //   font-weight: 400;
          //   font-size: 15px;
          //   line-height: 160%;
          //   color: ${black};
          // }
        }
      }

      @media (max-width: 767.98px) {
        .modal-fullscreen-md-down {
          width: 100% !important;
        }
      }


      .service, .notice, .faq {
        // padding: 3% 15%;
        padding: 5.58vh 18.2vw;
        
        // 공지사항 리스트
        .notice_list {

          table {
            width: 100%;
          }

          thead {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            padding-bottom: 7px;
            color: ${mypage_subtitle_blue};

            tr {
              border-bottom: 1px solid ${stroke_gray1};
            }
          }

          tbody {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: ${black};

            tr {
              height: 60px;
              vertical-align: middle;
              border-bottom: 1px solid ${stroke_gray1};
            }
          }
        }

        .detail-content {
          table {
            width: 100% !important;
          }

          img {
            width: 100%;
          }
        }

        .card {
          min-height: 721px;
          // min-width: 711px;
          min-width: 655px;
          border: none;

          .menu_tab {
            margin: 27px 64px 0 64px;

            h1 {
              font-size:24px; 
              font-weight: 700;
              padding-bottom: 32px;
            }
          }

          .content {
            margin: 30px 64px;
          }
        }

        .card-body {
          padding: 0;
        }
      }

      // 설치 문의
      .reception_form, .qr_form {
        sub {
          color: #2c98f0;
          padding-left: 15px;
          bottom: -0.25em;
        }

        // padding: 3% 15%;
        padding: 5.58vh 18.2vw;

        input[type="checkbox"] {
          display: none;
        }
  
        input[type='checkbox']:checked+label {
          background: url(${CHECKBOX_TRUE}) no-repeat 0 0px / contain;
          height:26px;
        }
  
        input[type="checkbox"]+label {
          :hover {
            cursor: pointer;
          }
          height:26px;
          padding-left: 44px;
          padding-top: 6px;
          background: url(${CHECKBOX_FALSE}) no-repeat 0 0px / contain;
        }

        .card {
          // min-height: 721px;
          // min-width: 711px;
          min-width: 655px;
          border: none;

          h1 {
            padding-bottom: 32px;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
          }

          .card-body {
            padding: 0;

            .form_row {
              display: flex;
              padding-bottom: 24px;

              .input_div {
                width: 100%;
                height: 48px;
              }

              .label_div {
                font-weight: 400;
                font-size: 16px;
                line-height: 225.3%;
                color: ${button_text};
    
                min-width: 115px;
              }

              .text_div {
                width: 100%;
              }

              textarea {
                border-radius: 10px;
              }

              input[type="text"] {
                width: 100%;
                height: 100%;
                // border: 1px solid ${inputbox_stroke};
                border-radius: 10px;
              }
            }
          }

          
        }
      }
      
      // 설치안내
      .reception {
        padding: 5.58vh 18.2vw;

        .card {
          min-height: 721px;
          // min-width: 711px;
          min-width: 655px;
          border: none;

          .card-body {
            padding: 0;
            // .bold_text {
            //   font-weight: 700; 
            //   font-size: 18px; 
            //   line-height: 26px; 
            //   margin: 16px 0 9px 0;
            }

            h1 {
              padding-bottom: 32px;
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
            }

            .section1 {
              padding: 11vh 0;

              .title_dt_div {
                display: flex; 
                justify-content: center; 
                align-items: self-start;
              
                @media (max-width: 929px) {
                  display: none;
                }
              }

              .title_tb_div {
                text-align: center;
                justify-content: center; 
                align-items: self-start;
                @media all and (min-width: 930px) {
                  display: none;
                }
              }

              .title_reception {
                font-weight: 700; 
                height: 35px; 
                font-size: 24px; 
                line-height: 30px; 
                color:${black2}; 
                margin-right: 9px;
              }
            }

            .section4{
              height: 500px;
              background: ${charger_gray2};
              padding: 60px 0 0 0;
            }

            .section5{
              height: 227px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            strong {
              color: ${button_text};
            }
          }
        }
      }
    `} />
  );
};
export default GlobalStyle;