import * as yup from "yup";
import * as messages from "../messages/messages";

export const updateUserInfoSchema = yup.object({
    Phone: yup
        .string()
        // .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요.")
        .required(messages.requiredMessage),
    Rdnmadr: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    AdresDetail: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, "255자리 이하로 입력해주세요."),
    CarId: yup
        .string()
        .required(messages.requiredMessage),
    CarNo: yup
        .string()
        .required(messages.requiredMessage)
        .test(
            "match",
            messages.carNumberMessage,
            value => {
                if (value) {
                    if (value.match(/^\d{2,3}[가-힣]\s?\d{4}$/)) {
                        return true;
                    }
    
                    if (value.match(/^[가-힣]{2}\s?\d{2,3}[가-힣]\s?\d{4}$/)) {
                        return true;
                    }
                }
                
                return false
            }
        ),
}).required();
