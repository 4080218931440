import { EDIT_USER_DONE, EDIT_USER_ING, REGIST_USER_DONE, REGIST_USER_ING } from './../actions/types';

const initialState = {};

export const dataAction =  function (
    state = initialState,
    action 
) {
    switch(action.type) {
        case REGIST_USER_ING:
            return {...state, data : action.payload}
        case REGIST_USER_DONE:
            return initialState
        case EDIT_USER_ING:
            return {...state, data : action.payload}
        case EDIT_USER_DONE:
            return initialState
        default:
            return state;
    }
};

export default dataAction;