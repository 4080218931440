/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as themes from '../../styles/themes';
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import SEOULEV_LOGO from '../../assets/images/logo.png';
import { loginUser, logoutUser } from '../../stores/actions/user_action';
import { useDispatch } from 'react-redux';
import { ResponseResult } from '../../apis/response';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { private_api } from '../../apis/api';
import { black, charger_gray, inputbox_stroke, red, typo_light_blue, white } from '../../styles/colors';
import { tb_point } from '../../styles/media-queries';
import LINE from '../../assets/images/line.png';

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [searchParams, setSearchParams] = useSearchParams();

  const [LgnId, setLgnId] = useState("");
  const [LgnPassword, setLgnPassword] = useState("");
  const [LoginFail, setLoginFail] = useState("");

  // useEffect(() => {
  //   if (user) {
  //     dispatch(logoutUser());
  //   }
  // }, []);

  const onChangeLgnIdHandler = (event) => {
    setLgnId(event.target.value);
  }

  const onChangeLgnPasswordHandler = (event) => {
    setLgnPassword(event.target.value);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();

    let reqParams = {
      lgnId: LgnId,
      lgnPassword: LgnPassword,
    }

    dispatch(loginUser(reqParams)).then(
      response => {
        if (response.payload.result === ResponseResult.OK) {
          // 로그인 성공시
          let aat = response.payload.data.accessToken;

          private_api.interceptors.request.use(function (config) {
            const token = aat;
            // console.log(token);
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
          });

          if (searchParams.get("redirectUrl")) {
            // 리다이렉트 url 존재시
            navigate(searchParams.get("redirectUrl"));
          } else {
            navigate("/");
          }
        } else {
          setLoginFail(response.payload.msg);
          setLgnPassword("");
        }
      }
    );
  };

  return (
    <React.Fragment>
      <div css={loginWrap}>
        <Card css={loginContentsWrap}>
          <Card.Body>
            <Link to="/">
              <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" />
            </Link>
            <h1 css={loginTitle}>
              로그인
            </h1>
            <Form onSubmit={onSubmitHandler} className="login">
              <div css={themes.loginTheme}>
                <div css={css`padding-top: 24px; padding-bottom: 5%; width: 100%; margin: auto; max-width: 500px`}>
                  <div className="mb-3">
                    <input type="text" placeholder="아이디" value={LgnId} onChange={onChangeLgnIdHandler} />
                  </div>
                  <div className="mb-3">
                    <input type="password" placeholder="비밀번호" value={LgnPassword} onChange={onChangeLgnPasswordHandler} />
                  </div>
                  {
                    LoginFail !== "" &&
                    <div css={css`background: ${charger_gray}; border: 1px solid ${inputbox_stroke}; border-radius: 10px; padding: 12px 85px; font-weight: 400; font-size: 16px; line-height: 225.3%; color: ${red}`} className="mb-3">
                      {LoginFail}
                    </div>
                  }
                  <Form.Group css={css`width: 100%; height: 64px;`}>
                    <button css={themes.BtnFullBlue2} disabled={LgnId !== "" && LgnPassword !== "" ? false : true} type="submit">로그인</button>
                  </Form.Group>

                  <div css={css`margin-top: 50px;`}>
                    <div>
                      <Link css={css`float:left; font-weight: 700; font-size: 15px; line-height: 15px; color: ${typo_light_blue}`} to="/register">회원가입</Link>
                      <div css={css`float:right; font-weight: 400; font-size: 15px; line-height: 22px;`}>
                        <Link to="/find_login_id" css={css`color: ${black}`}>아이디 찾기</Link>
                        <img src={LINE} alt="line" css={lineWrap} />
                        <Link to="/find_password" css={css`color: ${black}`}>비밀번호 찾기</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  )
}

const loginWrap = css`
  position: relative;
  min-width: ${tb_point};
  height: 100vh;
  background: ${white};
`;

const loginContentsWrap = css`
  padding:10%; 
  border: none; 
  width:100%;
  height:100%;
  text-align: center;
`;

const logoWrap = css`
  margin-bottom: 102px;
`;

const loginTitle = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${black};
`;

const lineWrap = css`
    padding: 0 8px 0 8px;
`;

export default LoginPage