/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Card } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import SEOULEV_LOGO from '../../assets/images/logo.png';
import { black, white } from '../../styles/colors';
import { tb_point } from '../../styles/media-queries';
import { BtnOutLineBlack, BtnOutLineBlue } from '../../styles/themes';

function RegisterCompletePage() {
    const { state } = useLocation();

    return (
        <React.Fragment>
            <div css={registWrap} className="regist">
                <Card css={wrap} >
                    <Card.Body >
                        <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo"/>
                        <h1 css={titleStyle}>
                            SEOULEV 계정 가입 완료
                        </h1>
                        <table css={css`width: 100%;`}>
                            <tbody>
                                <tr>
                                    <th css={css`width:40%`}>이름</th>
                                    <td>{state ? state.Nm : "-"}</td>
                                </tr>
                                <tr>
                                    <th css={css`width:40%`}>아이디</th>
                                    <td>{state ? state.LgnId : "-"}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="text_box">
                            모든 회원가입 절차가 완료되었습니다. <br/>
                            로그인 후 모든 서비스를 이용하실 수 있습니다.
                        </div>
                        <div css={css`display: flex; margin: 1rem auto; justify-content: center;`}>
                            <div css={css`width: 50%; height: 64px; margin-right: 8px;`}>
                                <Link to="/login"><button css={BtnOutLineBlue} type="button">로그인하러 가기</button></Link>
                            </div>
                            <div css={css`width: 50%; height: 64px;`}>
                                <Link to="/"><button css={BtnOutLineBlack} type="button">메인으로</button></Link>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}

const registWrap = css`
    position: relative;
    width: 100%;
    height: 100vh;
    background: ${white};

    tr {
        vertical-align: middle;
    }
`;

const wrap = css`
    border: none; 
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const logoWrap = css`
    padding-bottom: 96px;
`;

const titleStyle = css`
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${black};
    margin-bottom: 27px;
`;

export default RegisterCompletePage