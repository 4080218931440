/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Card, CloseButton, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { hide, TextStyle10, TextStyle9 } from '../../styles/themes';
import * as color from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import ModalLocationAgree from '../../components/Modal/ModalLocationAgree';
import { charger_speed, default_location } from '../../configs/base';
import NavBar from '../../components/NavBar/NavBar';
import { useDispatch } from 'react-redux';
import { getSearchStation, getStation, getStationList } from '../../stores/actions/station_action';
import MARKER_IMG from '../../assets/images/marker.png';
import { ResponseResult } from '../../apis/response';
// import LOCATION_ICON from '../../assets/images/location_icon.png';
import CLOSE_ICON from '../../assets/images/close_icon.png';
import { DESKTOP, dt_point, tb_point } from '../../styles/media-queries';
import Pagenate from '../../components/Pagenate/Pagenate';
import { useNavigate } from 'react-router';
import LoadingDiv from '../../components/Loading/LoadingDiv';

function SearchStationsPage() {
    const { kakao } = window;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);

    const [currentPage, setCurrentPage] = useState(params.get("currentPage") ?? "1");
    const [totalPage, setTotalPage] = useState();

    const [IsSearching, setIsSearching] = useState(false);
    const [Stations, setStations] = useState([]);

    const { register } = useForm();
    const [SearchCardStyle, setSearchCardStyle] = useState([searchDivStyle]);
    const [DetailStyle, setDetailStyle] = useState([detailDivStyle, hide]);
    const [FakeButtonStyle] = useState(fakeStyle);
    const [SelectStation, setSelectStation] = useState([]);

    const [IsSearchListShow, setIsSearchListShow] = useState(false);

    const [Map, setMap] = useState(null);
    const [Markers, setMarkers] = useState([]);
    const [SearchShow, setSearchShow] = useState(false);
    const [SearchKeyword, setSearchKeyword] = useState("");
    const [LocationAgree, setLocationAgree] = useState(null);

    const [Loading] = useState(false);
    const [Location, setLocation] = useState({ lat: default_location.lat, lng: default_location.lng });


    const ChargerStatus = {
        AA: "사용불가",
        AB: "사용가능",
        AC: "충전중",
        AD: "사용불가",
        AE: "사용불가",
        AF: "사용불가",
        AG: "사용불가",
        AH: "사용불가",
        AI: "사용불가",
        AJ: "사용불가",
        AK: "사용불가",
        AL: "사용불가"
    }

    const onChangeKeywordHandler = (event) => {
        setSearchKeyword(event.target.value);
        setIsSearching(true);

        if (event.target.value === "") {
            setIsSearching(false);
        }
    };

    // 페이지 클릭
    const onClickPageChangeHandler = (page) => {
        setCurrentPage(page);
        if (params.get("search")) {
            navigate("?currentPage=" + page + "&searchType=" + params.get("searchType") + "&search=" + params.get("search"));
        } else {
            navigate("?currentPage=" + page);
        }
    }

    const onClickLocationChangerHandler = (lat, lng) => {
        var moveLatLon = new kakao.maps.LatLng(lat, lng);
        Map.panTo(moveLatLon);

        setDetailStyle([detailDivStyle, hide]);
    }

    const onClickSearchCloseHandler = () => {
        setSearchKeyword("");
        setIsSearching(false);
        setIsSearchListShow(false);
    }

    const onClickSearchHandler = (event) => {
        if (SearchKeyword) {
            let reqParams = {
                "searchText": SearchKeyword,
                "perPage": 10,
                "currentPage": currentPage,
            }

            dispatch(getSearchStation(reqParams)).then(
                response => {
                    // console.log(response);
                    if (response.payload.result === ResponseResult.OK) {
                        setStations(response.payload.data.data);
                        setSearchShow(!SearchShow);
                        setSearchCardStyle([searchDivStyle, searchDivHeightStyle]);
                        setCurrentPage(parseInt(response.payload.data.currentPage));
                        setTotalPage(parseInt(response.payload.data.totalPage));
                    } else {
                        alert(response.payload.msg);
                    }
                }
            )
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.key === "Enter") {
            onClickSearchHandler();
        }
    }

    const onClickDetailCloseHandler = () => {
        setDetailStyle([detailDivStyle, hide]);
    }

    // useEffect(() => {
    //     if (Map) {
    //         // if (LocationAgree === true) {
    //         //     // 동의
    //         //     console.log("Agree");
    //         //     setLoading(true);
    //         //     window.navigator.geolocation.getCurrentPosition((position) => {
    //         //         console.log(position);
    //         //         var moveLatLon = new kakao.maps.LatLng(position.coords.latitude, position.coords.longitude);
    //         //         Map.setLevel(6);
    //         //         Map.panTo(moveLatLon);
    //         //         setLoading(false);

    //         //         setCurrentLocation({
    //         //             lat: position.coords.latitude,
    //         //             lng: position.coords.longitude
    //         //         });

    //         //         setLocation({
    //         //             lat: position.coords.latitude,
    //         //             lng: position.coords.longitude
    //         //         });

    //         //         console.log("dhksfy");
    //         //     });
    //         // } else {
    //         //     console.log("No Agree");
    //         //     // 미동의 or esc 종료
    //             var moveLatLon = new kakao.maps.LatLng(Location.lat, Location.lng);
    //             Map.setLevel(6);
    //             Map.panTo(moveLatLon);

    //             setLocation({
    //                 lat: Location.lat,
    //                 lng: Location.lng
    //             });
    //         // }
    //     }
    // }, [LocationAgree]);

    // const onClickLocationAgreeHandler = () => {
    //     // 위치정보 제공 동의
    //     setModalShow(false);
    //     setLocationAgree(true);
    // }

    // const onClickLocationDisAgreeHandler = () => {
    //     // 위치정보 제공 비동의
    //     setModalShow(false);
    //     setLocationAgree(false);
    // }

    // const onClickCurrentLocationHandler = () => {
    //     // 현재위치 이동
    //     setIsSearching(false);
    //     setDetailStyle([detailDivStyle, hide]);

    //     if (LocationAgree === false) {
    //         setModalShow(true);
    //     } else {
    //         var moveLatLon = new kakao.maps.LatLng(CurrentLocation.lat, CurrentLocation.lng);
    //         setLocation({
    //             lat: CurrentLocation.lat,
    //             lng: CurrentLocation.lng
    //         });
    //         Map.setLevel(6);
    //         Map.panTo(moveLatLon);  
    //     }
    // }

    const handleEsc = (event) => {
        if (event.keyCode === 27 && LocationAgree === null) {
            setLocationAgree(false);
        }
    };

    useEffect(() => {
        // 처음 로딩시
        var container = document.getElementById('station_map');

        setLocation({
            lat: Location.lat,
            lng: Location.lng
        });

        var options = {
            center: new kakao.maps.LatLng(Location.lat, Location.lng),
            level: 7
        };

        var map = new kakao.maps.Map(container, options);

        kakao.maps.event.addListener(map, 'dragend', function () {
            // 지도 중심좌표를 얻어옵니다 
            var latlng = map.getCenter();

            setLocation({
                lat: latlng.getLat(),
                lng: latlng.getLng()
            });
        });

        kakao.maps.event.addListener(map, 'zoom_changed', function () {
            var latlng = map.getCenter();

            setLocation({
                lat: latlng.getLat(),
                lng: latlng.getLng()
            });
        });

        setMap(map);

        window.addEventListener('keydown', handleEsc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // 지도 좌표 변경시
        if (Map && !IsSearching) {
            const lb = Map.getBounds();
            const polyline = new kakao.maps.Polyline({
                /* map:map, */
                path: [
                    lb.getSouthWest(),
                    lb.getNorthEast(),
                ],
                // strokeColor: '#FFAE00',
                // strokeStyle: 'solid',
                // strokeWeight: 5,
            });
            // polyline.setMap(Map);  

            let distance = (polyline.getLength() / 1000) / 2;
            let reqParams = {
                "distance": distance,
                "lat": Location.lat,
                "lng": Location.lng
            }

            // 충전소 정보 받아오기
            dispatch(getStationList(reqParams)).then(
                response => {
                    // console.log(response);
                    if (response.payload.result === ResponseResult.OK) {
                        setStations(response.payload.data);
                    } else {
                        alert(response.payload.msg);
                    }
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Location]);

    useEffect(() => {
        if (Stations.length > 0) {
            setIsSearchListShow(true);
        }

        Markers.map((marker) => {
            return marker.setMap(null);
        });

        Stations.map(function (station, index) {
            const marker = new kakao.maps.Marker({
                map: Map,
                // image: markerImage // 마커이미지 설정 
                position: new kakao.maps.LatLng(station.lat, station.lng),
                clickable: true, // 마커를 클릭했을 때 지도의 클릭 이벤트가 발생하지 않도록 설정합니다
                image: new kakao.maps.MarkerImage(MARKER_IMG, new kakao.maps.Size(29, 46))
            });

            kakao.maps.event.addListener(marker, 'click', () => {
                var moveLatLon = new kakao.maps.LatLng(station.lat, station.lng);
                Map.panTo(moveLatLon);
                setDetailStyle([detailDivStyle, closeStyle]);
                dispatch(getStation(station.stationIdntfr)).then(
                    response => {
                        if (response.payload.result === ResponseResult.OK) {
                            setSelectStation(response.payload.data);
                        } else {
                            alert(response.payload.msg);
                        }
                    }
                )
            });
            setMarkers(prevMarker => [...prevMarker, marker]);
            return marker;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Stations]);

    return (
        <React.Fragment>
            {Loading ? <LoadingDiv /> : null}
            <div css={css`position: relative; width: 100%; height:100%;`}>
                <NavBar />
                <div className="map_wrap" css={css`width: 100%; height: 100%;`}>
                    <div id="station_map" css={css`width: 100%; height: calc(100% - 104px); display: block; @media screen and (max-width: ${dt_point}) { height: calc(100% - 60px);}`}>
                        <div css={FakeButtonStyle}>
                            <Card css={SearchCardStyle}>
                                <Card.Body css={css`padding:0;`}>
                                    <Form.Group>
                                        <InputGroup css={css`cursor: pointer;`}>
                                            <Form.Control css={css`border-top: 1px solid ${color.typo_light_blue}; border-left: 1px solid ${color.typo_light_blue}; border-bottom: 1px solid ${color.typo_light_blue}; border-right: none; font-size: 12px; height: 44px;`} {...register("Keyword")} placeholder="주소로 검색" autoComplete="off" onChange={onChangeKeywordHandler} onKeyDown={onKeyDownHandler} value={SearchKeyword} />
                                            <InputGroup.Text css={css`background-color: ${color.typo_light_blue}; border-left: none; border-top: 1px solid ${color.typo_light_blue}; border-bottom: 1px solid ${color.typo_light_blue}; border-right: 1px solid ${color.typo_light_blue}; width: 44px; height: 44px; justify-content: center;`} onClick={onClickSearchHandler}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass} css={css`color: ${color.white};`} />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    {
                                        // (SearchKeyword !== "" && IsSearching) &&
                                        IsSearchListShow &&
                                        <Form.Group>
                                            <div css={css`font-weight: 400; font-size: 12px; line-height: 225.3%; color: ${color.black}; padding: 11px; display: flex; align-items: center;`}> 
                                                <div>검색결과 {Stations.length}개</div>
                                                <img src={CLOSE_ICON} alt="close_icon" css={css`margin-left: 9px; &:hover{ cursor: pointer; }`} onClick={onClickSearchCloseHandler} />
                                            </div>
                                            <div css={css`overflow-y: auto; height: calc(100vh - 272px);`} className="station_list">
                                                {
                                                    Stations.map((station, idx) => {
                                                        return <React.Fragment key={idx}>
                                                            <div css={stationBox} onClick={(e) => { onClickLocationChangerHandler(station.lat, station.lng) }}>
                                                                <h1 css={css`font-weight: 700; font-size: 18px; line-height: 26px; color: ${color.typo_light_blue};`}>{station.nm ?? "-"}</h1>
                                                                <h2 css={css`font-weight: 400; font-size: 13px; line-height: 19px; color: ${color.black};`}>{station.rdnmadr ? station.rdnmadr : station.adres}</h2>
                                                            </div>
                                                        </React.Fragment>
                                                    })
                                                }
                                                {
                                                    Stations.length > 0 &&
                                                    <Pagenate currentPage={currentPage} totalPage={totalPage} onClickPageChange={onClickPageChangeHandler} />
                                                }
                                            </div>
                                        </Form.Group>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                        <Card css={DetailStyle}>
                            <Card.Body css={css`height: 100%;`}>
                                <div className="text-left">
                                    <Row css={css`align-items: center; border-bottom: 1px solid ${color.stroke_gray1};`}>
                                        <Col sm={10}>
                                            <h1 css={css`font-weight: 700;
                                                font-size: 16px;
                                                line-height: 225.3%;
                                                color: #000000;
                                                `}>충전소 상세</h1>
                                        </Col>
                                        <Col sm={2}>
                                            <CloseButton css={css`padding:0px; margin: 0px;`} onClick={onClickDetailCloseHandler} />
                                        </Col>
                                    </Row>
                                    <Row css={css`padding: 1rem 0; border-bottom: 1px solid ${color.stroke_gray1};`}>
                                        <h1 css={css`
                                            font-weight: 700;
                                            font-size: 18px;
                                            line-height: 26px;
                                            display: flex;
                                            align-items: center;
                                            color: ${color.typo_light_blue};
                                            padding-bottom: 5px;
                                        `}>{SelectStation.nm}</h1>
                                        <h1 css={css`font-weight: 700;
                                            font-size: 13px;
                                            line-height: 19px;
                                            color: ${color.stroke_gray2};
                                        `}>주소 <span css={css`
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 19px;
                                            color: #000000;
                                            padding-left: 5px;
                                        `}>{SelectStation.rdnmadr ? SelectStation.rdnmadr : SelectStation.adres}</span></h1>
                                    </Row>
                                </div>
                                <div className="charger_list mt-3" css={css`overflow-y: auto; height: calc(100vh - 500px);`}>
                                    <div>
                                        <h1 css={css`
                                        font-weight: 700;
                                        font-size: 16px;
                                        line-height: 225.3%;
                                        color: #000000;
                                        `}>충전기 목록</h1>
                                    </div>
                                    <div css={css`font-weight: 700;
                                    font-size: 12px;
                                    line-height: 17px;`}>
                                        {
                                            Object.keys(SelectStation).length > 0 && SelectStation.chargers.length > 0
                                                ? SelectStation.chargers.map((charger, idx) => {
                                                    return <div key={"c_div_" + idx} css={detailChargerPaddingWrap}>
                                                        <div css={detailChargerBox}>
                                                            <div css={css`display: flex; padding-bottom: 8px;`}>
                                                                <div css={[TextStyle9, css`width:70px;`]}>
                                                                    충전기 ID
                                                                </div>
                                                                <div css={TextStyle10}>
                                                                    {charger.chrgrIdntfr.replace(SelectStation.stationIdntfr, "")}
                                                                </div>
                                                            </div>
                                                            <div css={css`display: flex; padding-bottom: 8px;`}>
                                                                <div css={[TextStyle9, css`width:70px;`]}>
                                                                    충전기 상태
                                                                </div>
                                                                <div css={[(ChargerStatus[charger.sttus] === "충전중" && css`color: ${color.blue};`)
                                                                    || (ChargerStatus[charger.sttus] === "사용불가" && css`color: ${color.status_red};`)
                                                                    || (ChargerStatus[charger.sttus] === "사용가능" && css`color: ${color.status_green};`), TextStyle10]}>
                                                                    {ChargerStatus[charger.sttus]}
                                                                </div>
                                                            </div>
                                                            <div css={css`display: flex;`}>
                                                                <div css={[TextStyle9, css`width:70px;`]}>
                                                                    타입
                                                                </div>
                                                                <div css={TextStyle10}>
                                                                    {parseInt(charger.cpcty) < charger_speed ? "완속" : "급속"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                                : "충전기가 존재하지 않습니다."
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        {/* <button css={[BtnFullBlue, css`z-index:200; width:124px; position:absolute; left: auto; bottom: 3%; right: 2%;display: flex; align-items: center; justify-content: space-evenly;`]}
                            onClick={onClickCurrentLocationHandler}
                            >현재위치 <img src={LOCATION_ICON} alt="location_icon"/></button> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

// const searchStyle = css`
//     background-color: white;
//     // width:20%; 
//     z-index:200;
//     // position: absolute;
//     height:100%;

//     -webkit-transform: translate3d(-100%, 0px, 0px);
//     transform: translate3d(-100%, 0px, 0px);
//     visibility: visible;
//     transition: all 0.3s ease 0s;
//     -webkit-transition: all 0.3s ease 0s;
// `;

const closeStyle = css`
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
`;

const searchDivStyle = css`
    // margin-left: 10px; 
    width: 100%; 
    border:none;
`

const fakeStyle = css`
    width: 285px; 
    top: 30px;
    left: 10px;
    right: auto;
    z-index: 5; 
    position: absolute;
`;

const detailDivStyle = css`
    right: 2%;
    top: 30px;
    width: 333px; 
    height: 80%; 
    z-index: 5; 
    position: absolute;
    border:none;
`;

const detailChargerPaddingWrap = css`
    padding: 0 10px 16px 0;
`;

const detailChargerBox = css`
    border: 1px solid ${color.inputbox_stroke};
    border-radius: 10px;
    padding: 17px; 
    background: ${color.charger_gray};

    &:hover {
        border: 1px solid ${color.typo_light_blue};
        background: ${color.charger_gray2};
    }
`;

const stationBox = css`
    border-top: 1px solid ${color.stroke_gray1};
    padding: 16px;

    &:hover {
        cursor: pointer;
        background: ${color.charger_gray2};
    }
`;

const searchDivHeightStyle = css`
    height: 85%;
`

export default SearchStationsPage;