/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Tab = ({ list, active, userId }) => {
  return (
    <Nav
      variant="tabs"
      defaultActiveKey={list[0].link}
      className="text-center tab"
    >
      {list.map((obj, index) => (
        <div
          key={index}
          className={index === active ? "active_div tab_div" : "tab_div"}
        >
          <NavLink
            to={obj.link}
            className={({isActive})=> isActive ? "nav-link active" : "nav-link"}
          >
            <Nav.Item>{obj.nm}</Nav.Item>
          </NavLink>
        </div>
      ))}
    </Nav>
  );
};

export default Tab;
