/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal, Row } from "react-bootstrap";
import { useNavigate } from 'react-router';
import { black } from '../../styles/colors';
import { BtnFullBlue2, BtnOutLineBlue } from '../../styles/themes';

export default function ModalLoginRequire(props) {
    let navigate = useNavigate(); 
    
    const onClickBackPageHandler = () => {
        navigate("/");
    }

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            autoFocus
            fullscreen="md-down"
        >
        <Modal.Body className="container1 text-center" css={css`padding: 32px`}>
            <Row className="mb-3">
                <div className="mb-3">
                    <h1 css={css`font-weight: 700; font-size: 24px; line-height: 24px; color: ${black}`}>로그인 필요</h1>
                </div>
                <h5 css={css`font-weight: 400; font-size: 16px; line-height: 24px; color: ${black}`}>로그인 후 이용 가능합니다.<br/>
                로그인하시겠습니까?</h5>
            </Row>
            <Row css={css`display: flex; justify-content: space-evenly;`}>
                <div css={css`width: 50%; height:56px;`}>
                    <button css={[BtnOutLineBlue, css`font-weight: 700; font-size: 18px; line-height: 18px;`]} onClick={onClickBackPageHandler}>아니오</button>
                </div>
                <div css={css`width: 50%; height:56px;`}>
                    <button css={[BtnFullBlue2, css`font-weight: 700; font-size: 18px; line-height: 18px;`]} onClick={props.onClickLoginPageHandler}>예</button>
                </div>
            </Row>
        </Modal.Body>
      </Modal>
    );
};