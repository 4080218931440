import { ResponseResult } from './response';
import axios from "axios";
import { reloginAlertMessage } from '../validations/messages/messages';

// const SERVER_ADDRESS = 'https://api-dev.evplug.co.kr/api/v1';
const SERVER_ADDRESS = process.env.REACT_APP_API_URL;

let isTokenRefreshing = false;
let refreshSubscribers = [];


const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};


const get_access_token = () => {
    let session = sessionStorage.getItem("persist:root");

    if (session) {
        let user = JSON.parse(session).user;
        let aat = null;

        if (user) {
            aat = JSON.parse(user).aat;
        }

        return aat;
    }

    return "";
}

const get_refresh_tokn = () => {
    let session = sessionStorage.getItem("persist:root");

    if (session) {
        let user = JSON.parse(session).user;
        let art = null;

        if (user) {
            art = JSON.parse(user).art;
        }

        return art;
    }

    return "";
}

const api = axios.create({
    baseURL: `${SERVER_ADDRESS}`,
    timeout: 6000,
    headers: { "Content-Type": 'application/json' },
});

export const private_api = axios.create({
    baseURL: `${SERVER_ADDRESS}`,
    timeout: 6000,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + get_access_token()
    },
});


private_api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            config,
            response: { status },
        } = error;

        const originalRequest = config;

        if (status === 401) {
            // console.log(401); 
            if (!isTokenRefreshing) {
                // isTokenRefreshing이 false인 경우에만 token refresh 요청
                isTokenRefreshing = true;
                refresh_api.get("/auth/refreshToken").then(
                    response => {
                        // console.log(response);
                        let aat = response.data.data.accessToken;
                        let session = JSON.parse(sessionStorage.getItem("persist:root"));
                        let user_session = JSON.parse(session.user);
                        user_session.aat = aat;
                        session.user = JSON.stringify(user_session);
                        sessionStorage.setItem("persist:root", JSON.stringify(session));
                        isTokenRefreshing = false;
                        axios.defaults.headers.common.Authorization = `Bearer ${aat}`;
                        // 새로운 토큰으로 지연되었던 요청 진행
                        onTokenRefreshed(aat);
                    }
                ).catch((error) => {
                    if (error.response.status === 401) {
                        // console.log("here 401");
                        // console.log(JSON.stringify({data:{},user:{}}));
                        let session = JSON.parse(sessionStorage.getItem("persist:root"));
                        let user_session = JSON.parse(session.user);
                        user_session = {};
                        session.user = JSON.stringify(user_session);
                        sessionStorage.setItem("persist:root", JSON.stringify(session));
                        alert(reloginAlertMessage);
                        let urlarr = window.location.href.split("/");
                        window.location.href = urlarr[0] + "/login";
                    }
                });
            }

            // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
            const retryOriginalRequest = new Promise((resolve) => {
                // console.log(originalRequest);
                addRefreshSubscriber((accessToken) => {
                    originalRequest.headers.Authorization = "Bearer " + accessToken;
                    resolve(axios(originalRequest));
                });
            });
            return retryOriginalRequest;
        }
        return Promise.reject(error);
    }
);


const refresh_api = axios.create({
    baseURL: `${SERVER_ADDRESS}`,
    timeout: 5000,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + get_refresh_tokn()
    },
});

const reqAPI = async (req) => {
    const response = await req.then(function (res) {
        if (res.data.result === ResponseResult.OK) {
        } else if (res.data.result === ResponseResult.FAIL) {
            // console.log("실패");
        } else {
            console.error("ResponseResult 확인 필요");
        }
        return res.data;
    }).catch(function (error) {
        // console.log(error);
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
            // Node.js의 http.ClientRequest 인스턴스입니다.
            console.error(error.request);
        } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.error('Error', error.message);
        }
        // console.error(error.config);
    });

    return response;
}

export const apiGet = (url) => {
    return reqAPI(api.get(url));
}

export const apiGetParams = (url, reqData) => {
    url = url + "?";

    let data = [];

    Object.keys(reqData).map(key =>
        (data.push(key + "=" + reqData[key]))
    );

    url = url + data.join("&");

    return apiGet(url);
}

export const apiPost = async (url, reqData) => {
    return await reqAPI(api.post(url, reqData));
}

export const privateApiPost = (url, reqData) => {
    return reqAPI(private_api.post(url, reqData));
}

export const privateApiGet = (url) => {
    return reqAPI(private_api.get(url));
}

export const privateApiDelete = (url) => {
    return reqAPI(private_api.delete(url));
}

export const privateApiGetParams = (url, reqData) => {
    url = url + "?";

    let data = [];

    Object.keys(reqData).map(key =>
        (data.push(key + "=" + reqData[key]))
    );

    url = url + data.join("&");

    return reqAPI(private_api.get(url));
}

const tokenRefresh = () => {
    // return reqAPI(refresh_api.get("/auth/refreshToken"));
}