/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Card } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer2';
import NavBar from '../../components/NavBar/NavBar';
import { blue, button_text, stroke_gray1 } from '../../styles/colors';
import { BtnFullBlue2, TextStyle8 } from '../../styles/themes';

function QRpayResultPage() {
    return (
        <React.Fragment>
            <NavBar/>
            <div css={QRpayWrap}>
                <Card>
                    <Card.Body>
                        <h1 className="text-center" css={css`padding: 5%;`}>비회원 QR 결제</h1>
                        <div className="text-center" css={css`padding: 0% 5%; margin-bottom: 5%;`}>
                            <div className="mt-1" css={TextStyle8}>QR 인증이 완료되었습니다.</div>
                            <div className="mt-1" css={TextStyle8}><span css={css`color: ${blue}; font-weight: 600`}>충전기의 안내</span>에 따라</div>
                            <div className="mt-1" css={TextStyle8}>충전을 시작해주세요.</div>
                        </div>
                        
                        <div className="box">
                            <div className="mt-1" css={[TextStyle8, css`color: ${blue}; font-weight: 500`]}>SeoulEV 회원으로 가입하세요</div> 
                            <br/>
                            <div className="mt-1" css={TextStyle8}><span css={css`color: ${blue}; font-weight: 500`}>훨씬 저렴한 요금</span>으로</div>
                            <div className="mt-1" css={TextStyle8}>이용 하실 수 있습니다</div> 

                            <div className="mt-3" css={css`width: 150px; height: 44px; margin: 0 auto;`}>
                                <button css={BtnFullBlue2} onClick={() => window.location.href=process.env.REACT_APP_URL+"register"}>
                                    회원가입
                                </button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer/>
            </div>
        </React.Fragment>
    );
}

const QRpayWrap = css`
    padding: 3% 15%;
    min-height: 500px;

    input[type="checkbox"] {
        display: none;
    }

    .box {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 5%;
        text-align: center;
        padding: 2%;
        border: 1px solid ${stroke_gray1};
    }

    .card {
        border: none;

        h1 {
            padding-bottom: 32px;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
        }

        .card-body {
            padding: 0;

            .form_row {
                display: flex;
                padding-bottom: 24px;

            .input_div {
                    width: 100%;
                    height: 48px;
            }

            .label_div {
                font-weight: 400;
                font-size: 16px;
                line-height: 225.3%;
                color: ${button_text};

                min-width: 115px;
            }

            .text_div {
            width: 100%;
            }

            textarea {
            border-radius: 10px;
            }

            input[type="text"] {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
    }
`;

export default QRpayResultPage