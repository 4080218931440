/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Card, Form, TabContent } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ResponseResult } from '../../apis/response';
import Footer from '../../components/Footer/Footer2';
import ModalAlert from '../../components/Modal/ModalAlert';
import NavBar from '../../components/NavBar/NavBar';
import Tab from '../../components/Tab/Tab';
import { mypage_tab } from '../../configs/base';
import { getUserPaycard, updatePaycard } from '../../stores/actions/user_action';
import { mypage_subtitle_blue } from '../../styles/colors';
import { BtnOutLineGray2, MyPageWrap } from '../../styles/themes';
import { ediDateNoFormat, makeEncryptData, makeMoid, todayNoFoarmat, TodayFormatYYYYMMDDHHmmssSSS } from '../../utils/utils';
import { notFoundUserPaycard, paycardUpdateFailAlertMessage } from '../../validations/messages/messages';

function PaycardPage() {
    const dispatch = useDispatch();
    const user = useState(useSelector(state => state.user));
    const { state, search } = useLocation();
    const [PaycardResult, setPaycardResult] = useState(null);
    const smartropay = window.smartropay;
    const [Loaded, setLoaded] = useState(false);
    const [Moid, setMoid] = useState("");
    const [EdiDate, setEdiDate] = useState("");
    const [EncryptData, setEncryptData] = useState("");
    const [Paycard, setPaycard] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [ResMsg, setResMsg] = useState();
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://pay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=" + todayNoFoarmat();
        script.async = false;
        script.addEventListener('load', () => setLoaded(true));
        document.body.appendChild(script);

        dispatch(getUserPaycard(user[0].id)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    setPaycard(response.payload.data);
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }, []);

    useEffect(() => {
        if (PaycardResult !== null) {
            if (PaycardResult.ResultCode === "3001") {
                // 성공 코드
                window.history.replaceState({}, null, window.location.pathname);

                dispatch(getUserPaycard(user[0].id)).then(
                    response => {
                        if (response.payload.result === ResponseResult.OK) {
                            let reqParams = {
                                "id": parseInt(response.payload.data.id),
                                "paycardCo": PaycardResult.IssuerCardNm,
                                "paycardNo": PaycardResult.DisplayCardNo,
                                "billingkey": PaycardResult.BillTokenKey,
                                "tid": PaycardResult.Tid,
                                "mallUserId": PaycardResult.MallUserId
                            }

                            dispatch(updatePaycard(reqParams)).then(
                                response => {
                                    setPaycardResult(null);
                                    setModalShow(true);
                                    setResMsg(response.payload.msg);

                                }
                            )
                        } else {
                            alert(response.payload.msg);
                        }
                    }
                )
            } else {
                window.history.replaceState({}, null, window.location.pathname);
                if (PaycardResult.ResultMsg) {
                    setResMsg(paycardUpdateFailAlertMessage + " (" + PaycardResult.ResultMsg + ")");
                    setModalShow(true);
                }
            }
        }
    }, [PaycardResult]);

    useEffect(() => {
        if (search !== "") {
            let smartro_res = searchParams.get("result");

            // console.log((encodeURIComponent(JSON.parse(searchParams.get("result"))['BillTokenKey'])).toString().replaceAll("%3D", '=').replaceAll("%2B", '+').replaceAll("%2F", '/').replaceAll("%20", '+'));
            if (smartro_res) {
                let json_smartro_res = JSON.parse(smartro_res);
                json_smartro_res['BillTokenKey'] = (encodeURIComponent(JSON.parse(searchParams.get("result"))['BillTokenKey'])).toString().replaceAll("%3D", '=').replaceAll("%2B", '+').replaceAll("%2F", '/').replaceAll("%20", '+');
                // console.log(json_smartro_res);
                // console.log(JSON.parse(smartro_res));
                setPaycardResult(json_smartro_res);
            }
        }
    }, [state]);


    useEffect(() => {
        if (!Loaded) {
            return;
        } else {
            const moid = makeMoid();
            const ediDate = ediDateNoFormat();

            setMoid(moid);
            setEdiDate(ediDate);
            setEncryptData(makeEncryptData(ediDate, moid));
        }
    }, [Loaded])

    const requstPay = () => {
        smartropay.init({
            mode: process.env.REACT_APP_SMARTROPAY_MODE,
            actionUri: '/ssb/interface.do'
        });

        smartropay.payment({
            FormId: 'paycardForm'
        });
    }

    const onClickChangePaycardHandler = () => {

        // 결제카드 변경
        requstPay();
    }

    return (
        <React.Fragment>
            <NavBar />
            <div css={MyPageWrap}>
                <Card>
                    <Card.Body>
                        <h1>마이페이지</h1>
                        <Tab list={mypage_tab} active={4} userId={1}></Tab>
                        <TabContent>
                            <div className="paycard">
                                <table css={{ 'width': '100%' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={3} css={css`color: ${mypage_subtitle_blue}; font-weight: 700; font-size: 13px; line-height: 19px;`}>결제카드</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                Object.keys(Paycard).length > 0
                                                    ? <React.Fragment>
                                                        <td css={css`width: 30%;`}>
                                                            {Paycard.paycardCo}
                                                        </td>
                                                        <td css={css`width: 50%;`}>
                                                            {Paycard.paycardNo}
                                                        </td>
                                                        <td>
                                                            <div css={css`width: 110px; height:28px; float: right`}>
                                                                <button type="submit" css={BtnOutLineGray2} onClick={onClickChangePaycardHandler}>결제카드 변경</button>
                                                            </div>
                                                        </td>
                                                    </React.Fragment>
                                                    : <React.Fragment>
                                                        <td colSpan={2}>
                                                            {notFoundUserPaycard}
                                                        </td>
                                                    </React.Fragment>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </TabContent>
                    </Card.Body>
                </Card>

                <Form id="paycardForm" name="paycardForm" method="post">
                    <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
                    <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                    <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                    <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
                    <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                    <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                    <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + "/users/changePaycard/result"} />
                    <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                    <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                    <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                    <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                    <input type="hidden" name="IsPwdPass" maxLength={1} value="Y" placeholder="" />
                </Form>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
            <ModalAlert
                show={modalShow}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
};

export default PaycardPage;