
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { black2, blue } from '../../styles/colors';
import MARKER_PIN from '../../assets/images/marker-pin-06.svg';
import INFORMATION_CIRCLE from '../../assets/images/Information circle.svg';
import INTERFACE_SETTING from '../../assets/images/Interface Setting Wrench by Streamlinehq.svg';
import EXTERNAL_LINK from '../../assets/images/External link.svg';
import { TextStyle2 } from '../../styles/themes';
import { useNavigate } from 'react-router-dom';
import { DESKTOP } from '../../styles/media-queries';

const QuickMenu = () => {
    const navigate = useNavigate();
    const path = window.location.pathname;
    const [isOtherHover, setIsOtherHover] = useState(false);

    return (
        <React.Fragment>
            <div css={[QuickMenuWrap, DESKTOP]}>
                <ul css={TextStyle2}>
                    <li css={isOtherHover
                        ? isOtherHoverStype
                        : css` cursor: pointer; -moz-border-radius: 7px 7px 0px 0px; -webkit-border-radius: 7px 7px 0px 0px; border:1px solid ${blue}; background-color: ${blue};`}
                        id='only_top' onClick={() => path === "/search_stations" ? window.location.href = process.env.REACT_APP_URL + "search_stations" : navigate("/search_stations")}>
                        <img src={MARKER_PIN} alt="marker_pin" style={{ marginBottom: "9px" }} />
                        <span>충전소 찾기</span>
                    </li>
                    <li id='default_1' onClick={() => path === "/support/service_guide" ? window.location.href = process.env.REACT_APP_URL + "support/service_guide" : navigate("/support/service_guide")}
                        onMouseEnter={() => setIsOtherHover(true)} onMouseLeave={() => setIsOtherHover(false)}>
                        <img src={INFORMATION_CIRCLE} alt="information_circle" style={{ marginBottom: "9px" }} />
                        <span>이용 안내</span>
                    </li>
                    <li id='default_2' onClick={() => path === "/reception_guide" ? window.location.href = process.env.REACT_APP_URL + "reception_guide" : navigate("/reception_guide")}
                        onMouseEnter={() => setIsOtherHover(true)} onMouseLeave={() => setIsOtherHover(false)}>
                        <img src={INTERFACE_SETTING} alt="information_circle" style={{ marginBottom: "9px" }} />
                        <span>설치안내</span>
                    </li>
                    <li id='only_bottom' onClick={() => window.open("https://blog.naver.com/seoulevblog", "_black")}
                        onMouseEnter={() => setIsOtherHover(true)} onMouseLeave={() => setIsOtherHover(false)}>
                        <img src={EXTERNAL_LINK} alt="information_circle" style={{ marginBottom: "9px" }} />
                        <span>블로그</span>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

const isOtherHoverStype = css`
-moz-border-radius: 7px 7px 0px 0px;
        -webkit-border-radius: 7px 7px 0px 0px;
        border:1px solid ${black2};
        background-color: ${black2};
        !important`;
const QuickMenuWrap = css`
    position: fixed;
    width:80px;
    transform: translate(-50%, -50%);
    z-index: 100;
    top:50%;
    right:-20px;

    ul {
        list-style: none;
        position:relative;
        width:100%;
        padding:0;
        margin:0;

        -moz-border-radius: 7px;
        -webkit-border-radius: 7px;
        // background: ${blue};
        
        background: ${black2};
    }

    li {
        width:80px;
        height:80px;
        text-align:center;
        padding: 7px 0 7px 0;
        color: white;

        display: flex;              
        flex-direction: column;     
        justify-content: center;    
        align-items: center;   

    }
   
    
    
    li[id='only_bottom']:hover{
        cursor: pointer;
        -moz-border-radius:  0px 0px 7px 7px;
        -webkit-border-radius: 0px 0px 7px 7px;
        border:1px solid ${blue};
        background-color: ${blue};
    } 
   

    li[id^='default_']:hover{
        cursor: pointer;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        border:1px solid ${blue};
        background-color: ${blue};
    }
   

    a {
        color: white;
        text-decoration:none;
        font-size: 14px;
        font-weight: 600;
    }
`;

export default QuickMenu;