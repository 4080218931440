import * as yup from "yup";
import * as messages from "../messages/messages";

export const authReqValidation = yup.object({
    LgnId: yup
        .string()
        .required(messages.requiredMessage),
    Phone: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^[0-9]{9,11}$/, messages.onlyNumberPhoneMessage)
}).required();

export const authCodeValidation = yup.object({
    AuthCode: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^.{6}$/, "6자리로 입력해주세요."),
}).required();

export const changePasswordValidation = yup.object({
    Password: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, messages.passwordChangeRegexAlertMessage),
    PasswordConfirm: yup
        .string()
        .required(messages.requiredMessage)
        .oneOf([yup.ref('Password'), null], messages.confirmPassword),
}).required();