import { privateApiGetParams } from '../../apis/api';
import { GET_CHARGING_LIST, GET_CHARGING_LIST_EXPORT } from './types';

export const getChargingList = (data) => {
    // 회원 충전이용 내역 조회
    const request = privateApiGetParams("/users/getUseHistoryList", data).then(response => response);

    return {
        type: GET_CHARGING_LIST,
        payload: request
    }
}

export const getChargingListExport = (data) => {
    // 회원 충전이용 내역 Excel 다운로드
    const request = privateApiGetParams("/users/getUseHistoryList/export", data).then(response => response);

    return {
        type: GET_CHARGING_LIST_EXPORT,
        payload: request
    }
}