/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LOGO from '../../assets/images/logo_white_blue.svg';
import LOGO_BLACK from '../../assets/images/logo_black.svg';
import LOGO_MOBILE from '../../assets/images/logo_white_blue.svg';
import LOGO_MOBILE_BLACK from '../../assets/images/logo_black.svg';
import USER_ADD from '../../assets/images/User add.png';
import USER from '../../assets/images/User.png';
import USER_BLACK from '../../assets/images/User_black.png';
import LOGOUT from '../../assets/images/Logout.png';
import X_ICON from '../../assets/images/X.png';
import MOBILE_MENU from '../../assets/images/mobile_menu.png';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { logoutUser } from '../../stores/actions/user_action';
import { useDispatch } from 'react-redux';
import { DESKTOP, MOBILE, tb_point } from '../../styles/media-queries';
import { black2, white } from '../../styles/colors';
import { BtnFullDark, BtnFullDarkBlueMenu, BtnFullWhite, TextStyle1, TextStyle3, TextStyle4, TextStyle5 } from '../../styles/themes';
import { useRef } from 'react';

const NavBar = ({isNavBtnVisible = true}) => {
    const user = useState(useSelector(state => state.user));
    const dispatch = useDispatch();
    const path = window.location.pathname;
    const navigate = useNavigate();
    const sideBar = useRef();

    const onClickLogoutHandler = () => {
        dispatch(logoutUser());
    }

    const onClickSideBarCloseHandler = () => {
        sideBar.current.className = "sidebar sidebar_hidden";
    }

    const onClickSideBarOpenHandler = () => {
        sideBar.current.className = "sidebar";
    }

    return (
        <>
            <div css={DESKTOP}>
                <div css={path === "/" || path === "/introduce" ? HeaderWrap : BlockHeaderWrap}>
                    <a href="/">
                        <img src={path === "/" || path === "/introduce" ? LOGO : LOGO_BLACK} alt="logo" className="logo" style={{ width: 216, height: 24 }} />
                    </a>
                    <div css={[path === "/" || path === "/introduce" ? MenuWrap : BlockMenuWrap, TextStyle4]}>
                        <ul>
                            <li onClick={() => path === "/introduce" ? window.location.href = process.env.REACT_APP_URL + "introduce" : navigate("/introduce")}><span>SEOULEV 스토리</span></li>
                            <li onClick={() => path === "/search_stations" ? window.location.href = process.env.REACT_APP_URL + "search_stations" : navigate("/search_stations")}>충전소 찾기</li>
                            <li onClick={() => path === "/reception_guide" ? window.location.href = process.env.REACT_APP_URL + "reception_guide" : navigate("/reception_guide")}>설치안내</li>
                            <li onClick={() => path === "/support/notice" ? window.location.href = process.env.REACT_APP_URL + "support/notice?currentPage=1" : navigate("/support/notice?currentPage=1")}>고객센터</li>
                        </ul>
                    </div>
                    <div css={BtnListWrap}>
                        <div style={{ maxWidth: "150px", minWidth: "135px", height: "44px", marginRight: "10px" }}>
                            {
                                ("id" in user[0])
                                    ? <button onClick={() => navigate("/mypage/charging?currentPage=1")} type="button" css={[BtnFullWhite, TextStyle1]} style={{ display: "flex", alignItems: "end", justifyContent: "center" }}>
                                        <img src={USER_BLACK} alt="user_black" style={{ height: "20px", paddingRight: "11px" }} />
                                        <span>마이페이지</span>
                                    </button>

                                    : <button onClick={() => navigate("/register")} type="button" css={[BtnFullWhite, TextStyle1]} style={{ display: "flex", alignItems: "end", justifyContent: "center" }}>
                                        <img src={USER_ADD} alt="user_add" style={{ height: "20px", paddingRight: "11px" }} />
                                        <span>신규회원 가입</span>
                                    </button>
                            }
                        </div>
                        <div style={{ width: "62px", height: "44px" }}>
                            {
                                ("id" in user[0])
                                    ? <button onClick={onClickLogoutHandler} type="button" css={[BtnFullDark, TextStyle5]}>
                                        <img src={LOGOUT} alt="logout" style={{ display: "block", margin: "0 auto", paddingBottom: "3px" }} />
                                        <span>로그아웃</span>
                                    </button>
                                    : <button onClick={() => navigate("/login")} type="button" css={[BtnFullDark, TextStyle5]}>
                                        <img src={USER} alt="user_add" style={{ display: "block", margin: "0 auto", paddingBottom: "3px" }} />
                                        <span>로그인</span>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div css={[MOBILE, css`width: 100%;`]}>
                <div css={path === "/" || path === "/introduce" ? HeaderWrap : BlockHeaderWrap}>
                    <a href="/" style={{ position: "absolute", zIndex: "10" }}>
                        <img src={path === "/" || path === "introduce" ? LOGO_MOBILE : LOGO_MOBILE_BLACK} alt="logo_mobile" style={{ width: 140, height: 16 }} />
                    </a>
                    {
                        isNavBtnVisible &&
                        <div css={path === "/" || path === "/introduce" || path.includes("/QRpay") ? MobileMenuButtonWrap : TbMenuButtonWrap} onClick={onClickSideBarOpenHandler}>
                            <button css={BtnFullDarkBlueMenu}>
                                <img src={MOBILE_MENU} alt="mobile_menu" />
                            </button>
                        </div>
                    }
                    <div className="sidebar sidebar_hidden" ref={sideBar}>
                        <div css={SideBarHeaderWrap}>
                            <Link to="/">
                                <img src={LOGO_MOBILE} alt="logo_mobile" />
                            </Link>
                            <img src={X_ICON} alt="x_icon" onClick={onClickSideBarCloseHandler} />
                        </div>

                        <div css={SideBarContentWrap}>
                            <ul css={TextStyle3}>
                                <li onClick={() => path === "/introduce" ? window.location.href = process.env.REACT_APP_URL + "introduce" : navigate("/introduce")}><span>SEOULEV 스토리</span></li>
                                <li onClick={() => path === "/search_stations" ? window.location.href = process.env.REACT_APP_URL + "search_stations" : navigate("/search_stations")}>충전소 찾기</li>
                                <li onClick={() => path === "/reception_guide" ? window.location.href = process.env.REACT_APP_URL + "reception_guide" : navigate("/reception_guide")}>설치안내</li>
                                <li onClick={() => path === "/support/notice" ? window.location.href = process.env.REACT_APP_URL + "support/notice?currentPage=1" : navigate("/support/notice?currentPage=1")}>고객센터</li>
                            </ul>
                        </div>

                        <div css={SideBarFooterWrap}>
                            <div style={{ height: "44px" }}>
                                {
                                    ("id" in user[0])
                                        ? <button onClick={onClickLogoutHandler} type="button" css={[BtnFullDark, TextStyle1]} style={{ display: "flex", alignItems: "center" }}>
                                            <img src={LOGOUT} alt="logout" />
                                            <span css={css`margin: 0 auto;`}>로그아웃</span>
                                        </button>
                                        : <button onClick={() => navigate("/login")} type="button" css={[BtnFullDark, TextStyle1]} style={{ display: "flex", alignItems: "center" }}>
                                            <img src={USER} alt="user" />
                                            <span css={css`margin: 0 auto;`}>회원 로그인</span>
                                        </button>
                                }
                            </div>
                            <div style={{ height: "44px", marginTop: "15px" }}>
                                {
                                    ("id" in user[0])
                                        ? <button onClick={() => navigate("/mypage/charging?currentPage=1")} type="button" css={[BtnFullWhite, TextStyle1]} style={{ display: "flex", alignItems: "center" }}>
                                            <img src={USER_BLACK} alt="user_black" />
                                            <span css={css`margin: 0 auto;`}>마이페이지</span>
                                        </button>

                                        : <button onClick={() => navigate("/register")} type="button" css={[BtnFullWhite, TextStyle1]} style={{ display: "flex", alignItems: "center" }}>
                                            <img src={USER_ADD} alt="user_add" />
                                            <span css={css`margin: 0 auto;`}>신규회원 가입</span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const MobileMenuButtonWrap = css`
    position: absolute;
    right: 0;
    height: 60px;
    width: 60px;
    z-index:10;
`;

const TbMenuButtonWrap = css`
    // position: absolute;
    // left: ${tb_point};

    position:fixed;
    right:0px;
    height: 60px;
    width: 60px;
    z-index:10;
`;

const HeaderWrap = css`
    position: absolute;
    padding: 30px 60px 0 60px;
    display: flex;
    z-index: 10;
    justify-content:space-between;
    width: 100%;
    align-items: center;
    // background: ${white};
`;

const BlockHeaderWrap = css`
    width: 100%;
    // min-width: 711px;
    // min-width: 655px;
    padding: 30px 60px 30px 60px;
    display: flex;
    z-index: 10;
    justify-content:space-between;
    align-items: center;
    background: ${white};
`;

const SideBarHeaderWrap = css`
    display: flex;
    padding: 22px 18px 0 30px;
    justify-content: space-between;
    align-items: end;

    img {
        cursor: pointer;
    }
`;

const SideBarContentWrap = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;

    li {
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
        }
    }
`;

const SideBarFooterWrap = css`
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
    width: 70%;
`;

const MenuWrap = css`
    padding: 0 15px 0 15px;
    color: ${white};

    ul {
        list-style: none;
        display: flex;
        width: 500px;
        justify-content: space-between;
    }

    li {
        padding: 0.2em 0;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 0.1em;
            bottom: 0;
            left: 0;
            background-color: ${white};
            transform-origin: bottom right;
            transition: transform 0.20s ease-out;
           
            // content: '';
            // position: absolute;
            // bottom: 0;
            // left: 0;
            // width: 100%;
            // height: 0.1em;
            // background-color: ${white};
            // opacity: 0;
            // transition: width 300ms, opacity 300ms, transform 300ms;
        }

        &:hover:after,&:hover {
            cursor: pointer;
            transform: scaleX(1);
            transform-origin: bottom left;
        }

        // &:hover:after,&:hover {
        //     cursor: pointer;

        //     opacity: 1;
        //     transform: translate3d(0, 0, 0);
        // }
    }
`;

const BlockMenuWrap = css`
    padding: 0 15px 0 15px;
    color: ${black2};

    ul {
        list-style: none;
        display: flex;
        width: 500px;
        justify-content: space-between;
    }

    li {
        padding: 0.2em 0;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 0.1em;
            bottom: 0;
            left: 0;
            background-color: ${black2};
            transform-origin: bottom right;
            transition: transform 0.20s ease-out;
        }

        &:hover:after,&:hover {
            cursor: pointer;
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
`;


const BtnListWrap = css`
    display: flex;
`;

export default NavBar;