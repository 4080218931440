/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from "react";
import { Alert, AlertClose, AlertTheme, hide } from "../../styles/themes";
import ALERT_ICON_WARNNING from"../../assets/images/alert_icon1.png";
import ALERT_ICON_INFO from"../../assets/images/alert_icon2.png";
import ALERT_ICON_ERROR from"../../assets/images/alert_icon3.png";
import ALERT_CLOSE_ICON_WARNNING from"../../assets/images/alert_close_icon1.png";
import ALERT_CLOSE_ICON_INFO from"../../assets/images/alert_close_icon2.png";
import ALERT_CLOSE_ICON_ERROR from"../../assets/images/alert_close_icon3.png";
import { button_text } from '../../styles/colors';

function CustomAlert({type, message, alertHandler}) {
    const [isShow, setIsShow] = useState(true);
  
    const handleClose = (e) => {
      e.preventDefault();
      alertHandler();
      setIsShow(false);
    };
    
  return (
    <div css={[AlertTheme, Alert[type], !isShow && hide]}>
      <div css={alertWrap}>
        {
          {
            info : 
              <React.Fragment>
                <img src={ALERT_ICON_INFO} alt="alert_icon_info" css={alertIconWrap}/>
                <div css={css`font-weight: 700; font-size: 16px; line-height: 24px; margin-right: 15px; color: ${button_text}`}>알림</div>
                <div css={css`font-weight: 400; font-size: 16px; line-height: 24px; color: ${button_text}; max-width: 215px; text-align: left;`}>{message}</div>
                <div css={css`margin-left: auto;`}>
                  <img src={ALERT_CLOSE_ICON_INFO} alt="alert_close_icon_info" css={css`&:hover{cursor:pointer}`} onClick={handleClose}/>
                </div>
              </React.Fragment>,
            warnning: 
              <React.Fragment>
                <img src={ALERT_ICON_WARNNING} alt="alert_icon_warnning" css={alertIconWrap}/>
                <div css={css`font-weight: 700; font-size: 16px; line-height: 24px; margin-right: 15px; color: ${button_text}`}>알림</div>
                <div css={css`font-weight: 400; font-size: 16px; line-height: 24px; color: ${button_text}; max-width: 215px; text-align: left;`}>{message}</div>
                <div css={css`margin-left: auto;`}>
                  <img src={ALERT_CLOSE_ICON_WARNNING} alt="alert_close_icon_warnning" css={css`&:hover{cursor:pointer}`} onClick={handleClose}/>
                </div>
              </React.Fragment>,
            error:
              <React.Fragment>
                <img src={ALERT_ICON_ERROR} alt="alert_icon_error" css={alertIconWrap}/>
                <div css={css`font-weight: 700; font-size: 16px; line-height: 24px; margin-right: 15px; color: ${button_text}`}>알림</div>
                <div css={css`font-weight: 400; font-size: 16px; line-height: 24px; color: ${button_text}; max-width: 215px; text-align: left;`}>{message}</div>
                <div css={css`margin-left: auto;`}>
                  <img src={ALERT_CLOSE_ICON_ERROR} alt="alert_close_icon_error" css={css`&:hover{cursor:pointer}`} onClick={handleClose}/>
                </div>
              </React.Fragment>, 
          }[type]
        }
      </div>
    </div>
  );
}

const alertWrap = css`
  display: flex;
  align-items: center;
`;

const alertIconWrap = css`
  margin-right: 16px;
`;

export default CustomAlert