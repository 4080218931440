/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Container, Form } from 'react-bootstrap';
import SEOULEV_LOGO from '../../assets/images/logo.png';
import { updatePasswordSchema } from '../../validations/schemas/UpdatePasswordSchema';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserPassword } from '../../stores/actions/user_action';
import ModalAlert from '../../components/Modal/ModalAlert';
import { ResponseResult } from '../../apis/response';
import { useNavigate } from 'react-router';

function PasswordUpdatePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [modalShow, setModalShow] = useState(false);
    const [IsReqOK, setIsReqOK] = useState(false);
    const [ResMsg, setResMsg] = useState();

    const [Writing, setWriting] = useState(true);
    const { handleSubmit, register, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(updatePasswordSchema),
        reValidateMode: 'onChange',
    });

    const onClickInfoHandler = () => {
        // 비밀번호 변경 성공시
        navigate("/mypage/info");
    }

    const onClickFailUpdateHandler = () => {
        // 비밀번호 변경 실패시
        setModalShow(false);
    }


    const test = () => {
        let info = Object.values(getValues());

        if (info.includes(undefined) || info.includes("")) {
            // console.log("빠진거 있음");
            // console.log(getValues());
            setWriting(true);
        } else {
            setWriting(false);
        }
    }

    const onSubmitHandler = (data) => {
        let reqParams = {
            "id": parseInt(user.id),
            "lgnPassword": data.Password,
            "changePassword": data.UpdatePassword,
        }

        dispatch(updateUserPassword(reqParams)).then(
            response => {
                // console.log(response);
                setModalShow(true);
                setResMsg(response.payload.msg);

                if (response.payload.result === ResponseResult.OK) {
                    setIsReqOK(true);
                }
            }
        )
    }

    return (
        <React.Fragment>
            <Container className="d-flex justify-content-center">
                <div css={wrapStyle}>
                    <div css={css`padding-bottom: 2rem; text-align: center`}>
                        <img css={css`width:30vh`} src={SEOULEV_LOGO} alt="seoulev_logo" />
                    </div>
                    <Card css={css`padding:5%`}>
                        <Card.Body>
                            <div css={css`padding-top: 2rem; padding-bottom: 2rem; text-align:center`}>
                                비밀번호 변경
                            </div>
                            <div>
                                <Form onSubmit={handleSubmit(onSubmitHandler)} onChange={test}>
                                    <div css={css`font-size: 25px; padding-bottom: 2rem; text-align:center`}>
                                        비밀번호를 재설정해주세요.
                                    </div>
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>기존 비밀번호 <span className="text-danger">*</span></Form.Label>
                                            <Form.Control {...register("Password")} type="password" placeholder="기존 비밀번호 입력" autoComplete="off" isInvalid={!!errors.Password?.message} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.Password?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>비밀번호 재설정 <span className="text-danger">*</span></Form.Label>
                                            <Form.Control {...register("UpdatePassword")} type="password" placeholder="비밀번호 입력" autoComplete="off" isInvalid={!!errors.UpdatePassword?.message} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.UpdatePassword?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control {...register("UpdatePasswordConfirm")} type="password" placeholder="비밀번호 재입력" autoComplete="off" isInvalid={!!errors.UpdatePasswordConfirm?.message} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.UpdatePasswordConfirm?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Text className="text-muted">
                                            <p>* 비밀번호는 8~16자리의 영문 대소문자, 숫자, 특수문자를 조합하여 설정해주세요.</p>
                                        </Form.Text>
                                        <div css={css`text-align:center;`} className="mt-3">
                                            <Button disabled={Writing} variant="outline-dark" type="submit">변경하기</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>

            <ModalAlert
                show={modalShow}
                onClickHandler={IsReqOK ? onClickInfoHandler : onClickFailUpdateHandler}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
};

const wrapStyle = css`
    width: 60%;;
`

export default PasswordUpdatePage;