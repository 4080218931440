/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from 'react-router';
import CLOSE_ICON from '../../assets/images/modal_close_icon.png';
import { button_text } from '../../styles/colors';
import { BtnOutLineBlue } from '../../styles/themes';

export default function ModalChangePassword(props) {
    const navigate = useNavigate();

    const onClickLoginPageHandler = () => {
        navigate("/login");
    }

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
        >
        <div>
            <img src={CLOSE_ICON} onClick={props.onHide} alt="close_icon" css={css`margin:32px 32px 38px 0; float:right; &:hover{cursor:pointer}`}/>
        </div>
        <Modal.Body className="container1 text-center"  css={css`padding: 0 53px 73px 53px`}>
            <Row css={css`font-size: 16px; line-height: 24px; color: ${button_text};  margin-bottom: 52px;`}>
                <h5>비밀번호가 변경이 완료되었습니다. <br/>
                새로운 비밀번호로 로그인해주세요.</h5>
            </Row>
            <Row css={css`width: 100%; height: 48px; margin: 0`}>
                <button type="button" css={BtnOutLineBlue} onClick={onClickLoginPageHandler}>로그인 화면으로</button>
            </Row>
        </Modal.Body>
      </Modal>
    );
};