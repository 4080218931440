import { apiGet, apiGetParams } from '../../apis/api';
import { GET_NOTICE, GET_NOTICE_LIST } from './types';

export const getNoticeList = (data) => {
    const request = apiGetParams("/notices", data).then(response => response);
   
    return {
        type: GET_NOTICE_LIST,
        payload: request
    }
}

export const getNotice = (id) => {
    const request = apiGet("/notices/"+id).then(response => response);
    
    return {
        type: GET_NOTICE,
        payload: request
    }
}