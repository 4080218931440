/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import MAIN_BANNER_IMAGE from '../../assets/images/main_image1.png';
import MAIN_BRAND_STORY_IMAGE from '../../assets/images/main_image2.png';
import MAIN_FIND_STATION_IMAGE from '../../assets/images/main_image3.png';
import SCROLL_DOWN from '../../assets/images/Chevron double down.png';
import SCROLL_UP from '../../assets/images/Chevron double up.png';
import ARROW from '../../assets/images/Arrow narrow right.png';

import PARTNER_TARDIS from '../../assets/images/partner_tardis.png';
import PARTNER_HUMAX from '../../assets/images/partner_humax.png';
import PARTNER_KAKAOMOBILITY from '../../assets/images/partner_kakaomobility.png';
import PARTNER_COSTEL from '../../assets/images/partner_costel.png';
import PARTNER_KEPCOMCS from '../../assets/images/partner_kepcomcs.png';
import PARTNER_SMARTRO from '../../assets/images/partner_smartro.png';
import PARTNER_KOOLSIGN from '../../assets/images/partner_koolsign.png';
import PARTNER_AUTOCRYPT from '../../assets/images/partner_autocrypt.png';
import PARTNER_KAEA from '../../assets/images/partner_kaea.png';
import BENEFIT_ICON1 from '../../assets/images/benefit_icon1.png';
import BENEFIT_ICON2 from '../../assets/images/benefit_icon2.png';
import BENEFIT_ICON3 from '../../assets/images/benefit_icon3.png';
import BENEFIT_ICON4 from '../../assets/images/benefit_icon4.png';
import BENEFIT_ICON5 from '../../assets/images/benefit_icon5.png';
import BENEFIT_ICON6 from '../../assets/images/benefit_icon6.png';
import APP from '../../assets/images/seoulev_app.svg';
import QR from '../../assets/images/qr_icon.png';
import GOOGLE_PLAY from '../../assets/images/google_play.png';
import APP_STORE from '../../assets/images/app_store.png';
import { black2, blue, partners, primary, secondary, white } from '../../styles/colors';
import { DESKTOP, MOBILE } from '../../styles/media-queries';
import { BtnFullWhiteBlue, BtnFullBlackBlue, ScrollWrap, TextStyle1 } from '../../styles/themes';
import Footer2 from '../../components/Footer/Footer2';
import { useNavigate } from 'react-router';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useState } from 'react';
import { useRef } from 'react';

function MainPage2() {
    const navigate = useNavigate();
    const [ScrollY, setScrollY] = useState(0);
    const [bodyOffset, setBodyOffset] = useState(
        document.body.getBoundingClientRect()
    );

    const [BenefitStyle, setBenefitStyle] = useState([MainBenefitsCard]);

    const benefitRef = useRef(null);

    const listener = e => {
        setScrollY(-bodyOffset.top);
        const benefit = document.getElementById("desk_benefit");
        const distanceFromViewport = benefit.getBoundingClientRect().top;

        if (distanceFromViewport <= window.screen.availHeight / 2) {
            if (BenefitStyle.length === 1) {
                setBenefitStyle([MainBenefitsCard, BenefitAnimation]);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listener);

        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, []);



    return (
        <React.Fragment>
            <NavBar />
            <div >
                <div css={MainBannerWrap}>
                    <div css={DESKTOP}>
                        <div css={MainBannerContent}>
                            <h1 style={{ marginTop: "3vh", marginBottom: "28px", fontFamily: 'Spoqa Han Sans Neo' }}>
                                Beyond Mobility <br />
                                & Connects with Energy
                            </h1>
                            <h3 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                전기차 충전, 모빌리티를 넘어 에너지와 연결한다
                            </h3>
                            <div css={BtnList} style={{ marginTop: "70px" }}>
                                <div style={{ height: "40px", width: "160px" }}>
                                    <button onClick={() => navigate("/reception")} type="button" css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>설치 신청하기</span>
                                    </button>
                                </div>
                                <div style={{ height: "40px", width: "160px", marginLeft: "10px" }}>
                                    <button onClick={() => navigate("/search_stations")} type="button" css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>충전소 찾기</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", fontSize: "22px", fontWeight: "500" }}>2024 환경부 완속 · 급속 충전시설 보조사업 수행기관 동시 선정</div>
                        </div>
                        <div css={ScrollWrap}>
                            <p>SCROLL</p>
                            <img src={SCROLL_DOWN} alt="scroll_icon" />
                        </div>
                    </div>
                    <div css={MOBILE}>
                        <div css={MainBannerMobileContent}>
                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                Beyond Mobility <br />
                                & Connects <br />
                                with Energy
                            </h1>
                            <h3 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                전기차 충전, <br />
                                모빌리티를 넘어 <br />
                                에너지와 연결한다
                            </h3>

                            <div css={MobileBtnList} style={{ marginTop: "6%" }}>
                                <div style={{ height: "40px", width: "160px" }}>
                                    <button onClick={() => navigate("/reception")} type="button" css={[BtnFullWhiteBlue, TextStyle1]} className="dark_button font-s">
                                        <img src={ARROW} alt="arrow" />
                                        <span>설치 신청하기</span>
                                    </button>
                                </div>
                                <div style={{ height: "40px", width: "160px", marginTop: "10px" }}>
                                    <button onClick={() => navigate("/search_stations")} type="button" css={[BtnFullWhiteBlue, TextStyle1]} className="white_button font-s">
                                        <img src={ARROW} alt="arrow" />
                                        <span>충전소 찾기</span>
                                    </button>
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", fontSize: "16px", fontWeight: "500", padding: "0 32px 0 32px", lineHeight: "1.5" }}>2024 환경부 완속 · 급속 충전시설<br /> 보조사업 수행기관 동시 선정</div>
                        </div>
                        <div css={ScrollWrap}>
                            <img src={SCROLL_DOWN} alt="scroll_icon" />
                        </div>
                    </div>
                </div>
                <div css={MainBrandStroyWrap}>
                    <div css={DESKTOP}>
                        <div css={MainBrandStoryContent}>
                            <h1 style={{ marginBottom: "10px", fontFamily: 'Spoqa Han Sans Neo' }}>
                                Brand Story
                            </h1>
                            <h3 style={{ marginBottom: "8%", fontFamily: 'Spoqa Han Sans Neo' }}>
                                브랜드스토리
                            </h3>
                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                SEOULEV는 서울도시가스그룹이 런칭한 <br />
                                전기차충전사업 브랜드입니다.
                            </h5>
                            <div css={BtnList}>
                                <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                    <button onClick={() => navigate("/introduce")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>더 보기</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div css={MOBILE}>
                        <div css={MainBrandStoryMobileContent}>
                            <h1 style={{ marginBottom: "10px", fontFamily: 'Spoqa Han Sans Neo' }}>
                                Brand Story
                            </h1>
                            <h3 style={{ marginBottom: "10px", fontFamily: 'Spoqa Han Sans Neo' }}>
                                브랜드스토리
                            </h3>
                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                SEOULEV는 <br />
                                서울도시가스그룹이 런칭한 <br />
                                전기차충전사업 브랜드입니다.
                            </h5>
                            <div css={MobileBtnList}>
                                <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                    <button onClick={() => navigate("/introduce")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>더 보기</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={DESKTOP}>
                    <div css={MainPartnersWrap}>
                        <div css={MainPartnersContent}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                        Partners
                                    </h1>
                                    <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                        주요 파트너사
                                    </h5>
                                </div>
                                <div className="logos">
                                    <div className="logo_div" style={{ marginBottom: "30px" }}>
                                        <div>
                                            <img src={PARTNER_TARDIS} alt="tardis" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_HUMAX} alt="humax" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KAKAOMOBILITY} alt="kakaoMobility" />
                                        </div>
                                    </div>
                                    <div className="logo_div" style={{ marginBottom: "30px" }}>
                                        <div>
                                            <img src={PARTNER_COSTEL} alt="costel" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KEPCOMCS} alt="kepcoMCS" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_SMARTRO} alt="smartro" />
                                        </div>
                                    </div>
                                    <div className="logo_div">
                                        <div>
                                            <img src={PARTNER_KOOLSIGN} alt="koolsign" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_AUTOCRYPT} alt="autocrypt" />
                                        </div>
                                        <div>
                                            <img src={PARTNER_KAEA} alt="KAEA" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={MOBILE}>
                    <div css={MainPartnersMobileWrap}>
                        <div css={MainPartnersMobileContent}>
                            <div>
                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                    Partners
                                </h1>
                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                    주요 파트너사
                                </h5>
                            </div>
                            <div className="logos">
                                <img src={PARTNER_TARDIS} alt="tardis" style={{ width: "140px" }} />
                                <img src={PARTNER_HUMAX} alt="humax" style={{ width: "140px" }} />
                                <img src={PARTNER_KAKAOMOBILITY} alt="kakaoMobility" style={{ width: "140px" }} />
                                <img src={PARTNER_COSTEL} alt="costel" style={{ width: "140px" }} />
                                <img src={PARTNER_KEPCOMCS} alt="kepcoMCS" style={{ width: "140px" }} />
                                <img src={PARTNER_SMARTRO} alt="smartro" style={{ width: "140px" }} />
                                <img src={PARTNER_KOOLSIGN} alt="koolsign" style={{ width: "140px" }} />
                                <img src={PARTNER_AUTOCRYPT} alt="autocrypt" style={{ width: "140px" }} />
                                <img src={PARTNER_KAEA} alt="KAEA" style={{ width: "140px" }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div css={MainBenefitsWrap} id="desk_benefit">

                    <div css={DESKTOP}>
                        <div css={MainBenefitsContent} style={{ display: "flex" }}>
                            <div style={{ width: "27%" }}>
                                <h1 style={{ marginTop: "26%", fontFamily: 'Spoqa Han Sans Neo' }}>
                                    Benefits
                                </h1>
                                <h3 style={{ marginBottom: "26%", fontFamily: 'Spoqa Han Sans Neo' }}>
                                    고객혜택
                                </h3>
                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                    SEOULEV는 맞춤컨설팅을 통해 <br />
                                    다양한 혜택을 제공합니다.
                                </h5>
                                <div>
                                    <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                        <button onClick={() => navigate("/reception")} css={[BtnFullBlackBlue, TextStyle1]}>
                                            <img src={ARROW} alt="arrow" />
                                            <span>설치 신청하기</span>
                                        </button>
                                    </div>
                                    <div style={{ height: "44px", width: "172px", marginTop: "13px" }}>
                                        <button onClick={() => navigate("/reception_guide")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                            <img src={ARROW} alt="arrow" />
                                            <span>자세히 보기</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%", marginLeft: "3%" }}>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-around", marginBottom: "3%" }}>
                                    <div css={BenefitStyle} className="content1">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON1} alt="benefit_icon1" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}><br />저렴한 충전요금</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                                저렴한 충전요금을 제공합니다.
                                            </h5>
                                        </div>
                                    </div>
                                    <div css={BenefitStyle} className="content2">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON2} alt="benefit_icon2" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}><br />제약 없는 설치</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>지상 주차장, 전기인입거리 등 <br />
                                                설치환경에 제한이 없습니다
                                            </h5>
                                        </div>
                                    </div>
                                    <div css={BenefitStyle} className="content3">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON3} alt="benefit_icon3" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}><br />회원 혜택</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>포인트 제도, 쿠폰 제공 등<br />
                                                고객 혜택을 제공합니다
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                                    <div css={BenefitStyle} className="content4">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON4} alt="benefit_icon4" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}><br />신속 대응</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>고장 접수 이후 24시간 이내 <br />
                                                출동을 원칙으로 합니다
                                            </h5>
                                        </div>
                                    </div>
                                    <div css={BenefitStyle} className="content5">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON5} alt="benefit_icon5" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>무상 설치지원<br />무상 유지보수</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>건물 자체의 비용 부담은 <br />
                                                일체 없습니다
                                            </h5>
                                        </div>
                                    </div>
                                    <div css={BenefitStyle} className="content6">
                                        <div className="logo_wrap">
                                            <img src={BENEFIT_ICON6} alt="benefit_icon6" />
                                        </div>
                                        <div className="content">
                                            <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}><br />안전한 충전기</h1>
                                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>사고 예방에 검증된<br />
                                                충전기만을 설치합니다
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div css={MOBILE} ref={benefitRef}>
                        <div css={MainBenefitsMobileContent}>
                            <h1 style={{ marginBottom: "10px", fontFamily: 'Spoqa Han Sans Neo' }}>
                                Benefits
                            </h1>
                            <h3 style={{ marginBottom: "8%", fontFamily: 'Spoqa Han Sans Neo' }}>
                                고객혜택
                            </h3>
                            <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                SEOULEV는 맞춤컨설팅을 통해 <br />
                                다양한 혜택을 제공합니다.
                            </h5>
                            <div css={MobileBtnList} style={{ marginBottom: "9%" }}>
                                <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                    <button onClick={() => navigate("/reception")} css={[BtnFullBlackBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>설치 신청하기</span>
                                    </button>
                                </div>
                                <div style={{ height: "44px", width: "172px", marginTop: "13px" }}>
                                    <button onClick={() => navigate("/reception_guide")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>자세히 보기</span>
                                    </button>
                                </div>
                            </div>
                            <div css={MainBenefitsSliderWrap}>
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={10}
                                    centeredSlides={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON1} alt="benefit_icon1" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>저렴한 충전요금</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>
                                                    저렴한 충전요금을 제공합니다.
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON2} alt="benefit_icon2" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>제약 없는 설치</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>지상 주차장, 전기인입거리 등 <br />
                                                    설치환경에 제한이 없습니다
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON3} alt="benefit_icon3" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>회원 혜택</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>포인트 제도, 쿠폰 제공 등<br />
                                                    고객 혜택을 제공합니다
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON4} alt="benefit_icon4" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>신속 대응</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>고장 접수 이후 24시간 이내 <br />
                                                    출동을 원칙으로 합니다
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON5} alt="benefit_icon5" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>무상 설치지원<br />무상 유지보수</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>건물 자체의 비용 부담은 <br />
                                                    일체 없습니다
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div css={MainBenefitsSliderCard}>
                                            <div className="logo_wrap">
                                                <img src={BENEFIT_ICON6} alt="benefit_icon6" />
                                            </div>
                                            <div className="content">
                                                <h1 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>안전한 충전기</h1>
                                                <h5 style={{ fontFamily: 'Spoqa Han Sans Neo' }}>사고 예방에 검증된<br />
                                                    충전기만을 설치합니다
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                {/* <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>Slide 1</SwiperSlide>
                                    <SwiperSlide>Slide 2</SwiperSlide>
                                    <SwiperSlide>Slide 3</SwiperSlide>
                                </Swiper> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div css={DESKTOP} style={{ position: "relative" }}>
                    <div css={MainFindStationWrap}>
                        <div css={MainFindStationContent}>
                            <div>
                                <h1 style={{ marginBottom: "10px", marginTop: "1.5vh" }}>
                                    Find Station
                                </h1>
                                <h3 style={{ marginBottom: "5.75vh" }}>
                                    충전소 찾기
                                </h3>
                                <div css={BtnList}>
                                    <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                        <button onClick={() => navigate("/search_stations")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                            <img src={ARROW} alt="arrow" />
                                            <span>검색하러 가기</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div css={MainFindStationCoverWrap}></div>
                </div>

                <div css={MOBILE} style={{ position: "relative" }}>
                    <div css={MainFindStationWrap}>
                        <div css={MainFindStationCoverMobileWrap}></div>
                        <div css={MainFindStationMobileContent}>
                            <h1 style={{ marginBottom: "10px" }}>
                                Find Station
                            </h1>
                            <h3 style={{ marginBottom: "8%" }}>
                                충전소 찾기
                            </h3>
                            <div css={MobileBtnList}>
                                <div style={{ height: "44px", width: "172px", marginTop: "48px" }}>
                                    <button onClick={() => navigate("/search_stations")} css={[BtnFullWhiteBlue, TextStyle1]}>
                                        <img src={ARROW} alt="arrow" />
                                        <span>검색하러 가기</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div css={MainDownloadWrap}>
                    <div css={DESKTOP}>
                        <div css={MainDownloadContent}>
                            <div css={css`width: 33.5%; padding-left:7.8vw;`}>
                                <h1>Download</h1>
                                <h3>다운로드</h3>
                                <h5>지금 SEOULEV 앱을 다운받고 <br />
                                    더 스마트하게 충전하세요!
                                </h5>
                            </div>
                            <div css={[MainDownloadAppImage,]}>
                                <img src={APP} alt="app" css={css` width: 16.66vw`} />
                            </div>
                            <div style={{ textAlign: "center" }} css={css`width:33.5%;padding-left:4.2vw;`}>
                                {/* <div>
                                    <img src={QR} alt="qr"/>
                                </div> */}
                                <div style={{ marginBottom: 20, marginTop: 39 }}>
                                    <a href="https://play.google.com/store/apps/details?id=kr.seoulev.SEOULEVapp" traget="_blank">
                                        <img src={GOOGLE_PLAY} alt="google_play" />
                                    </a>
                                </div>
                                <div style={{ marginBottom: 31 }}>
                                    <a href="https://apps.apple.com/kr/app/seoulev-%EC%84%9C%EC%9A%B8%EC%9D%B4%EB%B8%8C%EC%9D%B4/id6444150927" traget="_blank">
                                        <img src={APP_STORE} alt="app_store" />
                                    </a>
                                </div>
                                {/* <div css={MainDownLoadText}>
                                    12월 출시예정!
                                </div> */}
                            </div>
                        </div>
                        <div css={ScrollWrap}>
                            <p>TOP</p>
                            <img src={SCROLL_UP} alt="scroll_icon" />
                        </div>
                    </div>
                    <div css={MOBILE}>
                        <div css={MainDownloadMobileContent}>
                            <h1 style={{ marginBottom: "10px" }}>
                                Download
                            </h1>
                            <h3 style={{ marginBottom: "10px" }}>
                                다운로드
                            </h3>
                            <h5>
                                지금 SEOULEV 앱을 다운받고 <br />
                                더 스마트하게 충전하세요!
                            </h5>

                            <div>
                                <img className="app_image" src={APP} alt="app" />
                            </div>

                            <div>
                                <a href="https://play.google.com/store/apps/details?id=kr.seoulev.SEOULEVapp" traget="_blank">
                                    <img src={GOOGLE_PLAY} alt="google_play" style={{ marginBottom: 20 }} />
                                </a>
                                <a href="https://apps.apple.com/kr/app/seoulev-%EC%84%9C%EC%9A%B8%EC%9D%B4%EB%B8%8C%EC%9D%B4/id6444150927" traget="_blank">
                                    <img src={APP_STORE} alt="app_store" style={{ marginBottom: 20 }} />
                                </a>
                                {/* <div css={MainDownLoadText}>
                                    12월 출시예정!
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer2 />
            </div>
        </React.Fragment>
    );
}

const MainBannerWrap = css`
    background-image: url(${MAIN_BANNER_IMAGE});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: 16%;
    color: ${white};
    position: relative;
    width: 100%;
    height: 100vh;
`;

const MainBannerContent = css`
    position: absolute;
    width: 68%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    h1 {
        // font-weight: 700;
        // font-size: 5vw;
        // line-height: 1;
        // letter-spacing: -0.01em;

        font-weight: 700;
        font-size: 68px;
        line-height: 70px;
        letter-spacing: -0.01em;
    }

    h3 {
        // font-weight: 700;
        // font-size: 1.5vw;
        // line-height: 33px; 
        // letter-spacing: -0.03em;

        font-weight: 700;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: -0.03em;
    }
`;

const MainPartnersMobileContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    min-height: 670px;

    h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 34px;
        letter-spacing: -0.01em;
        color: ${blue};
        margin-bottom: 10px;
    }

    h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.03em;
        color: ${black2};
        margin-bottom: 60px;
    }

    .logos {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: 20px;
        }
    }
`;

const MainPartnersContent = css`
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
        @media screen and (min-width: 1500px) {
            font-size: 68px;
        }

        font-weight: 700;
        font-size: 3.5vw;
        line-height: 84px;
        letter-spacing: -0.01em;
        color: ${blue};
    }

    h5 {
        @media screen and (min-width: 1300px) {
            font-size: 26px;
        }

        font-weight: 700;
        // font-size: 26px;
        font-size: 1.8em;

        line-height: 33px;
        letter-spacing: -0.03em;
        color: ${black2};
    }

    .logos {
        width: 100%;
        .logo_div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            text-align:center;

            div {
                width: 256px;
                text-align: center;
            }
        }


        img {
            display: block;
            width: 100%;
            object-fit: contain;
            margin: 0 auto;
        }
    }
`;

const MainBenefitsSliderCard = css`
    // width: 10%;
    // max-width: 293px;

    width: 100%;

    height: 100%;
    min-width: 200px;
    min-height: 295px;
    background: ${white};
    text-align: center;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    color: ${black2};
    /* padding: 8% 3% 8% 3%; */
    padding: 60px 10px 60px 10px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 0px;
    }
    
    
    h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
      
        // font-weight: 700;
        // // font-size: 36px;
        // font-size: 2vw;
        // line-height: 45px;
        letter-spacing: -0.05em;
        margin-bottom: 19px;
    }

    h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.03em;
    }

    .content {
        margin-top: 10%;
    }

    img {
        width: 70%;
        display: block;
        margin:0 auto;
    }
`;

const BenefitAnimation = css`
    // :is(.content1, .content2, .content3, .content4, .content5, .content6) {
    //     animation: fadeInUp 2s ease;
    //     animation-fill-mode: forwards;
    // }

    :is(.content1) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
    }

    :is(.content2) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
    }

    :is(.content3) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
    }

    :is(.content4) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }

    :is(.content5) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }

    :is(.content6) {
        animation: fadeInUp 1s ease;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
        to {
            opacity: 1;
            transform: translateZ(0);
        }
    }
`;

const MainBenefitsCard = css`
    opacity: 0;
    width: 30%;
    background: ${white};
    text-align: center;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    color: ${black2};
    padding: 8% 3% 8% 3%;

    h1 {
        font-weight: 700;
        font-size: 1.85em;
        line-height: 45px;
        letter-spacing: -0.05em;
        margin-bottom: 10%;
    }

    h5 {
        font-weight: 700;
        font-size: 1em;
        line-height: 22px;
        letter-spacing: -0.03em;
    }

    .logo_wrap {
        height: 120px;
    }

    .content {
        margin-top: 20%;
    }

    img {
        width: 70%;
        display: block;
        margin:0 auto;
    }
`;

const MainBenefitsSliderWrap = css`
    height: 100%;
    margin-top: 5%;
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-wrapper{
        display:flex;
    }
    .swiper-slide {
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }
    .swiper-pagination-bullet{
        display: inline-block;
        border-radius: 50%;
        margin: 0 4px;
    }
`;

const MainBenefitsMobileContent = css`
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};
    text-align: center;
    overflow-x: hidden;

    h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 34px;
        letter-spacing: -0.01em;
    }

    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.03em;
    }

    h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.03em;
    }
`;

const MainBenefitsContent = css`
    margin-top:40px;
    
    position: absolute;
    width: 68%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};

    h1 {
        font-weight: 700;
        // font-size: 68px;
        font-size: 3.5vw;
        line-height: 84px;
        letter-spacing: -0.01em;
    }

    h3 {
        @media screen and (min-width: 1300px) {
            font-size: 26px;
        }

        font-weight: 700;
        font-size: 1.8em;
        // font-size: 26px;
        line-height: 33px;
        letter-spacing: -0.03em;
    }

    h5 {
        @media screen and (min-width: 1500px) {
            font-size: 20px;
        }

        font-weight: 500;
        font-size: 1.3vw;
        // font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.03em;
    }
`;

const MainFindStationContent = css`
    position: absolute;
    width: 68%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};    
    // display: flex;

    h1 {
        font-weight: 700;
        // font-size: 68px;
        font-size: 3.5vw;
        line-height: 84px;
        letter-spacing: -0.01em;
    }

    h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: -0.03em;
    }
`;

const MainBrandStoryContent = css`
    position: absolute;
    width: 68%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    h1 {
        font-weight: 700;
        font-size: 68px;
        // font-size: 5vw;
        line-height: 84px;
        letter-spacing: -0.01em;
    }

    h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: -0.03em;
    }

    h5 {
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.03em;
    }
`;

const MainDownloadMobileContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 34px;
        letter-spacing: -0.01em;
        color: ${blue};
    }

    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.03em;
        color: ${black2};

        padding-bottom: 40px;
    }

    h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: ${black2};
        
        padding-bottom: 33px;
    }

    .app_image {
        width: 50%;
        min-width: 114px;
        
        padding-bottom: 32px;
    }
`;

const MainDownLoadText = css`
    font-family: Spoqa Han Sans Neo;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: ${secondary};

`;

const MainDownLoadTextMobile = css`

    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: ${secondary};

`;

const MainDownloadAppImage = css`
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width:auto;
`;

const MainDownloadContent = css`
    // position: absolute;
    width: 69%;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100vw;

    h1 {
        @media screen and (min-width: 1460px) {
            font-size: 68px;
        }

        font-weight: 700;
        font-size: 3em;
        line-height: 84px;
        letter-spacing: -0.01em;
        color: ${blue};
        margin-bottom:1vh;
    }
    
    h3 {
        font-weight: 700;
        font-size: 1.6em;
        line-height: 33px;
        letter-spacing: -0.03em;
        color: ${black2};
    }

    h5 {
        margin-top: 5.6vh;
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.03em;
        color: ${black2};
    }
`;

const MainBrandStoryMobileContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;

    h1 {
        font-weight: 700;
        font-size: 6vw;
        line-height: 1.3;
        letter-spacing: -0.01em;

        @media (max-width: 550px) {
            font-size: 32px;
            line-height: 38px;
        };
    }

    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.03em;
    }

    h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.03em;
    }
`;

const MainBannerMobileContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;

    h1 {
        font-weight: 700;
        font-size: 6vw;
        line-height: 1.3;
        letter-spacing: -0.01em;

        @media (max-width: 550px) {
            font-size: 32px;
            line-height: 38px;
        };
    }

    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.03em;
    }
`;

const MainBrandStroyWrap = css`
    background-image: url(${MAIN_BRAND_STORY_IMAGE});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    color: ${white};
    width: 100%;
    height: 100vh;
    position: relative;
`;

const MainPartnersWrap = css`
    background-color: ${partners};
    position: relative;
    width: 100%;
    height: 60vh;
`;

const MainPartnersMobileWrap = css`
    background-color: ${partners};
    position: relative;
    width: 100%;
    height: auto;
    min-height: 950px;
`;

const MainBenefitsWrap = css`
    background-color: ${blue};
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 1400px;
    // min-height: 50vh;
`;


const MainDownloadWrap = css`
    background-color: ${white};
    position:relative;
    width: 100%;
    height: 100vh;
    @media (max-width:550px){

        min-height:624px;
    };
    @media (min-width:550px){
        min-height:920px;
    }
`;

const MainFindStationWrap = css`
    position: relative;
    background-color: ${primary};
    width: 100%;
    height: 100vh;
`;

const MainFindStationCoverWrap = css`
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    height: 100vh;
    background-image: url(${MAIN_FIND_STATION_IMAGE});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
`;

const MainFindStationCoverMobileWrap = css`
    position: absolute;
    top: 0;
    width: 100%;
    background-image: url(${MAIN_FIND_STATION_IMAGE});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    height: 50vh;
`;

const MainFindStationMobileContent = css`
    position: absolute;
    top: 85%;
    left: 50%;
    width:100%; 
    transform: translate(-50%, -85%);
    text-align: center;
    color: ${white};

    h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 34px;
        letter-spacing: -0.01em;
    }

    h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.03em;
    }
`;

const MobileBtnList = css`
    display: inline-block;
    // margin-top: 6%;

    button {
        display: flex;
        justify-content: center;
    }

    img {
        visibility: hidden;
        width: 0;
    }
`;

const BtnList = css`
    display: flex;
    // justify-content: center;
`;


export default MainPage2


