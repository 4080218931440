/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Card, TabContent } from "react-bootstrap";
import NavBar from '../../components/NavBar/NavBar';
import Tab from "../../components/Tab/Tab";
import { support_tab } from '../../configs/base';
import { background_green, black2, blue, primary, secondary, white, button_text } from '../../styles/colors';
import SERVICE_GUIDE_IMG from '../../assets/images/service_guide_img.png';
import MEMBERCARD from '../../assets/images/membercard.svg';
import STORY_FRAME from '../../assets/images/story_frame_white.png';
import SERVICE_GUIDE_ICON1 from '../../assets/images/service_guide_icon1.png';
import SERVICE_GUIDE_ICON2 from '../../assets/images/service_guide_icon2.png';
import SERVICE_GUIDE_ICON3 from '../../assets/images/service_guide_icon3.png';
import SERVICE_GUIDE_ICON4 from '../../assets/images/service_guide_icon4.png';
import BLUE_ARROW from '../../assets/images/blue_arrow.png';
import Footer from '../../components/Footer/Footer2';
import CHARGING_IMG from '../../assets/images/Group 5280.svg'

function ServiceGuidePage() {
    return (
        <React.Fragment>
            <NavBar />
            <div className="service" >
                <Card css={css`border-radius: 10px;`}>
                    <Card.Body>
                        <div className="menu_tab">
                            <h1>고객센터</h1>
                            <Tab list={support_tab} active={1}></Tab>
                        </div>
                        <TabContent css={css`padding-top: 3%`}>
                            <section css={[ContentWrap1, css`padding: 0px !important`]}>
                                <div css={css`display:flex; justify-content:start;`}>
                                    <div css={[TitleWrap, css`padding-top:6.5vh; padding-left:14.27vw; width:auto;
                                    `]}>
                                        <p className="number" css={css`color: ${secondary}`}>1</p>
                                        <p className="title" css={css`color: ${black2}; padding-top:0px !important;`}>회원가입</p>
                                        <p className="text">SEOULEV 홈페이지를 통해 <br /> 간편하게 회원가입하실 수 있습니다</p>
                                    </div>
                                    <div css={css` 
                                    width:55%;
                                    text-align: center;`}>
                                        <img src={SERVICE_GUIDE_IMG} alt="service_guide_img" />
                                    </div>
                                </div>
                            </section>
                            <section css={[ContentWrap2, css`padding: 0px !important`]}>
                                <div css={css`display:flex; 
                                align-items: center;
                                justify-content:space-between;
                                `}>
                                    <div css={[TitleWrap, css`padding-top:6.5vh; padding-bottom:5.284vh; padding-left:14.27vw; width:auto; height:279px; `]}>
                                        <p className="number">2</p>
                                        <p className="title" css={css` padding-top:0px !important;`}>멤버십카드 수령</p>
                                        <p className="text">회원가입시 입력한 주소로 <br /> 멤버십카드가 자동 발송됩니다</p>
                                    </div>
                                    <div css={css`width: 48%; padding-left:3vw`}>
                                        <img src={MEMBERCARD} alt="service_guide_img" />
                                    </div>
                                </div>
                            </section>
                            <section css={[ContentWrap3, css`padding: 0px !important`]}>
                                <div css={css`display:flex; align-items: center; `}>
                                    <div css={[TitleWrap, css`padding-top:6.5vh; padding-bottom:5.284vh; padding-left:14.27vw; width:auto; margin-right:1.7vw;height:279px; `]}>
                                        <p className="number" css={css`color: ${secondary}`}>3</p>
                                        <p className="title" css={css` padding-top:0px !important;`}>충전소 찾기</p>
                                        <p className="text">우리집/회사 근처의 <br /> 충전소를 검색하세요</p>
                                    </div>
                                    {/* <div css={css`
                                    width: 55%; 
                                    text-align: center;`}> */}
                                    <div css={css`text-align:center;width:55%`}>

                                        <img
                                            css={css`width:27.4vw; min-width:354px;`}
                                            src={STORY_FRAME} alt="service_guide_img" />
                                    </div>
                                    {/* </div> */}
                                </div>
                            </section>
                            <section css={[ContentWrap4, css`padding: 0px !important`]}>
                                <div css={css`display:flex; align-items: center`}>
                                    <div css={[TitleWrap, css`padding-top:6.5vh; padding-bottom:5.284vh; padding-left:14.27vw; width:auto; margin-right:5.5vw;height:279px; `]}>
                                        <p className="number" css={css`color: ${secondary}`}>4</p>
                                        <p className="title" css={css`color: ${button_text}; padding-top:0px !important;`}>충전하기</p>
                                        <p className="text">멤버십카드 인증을 통해 <br /> 간편하게 이용하실 수 있습니다</p>
                                    </div>
                                    {/* <div css={css`width:55%`}> */}
                                    <img css={css`width:27.4vw; min-width:320px; padding-right:1vw`} src={CHARGING_IMG} alt="service_guide_img" />
                                    {/* </div> */}


                                </div>
                            </section>
                        </TabContent>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const ContentWrap1 = css`
    background:${background_green};
`;

const ContentWrap2 = css`
    color: ${white};
    background: ${blue};
`;

const ContentWrap3 = css`
    color: ${white};
    background: ${primary};
`;

const ContentWrap4 = css`
    .icon_text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.03em;
        color: ${black2};
    }

    .img_wrap {
        height: 88px;

        img {
            width: 100%;
            min-width: 50px;
        }
    }
`;

const TitleWrap = css`
    width: 35%;

    .number {
        font-family: Gotham Light;
        font-weight: 300;
        font-size: 50px;
        line-height: 48px;

        margin-bottom: 12px;
    }

    .title {
        padding-top: 12px;

        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        letter-spacing: -0.03em;
    }

    .text {
        padding-top: 12px;

        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
    }
`;

export default ServiceGuidePage;