/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as themes from '../../styles/themes';
import SEOULEV_LOGO from '../../assets/images/logo_black.svg';
import Timer from '../../components/Timer/Timer';
import { authReqValidation, authCodeValidation, changePasswordValidation } from '../../validations/schemas/FindPasswordSchema';
import { useDispatch } from 'react-redux';
import { AuthCodeConfirm, AuthCodeReq, FindPassword } from '../../stores/actions/user_action';
import { ResponseResult } from '../../apis/response';
import ModalRegisterReq from '../../components/Modal/ModalRegisterReq';
import ModalChangePassword from '../../components/Modal/ModalChangePassword';
import { tb_point } from '../../styles/media-queries';
import { black, charger_gray, inputbox_befor_typed, inputbox_stroke, light_blue, red, white } from '../../styles/colors';
import LoadingDiv from '../../components/Loading/LoadingDiv';
import ModalAlert from '../../components/Modal/ModalAlert';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ModalNotFoundUser from '../../components/Modal/ModalNotFoundUser';

function FindPasswordPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ModalShow, setModalShow] = useState(false);

    const [FormCss, setFormCss] = useState([contentsStyle]);
    const [FindPasswordResCss, setFindPasswordResCss] = useState([themes.hide]);
    const [AuthReq, setAuthReq] = useState(false);
    const [IsAuth, setIsAuth] = useState(false);
    const [timer, setTimer] = useState(false);
    const [TimerStatus, setTimerStatus] = useState(false);
    const [Schema, setSchema] = useState(authReqValidation);
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [Phone, setPhone] = useState("");
    const [LgnId, setLgnId] = useState("");
    const [AuthCode, setAuthCode] = useState("");
    const [Loading, setLoading] = useState(false);
    const [authModalShow, setAuthModalShow] = useState(false);
    const [notFoundModalShow, setNotFoundModalShow] = useState(false);
    const [ResMsg, setResMsg] = useState();

    const [ChangePasswordStatus, setChangePasswordStatus] = useState(false);

    const { handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(Schema),
        reValidateMode: 'onChange'
    });

    const onChangeLgnIdHandler = (event) => {
        setLgnId(event.target.value);
    }

    const onChangePhoneHandler = (event) => {
        setPhone(event.target.value);
    }

    const onChangeAuthCodeHandler = (event) => {
        setAuthCode(event.target.value);
    }

    const onChangePasswordHandler = (event) => {
        setPassword(event.target.value);
    }

    const onChangeConfirmPasswordHandler = (event) => {
        setConfirmPassword(event.target.value);
    }

    const onAuthReqHandler = (data) => {
        setLoading(true);

        let reqParams = {
            lgnId: LgnId,
            phone: Phone
        }

        dispatch(AuthCodeReq(reqParams)).then(
            response => {
                setLoading(false);
                if (response.payload.result === ResponseResult.OK) {
                    setAuthModalShow(true);
                    setResMsg(response.payload.msg);

                    // 인증번호 전송 성공시
                    setAuthReq(true);
                    setTimerStatus(true);
                    setSchema(authCodeValidation);
                } else if (response.payload.msg === "해당 아이디를 가진 회원이 존재하지 않습니다.") {
                    setNotFoundModalShow(true);
                } else {
                    setAuthModalShow(true);
                    setResMsg(response.payload.msg);
                }
            }
        )
    }

    const onFindPasswordReqHandler = (data) => {
        let reqParams = {
            phone: Phone,
            authcode: AuthCode
        }

        dispatch(AuthCodeConfirm(reqParams)).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    // 인증완료시
                    setIsAuth(true);
                    setTimer(false);
                    setTimerStatus(false);
                    setSchema(changePasswordValidation);
                    setFormCss([themes.hide]);
                    setFindPasswordResCss([themes.show]);
                    if (authModalShow) {
                        // 팝업창이 계속 띄워져 있는 경우 종료
                        setAuthModalShow(false);
                    }
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }

    const onChangePasswordReqHandler = (data) => {
        let reqParams = {
            "phone": Phone,
            "changePassword": ConfirmPassword,
            "authcode": AuthCode,
        }

        dispatch(FindPassword(reqParams)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    setChangePasswordStatus(true);
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }

    useEffect(() => {
        if (!IsAuth && AuthReq) {
            // 인증번호 요청 성공 상태 일때,
            setSchema(authCodeValidation);
        }

        if (!IsAuth && timer && TimerStatus) {
            alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
            setTimer(false);
            setSchema(authReqValidation);
            setAuthReq(false);
            setAuthCode("");
        }
    }, [AuthReq, timer]);

    // 비밀번호 찾기 요청 UI
    const findPasswordForm = (
        <div css={FormCss} className="find_password">
            <h1 css={findPasswordTitle}>
                비밀번호 찾기
            </h1>
            <h2 css={findPasswordTextStyle}>
                SEOULEV 가입시 등록한 아이디를 입력하고 휴대전화번호를 인증해주세요
            </h2>
            <div css={wrap}>
                <Form onSubmit={handleSubmit(onAuthReqHandler)}>
                    <Form.Group className="mb-3">
                        <Form.Control {...register("LgnId")} placeholder="아이디" autoComplete="on" isInvalid={!!errors.LgnId?.message} readOnly={AuthReq} onChange={onChangeLgnIdHandler} />
                        <Form.Control.Feedback type="invalid" css={css`padding-top: 8px; align-items: left; text-align: left`}>
                            × {errors.LgnId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <div css={css`display:flex`}>
                            <Form.Control css={css`max-width: 351px; margin-right: 8px;`} {...register("Phone")} placeholder="휴대전화번호" autoComplete="on" isInvalid={!!errors.Phone?.message} onChange={onChangePhoneHandler} readOnly={AuthReq} />
                            <div css={css`width: 100%; max-width: 141px`}>
                                <button css={[themes.BtnOutLineBlack, css`font-weight: 400; font-size: 16px; line-height: 225.3%;`]} type="submit" disabled={LgnId !== "" && Phone !== "" ? false : true}>
                                    인증번호 전송
                                </button>
                            </div>
                        </div>
                        <Form.Control.Feedback type="invalid" css={errors.Phone?.message && css`display: block; padding-top: 8px; align-items: left; text-align: left`}>
                            × {errors.Phone?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                {
                    AuthReq &&
                    <Form onSubmit={handleSubmit(onFindPasswordReqHandler)}>
                        <Form.Group className="mb-3">
                            <div css={css`display:flex; align-items: center`}>
                                <Form.Control css={css`max-width: 351px; margin-right: 22px;`} {...register("AuthCode")} placeholder="인증번호" isInvalid={!!errors.AuthCode?.message} onChange={onChangeAuthCodeHandler} value={AuthCode} />
                                <div>
                                    <h5 css={css`font-weight: 400; font-size: 16px; line-height: 225.3%; color: ${inputbox_befor_typed}`}>*남은 시간 <span css={css`color:${light_blue}`}><Timer mm={3} ss={0} setTimer={setTimer}></Timer></span></h5>
                                </div>
                            </div>
                            <Form.Control.Feedback type="invalid" css={errors.AuthCode?.message && css`display: block; padding-top: 8px; align-items: left; text-align: left`}>
                                × {errors.AuthCode?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group css={css`width: 100%; height: 64px;`}>
                            <button css={themes.BtnFullBlue2} disabled={AuthReq && AuthCode !== "" ? false : true} type="submit">다음</button>
                        </Form.Group>
                    </Form>
                }
            </div>
        </div>
    );

    // 비밀번호 찾기 완료 UI
    const findPasswordRes = (
        <div css={FindPasswordResCss} className="find_password">
            <Form onSubmit={handleSubmit(onChangePasswordReqHandler)}>
                <h1 css={findPasswordTitle}>
                    비밀번호 변경
                </h1>
                <h1 css={findPasswordTextStyle}>
                    비밀번호를 재설정해주세요.
                </h1>
                <div css={css`padding-bottom: 5%; width: 100%; margin: auto; max-width: 500px`}>
                    <Form.Group className="mb-3">
                        <Form.Control {...register("Password")} type="password" placeholder="비밀번호 입력" autoComplete="on" onChange={onChangePasswordHandler} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control {...register("PasswordConfirm")} type="password" placeholder="비밀번호 재입력" autoComplete="on" onChange={onChangeConfirmPasswordHandler} />
                    </Form.Group>
                    {
                        errors.Password?.message
                            ? <div css={css`background: ${charger_gray}; border: 1px solid ${inputbox_stroke}; border-radius: 10px; padding: 12px 85px; font-weight: 400; font-size: 16px; line-height: 225.3%; color: ${red}`} className="mb-3">
                                {errors.Password?.message}
                            </div>
                            : errors.PasswordConfirm?.message
                                ? <div css={css`background: ${charger_gray}; border: 1px solid ${inputbox_stroke}; border-radius: 10px; padding: 12px 85px; font-weight: 400; font-size: 16px; line-height: 225.3%; color: ${red}`} className="mb-3">
                                    {errors.PasswordConfirm?.message}
                                </div>
                                : ""
                    }
                    <Form.Group css={css`width: 100%; height: 64px; margin-top: 24px;`}>
                        <button type="submit" css={[themes.BtnOutLineBlue, css`font-weight: 700; font-size: 18px; line-height: 26px;`]} disabled={Password !== "" && ConfirmPassword !== "" ? false : true}>다음</button>
                    </Form.Group>
                </div>
            </Form>
        </div>
    );


    return (
        <React.Fragment>
            {Loading ? <LoadingDiv /> : null}
            <div css={findPassworddWrap}>
                <Card css={findPasswordContentsWrap}>
                    <Card.Body>
                        <Link to="/">
                            <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" style={{ width: 164, height: 32 }} />
                        </Link>
                        {findPasswordForm}
                        {findPasswordRes}
                    </Card.Body>
                </Card>
            </div>
            <ModalAlert
                show={authModalShow}
                onClickHandler={() => setAuthModalShow(false)}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
            <ModalRegisterReq
                show={ModalShow}
                onHide={() => setModalShow(false)}
            />
            <ModalChangePassword
                show={ChangePasswordStatus}
                onHide={() => setModalShow(false)}
            />
            <ModalNotFoundUser
                show={notFoundModalShow}
                onHide={() => setNotFoundModalShow(false)}
                onClickLoginPageHandler={() => navigate("/register")}
            />
        </React.Fragment>
    )
}

const findPassworddWrap = css`
    position: relative;
    min-width: ${tb_point};
    height: 100vh;
    background: ${white};
`;

const findPasswordContentsWrap = css`
    padding:10%; 
    border: none; 
    width:100%;
    height:100%;
    text-align: center;
`;

const logoWrap = css`
  margin-bottom: 96px;
`;

const findPasswordTitle = css`
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${black};
`;

const findPasswordTextStyle = css`
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${black};

    margin: 16px 0 24px 0;
`;

const contentsStyle = css`
    position: relative;
    height: 100%;
`;

const wrap = css`
    padding-bottom: 5%; 
    width: 100%; 
    margin: auto; 
    max-width: 500px
`;

export default FindPasswordPage