/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Spinner from '../../assets/images/spinner.gif';
import { button_text } from '../../styles/colors';

export const LoadingDiv = () => {
    return <div css={backgroundWrap}>
        <div css={textStyle}>
             <img src={Spinner} alt="로딩중" width="30%" />
        </div>
    </div>;
};

const backgroundWrap = css`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffffb7;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const textStyle = css`
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
    text-align: center;
    color: ${button_text};
`;

export default LoadingDiv;