/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as themes from '../../styles/themes';
import { phoneValidation, authCodeValidation } from '../../validations/schemas/FindLoginIdSchema';
import SEOULEV_LOGO from '../../assets/images/logo_black.svg';
import Timer from '../../components/Timer/Timer';
import { useDispatch } from 'react-redux';
import { AuthCodeConfirm, AuthCodeReq, FindId } from '../../stores/actions/user_action';
import { ResponseResult } from '../../apis/response';
import ModalRegisterReq from '../../components/Modal/ModalRegisterReq';
import { notFoundUserToPhone } from '../../validations/messages/messages';
import { black, inputbox_befor_typed, light_blue, white } from '../../styles/colors';
import { tb_point } from '../../styles/media-queries';
import CustomAlert from '../../components/Alert/CustomAlert';
import LoadingDiv from '../../components/Loading/LoadingDiv';
import ModalAlert from '../../components/Modal/ModalAlert';

function FindLoginIdPage() {
    const dispatch = useDispatch();
    const [ModalShow, setModalShow] = useState(false);

    const [FormCss, setFormCss] = useState([contentsStyle]);
    const [FindLoginIdResCss, setFindLoginIdResCss] = useState([themes.hide]);
    const [AuthReq, setAuthReq] = useState(false);
    const [timer, setTimer] = useState(false);
    const [TimerStatus, setTimerStatus] = useState(false);
    const [Schema, setSchema] = useState(phoneValidation);
    const [LoginId, setLoginId] = useState("");

    const [Phone, setPhone] = useState("");
    const [Loading, setLoading] = useState(false);
    const [AuthCode, setAuthCode] = useState("");

    const [AlertComponent, setAlertComponent] = useState();
    const [copyModalShow, setCopyModalShow] = useState(false);
    const [authModalShow, setAuthModalShow] = useState(false);
    const [ResMsg, setResMsg] = useState();

    const { handleSubmit, register, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(Schema)
    });

    const setAlertComponentHandler = () => {
        setAlertComponent();
    }

    const onAuthReqHandler = (event) => {
        event.preventDefault();
        setLoading(true);

        let reqParams = {
            phone: Phone
        }

        dispatch(AuthCodeReq(reqParams)).then(
            response => {
                // console.log(response);
                setLoading(false);
                if (response.payload.result === ResponseResult.OK) {
                    // 인증번호 전송 성공시
                    // alert("인증번호가 전송되었습니다.");
                    setAuthReq(true);

                    setAuthModalShow(true);
                    setResMsg(response.payload.msg);
                    setTimerStatus(true);

                    // setAlertComponent(<CustomAlert type="info" message={response.payload.msg} alertHandler={setAlertComponentHandler}></CustomAlert>);
                } else {
                    if (response.payload.msg === notFoundUserToPhone) {
                        setModalShow(true);
                    } else {
                        setAuthModalShow(true);
                        setResMsg(response.payload.msg);
                        // setAlertComponent(<CustomAlert type="warnning" message={response.payload.msg} alertHandler={setAlertComponentHandler}></CustomAlert>);
                    }
                }
            }
        )
    }

    const onChangePhoneHandler = (event) => {
        setPhone(event.target.value);
    }

    const onChangeAuthCodeHandler = (event) => {
        setAuthCode(event.target.value);
    }

    const onFindLoginIdReqHandler = (data) => {
        let reqParams = {
            phone: Phone,
            authcode: data.AuthCode
        }

        dispatch(AuthCodeConfirm(reqParams)).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    setTimer(false);
                    setTimerStatus(false);

                    let reqParams = {
                        phone: Phone
                    }

                    dispatch(FindId(reqParams)).then(
                        response => {
                            if (response.payload.result === ResponseResult.OK) {
                                setLoginId(response.payload.data.lgnId);
                                setFormCss([themes.hide]);
                                setFindLoginIdResCss([themes.show]);
                            } else {
                                alert(response.payload.msg);
                            }
                        }
                    )
                } else {
                    alert(response.payload.msg);
                }
            }
        );
    }

    const onClickCopyHandler = async () => {
        // try {
        // await navigator.clipboard.writeText(LoginId);
        const str = document.createElement('textarea');
        document.body.appendChild(str);
        str.value = LoginId;
        str.select();
        document.execCommand("copy");
        setCopyModalShow(true);
        // setAlertComponent(<CustomAlert type="info" message="아이디가 복사되었습니다." alertHandler={setAlertComponentHandler}></CustomAlert>);
        document.body.removeChild(str);
        //   } catch (err) {
        // console.error('Failed to copy: ', err);
        // }
        // navigator.clipboard.writeText(LoginId);
    }

    useEffect(() => {
        if (AuthReq) {
            setSchema(authCodeValidation);
        }

        if (timer && TimerStatus) {
            alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
            setTimer(false);
            setSchema(phoneValidation);
            setAuthReq(false);
            setValue("AuthCode", null);
        }
    }, [AuthReq, timer]);

    // 아이디 찾기 요청 UI
    const findLoginIdForm = (
        <div css={FormCss} className="find_lgnid">
            <h1 css={findLgnidTitle}>
                아이디 찾기
            </h1>
            <h2 css={findLgnidTextStyle}>
                SEOULEV 가입 시 입력한 휴대전화번호를 인증해주세요.
            </h2>
            <div css={wrap}>
                <Form onSubmit={onAuthReqHandler}>
                    <Form.Group className="mb-3">
                        <div css={css`display:flex`}>
                            <Form.Control css={css`max-width: 351px; margin-right: 8px;`} {...register("Phone")} placeholder="휴대전화번호" autoComplete="on" isInvalid={!!errors.phone?.message} onChange={onChangePhoneHandler} readOnly={AuthReq} />
                            <div css={css`width: 100%; max-width: 141px`}>
                                <button css={[themes.BtnOutLineBlack, css`font-weight: 400; font-size: 16px; line-height: 225.3%;`]} type="submit" disabled={Phone !== "" ? false : true}>
                                    인증번호 전송
                                </button>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                {errors.phone?.message}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Form>
                <Form onSubmit={handleSubmit(onFindLoginIdReqHandler)}>
                    <Form.Group className="mb-3">
                        <div css={css`display:flex; align-items: center`}>
                            <Form.Control css={css`max-width: 351px; margin-right: 22px;`} {...register("AuthCode")} placeholder="인증번호" isInvalid={!!errors.AuthCode?.message} onChange={onChangeAuthCodeHandler} />
                            {AuthReq &&
                                <div>
                                    <h5 css={css`font-weight: 400; font-size: 16px; line-height: 225.3%; color: ${inputbox_befor_typed}`}>*남은 시간 <span css={css`color:${light_blue}`}><Timer mm={3} ss={0} setTimer={setTimer}></Timer></span></h5>
                                </div>
                            }
                        </div>
                        <Form.Control.Feedback type="invalid" css={errors.AuthCode?.message && css`display: block; padding-top: 8px; align-items: left; text-align: left`}>
                            × {errors.AuthCode?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group css={css`width: 100%; height: 64px;`}>
                        <button css={themes.BtnFullBlue2} disabled={AuthReq && AuthCode !== "" ? false : true} type="submit">다음</button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );

    // 아이디 찾기 완료 UI
    const findLoginIdRes = (
        <div css={FindLoginIdResCss} className="find_lgnid">
            <h1 css={findLgnidTitle}>
                아이디 찾기
            </h1>
            <h1 css={findLgnidTextStyle}>
                고객님이 가입하신 아이디는 아래와 같습니다.
            </h1>
            <div css={css`padding-bottom: 5%; width: 100%; margin: auto; max-width: 500px`}>
                <Form.Group className="mb-3">
                    <div css={css`display:flex`}>
                        <Form.Control css={css`max-width: 351px; margin-right: 8px;`} placeholder="아이디" autoComplete="on" readOnly={true} value={LoginId} />
                        <div css={css`width: 100%; max-width: 141px`}>
                            <button css={[themes.BtnOutLineBlack, css`font-weight: 400; font-size: 16px; line-height: 225.3%;`]} onClick={onClickCopyHandler}>
                                복사하기
                            </button>
                        </div>
                    </div>
                    <Form.Group css={css`width: 100%; height: 64px; margin-top: 24px;`}>
                        <Link to="/login">
                            <button css={themes.BtnFullBlue2}>로그인하러 가기</button>
                        </Link>
                    </Form.Group>
                </Form.Group>
            </div>
        </div>
    );


    return (
        <React.Fragment>
            {Loading ? <LoadingDiv /> : null}
            <div css={findLgnidWrap}>
                {
                    AlertComponent &&
                    AlertComponent
                }
                <Card css={findLgnidContentsWrap}>
                    <Card.Body>
                        <Link to="/">
                            <img css={logoWrap} src={SEOULEV_LOGO} alt="seoulev_logo" style={{ width: 164, height: 32 }} />
                        </Link>
                        {findLoginIdForm}
                        {findLoginIdRes}
                    </Card.Body>
                </Card>
            </div>
            <ModalAlert
                show={copyModalShow}
                onClickHandler={() => setCopyModalShow(false)}
                oneButtonTitle={"확인"}
                alertContent={"아이디가 복사되었습니다."}
                onHide={() => setModalShow(false)}
            />
            <ModalAlert
                show={authModalShow}
                onClickHandler={() => setAuthModalShow(false)}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
            <ModalRegisterReq
                show={ModalShow}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    )
}

const findLgnidWrap = css`
  position: relative;
  min-width: ${tb_point};
  height: 100vh;
  background: ${white};
`;

const findLgnidContentsWrap = css`
    padding:10%; 
    border: none; 
    width:100%;
    height:100%;
    text-align: center;
`;

const findLgnidTitle = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: ${black};
`;

const findLgnidTextStyle = css`
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${black};

    margin: 16px 0 24px 0;
`;

const contentsStyle = css`
    position: relative;
    height: 100%;
`;

const logoWrap = css`
  margin-bottom: 96px;
`;

const wrap = css`
    padding-bottom: 5%; 
    width: 100%; 
    margin: auto; 
    max-width: 500px
`;

export default FindLoginIdPage