// 회원
export const LOGIN_USER = "login_user";
export const LOGOUT_USER = "logout_user";
export const REFRESH_TOKEN = "refresh_token";
export const UPDATE_PAYCARD = "update_paycard";
export const CONFIRM_LGN_ID = "confirm_lgn_id";
export const REGIST_USER = "regist_user";
export const GET_USER = "get_user";
export const GET_USER_MEMBERCARD = "get_user_membecard";
export const GET_USER_PATCARD = "get_user_paycard";
export const UPDATE_USER_INFO = "update_user_info";
export const UPDATE_USER_PASSWORD = "update_user_password";
export const DELETE_USER = "delete_user";
export const AUTH_CODE_REQ = "auth_code_req";
export const AUTH_CODE_CONFIRM = "auth_code_confirm";
export const FIND_ID = "find_id";
export const FIND_PASSWORD = "find_password";
export const CHECK_PHONE = "check_phone";

// 약관
export const GET_POLICY = "get_policy";

// FAQ
export const GET_FAQ_CATEGORY = "get_faq_ctegory";
export const GET_FAQ_LIST = "get_faq_list";

// 공지사항
export const GET_NOTICE_LIST = "get_notice_list";
export const GET_NOTICE = "get_notice";

// 충전소 찾기
export const GET_STATION_LIST = "get_station_list";
export const GET_STATION = "get_station";
export const GET_SEARCH_STATION = "get_search_station";

// 충전내역
export const GET_CHARGING_LIST = "get_charging_list";
export const GET_CHARGING_LIST_EXPORT = "get_charging_list_export";

// 포인트내역
export const GET_POINT_LIST = "get_point_list";
export const GET_POINT_CARD_INFO = "get_point_card_info";

// 차량
export const GET_CAR_LIST = "get_car_list";

// 충전기 설치 신청
export const REGIST_RECEPTION = "regist_reception";

// QR 결제
export const GET_CHARGER_TO_QR = "get_charger_to_qr";
// export const QRPAY_PAYMENT = "qrpay_payment";

export const REGIST_USER_ING = "regist_user_ing";
export const REGIST_USER_DONE = "regist_user_done";
export const EDIT_USER_ING = "edit_user_ing";
export const EDIT_USER_DONE = "edit_user_done";