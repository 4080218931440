import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalLoginRequire from '../components/Modal/ModalLoginRequire';
import TempPage from '../pages/TempPage';


const PrivateRoute = (props) => {
    // 로그인 여부 확인 후 이동
    let navigate = useNavigate(); 
    const params = new URLSearchParams(window.location.search);
    const user = useSelector(state => state.user);
    const {component: RouteComponent, path} = props;
     
    const onClickLoginPageHandler = () => {
        navigate(`/login?redirectUrl=${path}`);
    }

    if (!user.aat) {
        return <ModalLoginRequire
        show={true}
        onClickLoginPageHandler={onClickLoginPageHandler}
    />
    } else if (path === "/mypage/paycard") {
        const result = params.get("result"); 
        if (result) {
            navigate(`/mypage/paycard`);
            // navigate(`/mypage/paycard`, { state: { result : params.get("result")} });
        }
    } else if (path === "/mypage/info") {
        const result = params.get("result"); 
        if (result) {
            return <TempPage path="/mypage/info"/>;
        }
    } else {
        return <RouteComponent/>;
    }

    return <RouteComponent/>;
}

export default PrivateRoute;