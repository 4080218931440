/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Pagination from 'react-bootstrap/Pagination';

const Pagenate = ({currentPage, totalPage, onClickPageChange}) => {
  let items = [];

  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === parseInt(currentPage)} onClick={() => onClickPageChange(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <div className="mt-3 item-center">
        <Pagination css={css`display: flex;
    justify-content: center;`}>{items}</Pagination>
    </div>
  )
};

export default Pagenate