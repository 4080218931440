import * as yup from "yup";
import * as messages from "../messages/messages";

export const receptionValidation = yup.object({
    Nm: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, 255+messages.maxTextMessage),
    Cttpc: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^[0-9]{9,11}$/, messages.onlyNumberPhoneMessage),
    Rdnmadr: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, 255+messages.maxTextMessage),
    Adres: yup
        .string()
        .required(messages.requiredMessage)
        .max(255, 255+messages.maxTextMessage),
    AdresDetail: yup
        .string()
        .max(255, 255+messages.maxTextMessage),
    Cn: yup
        .string(),
    Agree: yup
        .bool()
        .oneOf([true], messages.requiredMessage)
}).required();

export default receptionValidation;