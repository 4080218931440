/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Card, Form, TabContent } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ResponseResult } from "../../apis/response";
import NavBar from "../../components/NavBar/NavBar";
import Pagenate from "../../components/Pagenate/Pagenate";
import Tab from "../../components/Tab/Tab";
import { mypage_tab } from "../../configs/base";
import {
  getChargingList,
  getChargingListExport,
} from "../../stores/actions/charging_action";
import {
  mypage_filter_dt,
  mypage_filter_tb,
  tb_point,
} from "../../styles/media-queries";
import { BtnFullGraySquare, MyPageWrap } from "../../styles/themes";
import {
  today,
  monthBefore,
  formatYYYYMMDDHHmmss,
  moneyFormat,
  last_month_end_day,
  last_month_start_day,
} from "../../utils/utils";
import DOWNLOAD_ICON from "../../assets/images/download_icon.png";
import DOWNLOAD_ICON2 from "../../assets/images/download_icon2.png";
import { typo_light_blue } from "../../styles/colors";
import ModalAlert from "../../components/Modal/ModalAlert";
import Footer from "../../components/Footer/Footer2";

function ChargingListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useState(useSelector((state) => state.user));
  const params = new URLSearchParams(window.location.search);

  const Month = today("mm") + " 월";
  const LastMonth = monthBefore("mm", 1) + " 월";

  const [Charging, setCharging] = useState([]);
  const [IsCheckAll, setIsCheckAll] = useState(false);
  const [IsCheck, setIsCheck] = useState([]);
  const [SearchStartDate, setSearchStartDate] = useState(
    today("yyyymm") + "-01"
  );
  const [SearchEndDate, setSearchEndDate] = useState(today());

  const [currentPage, setCurrentPage] = useState(
    params.get("currentPage") ?? "1"
  );
  const [totalPage, setTotalPage] = useState();
  const cPage = params.get("currentPage");

  const [modalShow, setModalShow] = useState(false);
  const [ResMsg, setResMsg] = useState();

  const [IsLoading, setIsLoading] = useState(false);

  // 체크박스 관련
  const handleSelectAll = (event) => {
    // 전체선택 체크박스 선택시 전체선택 체크박스 상태 변경 (IsCheckAll)
    const checked = event.target.checked;
    if (checked) {
      setIsCheck(Charging.map((p) => p.id));
    } else {
      setIsCheck([]);
    }

    setIsCheckAll(!IsCheckAll);
  };

  const handleSelectSingle = (event) => {
    // 하위 체크박스 선택시

    if (IsCheckAll) {
      setIsCheckAll(false);
    }

    if (IsCheck.includes(event.target.id)) {
      // 존재
      setIsCheck(IsCheck.filter((value) => value !== event.target.id));
    } else {
      //없음
      setIsCheck([...IsCheck, event.target.id]);
    }

  };
  // 체크박스 관련

  // 기간 검색 관련
  const onClick1MonthHandler = () => {
    setSearchStartDate(last_month_start_day());
    setSearchEndDate(last_month_end_day());
  };

  const onClickThisMonthHandler = () => {
    setSearchStartDate(today("yyyymm") + "-01");
    setSearchEndDate(today());
  };

  const onSearchStartDateChangeHandler = (event) => {
    setSearchStartDate(event.target.value);
  };

  const onSearchEndDateChangeHandler = (event) => {
    setSearchEndDate(event.target.value);
  };
  // 기간 검색 관련

  const onClickSearchDateHandler = () => {
    setIsLoading(true);

    navigate(
      "?currentPage=" +
      1 +
      "&searchStartDt=" +
      SearchStartDate +
      "&searchEndDt=" +
      SearchEndDate
    );
  };

  const onClickExcelDownloadHandler = () => {
    let reqParams = {
      userId: user[0].id,
    };
    if (Charging.length == 0) {
      setModalShow(true);
      setResMsg("충전내역이 존재하지 않습니다.");
      return false;
    }
    if (IsCheck.length == 0) {
      // 전체선택인 경우
      reqParams = {
        ...reqParams,
        ids: 'ALL',
        searchStartDt: SearchStartDate,
        searchEndDt: SearchEndDate
      };
    } else if (IsCheck.length > 0) {
      // 전체선택이 아닌 경우
      reqParams = {
        ...reqParams,
        ids: IsCheck,
      };
    } else {
      setModalShow(true);
      setResMsg("다운로드할 이용내역을 선택해주세요.");

      return false;
    }

    dispatch(getChargingListExport(reqParams)).then((response) => {

      let byteArray = new Uint8Array(response.payload.data.data);


      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/octet-stream" })
      );

      a.download = `charging_list.XLSX`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  // 페이지 클릭
  const onClickPageChangeHandler = (page) => {
    setCurrentPage(page);
    navigate("?currentPage=" + page);
  };

  useEffect(() => {
    let reqParams = {
      currentPage: currentPage,
      id: user[0].id,
      searchStartDt: SearchStartDate,
      searchEndDt: SearchEndDate,
    };

    dispatch(getChargingList(reqParams)).then((response) => {
      if (response.payload.result === ResponseResult.OK) {
        setCharging(response.payload.data.data);
        setTotalPage(parseInt(response.payload.data.totalPage));
      } else {
        alert(response.payload.msg);
      }
    });
  }, []);

  useEffect(() => {
    let reqParams = {
      currentPage: cPage,
      id: user[0].id,
      searchStartDt: SearchStartDate,
      searchEndDt: SearchEndDate,
    };

    dispatch(getChargingList(reqParams)).then((response) => {
      if (response.payload.result === ResponseResult.OK) {
        setCharging(response.payload.data.data);
        setCurrentPage(parseInt(response.payload.data.currentPage));
        setTotalPage(parseInt(response.payload.data.totalPage));

      } else {
        alert(response.payload.msg);
      }
    });
  }, [
    currentPage,
    cPage,
    params.get("searchStartDt"),
    params.get("searchEndDt"),
  ]);

  useEffect(() => {
    if (IsCheckAll) {
      // setIsCheck(Charging.map((p) => p.id));
      setIsCheckAll(false);
    } else {
      setIsCheck([]);
    }
  }, [Charging]);


  return (
    <>
      <NavBar />
      <div css={MyPageWrap}>
        <Card>
          <Card.Body>
            <h1>마이페이지</h1>
            <Tab list={mypage_tab} active={0} userId={1}></Tab>
            <TabContent>
              <div className="filter">
                <div css={filterDownWrap}>
                  <button
                    css={[
                      BtnFullGraySquare,
                      css`
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 225.3%;
                        &:hover {
                          img {
                            transition: all 0.5s ease;
                            content: url(${DOWNLOAD_ICON2});
                          }
                        }
                      `,
                    ]}
                    onClick={onClickExcelDownloadHandler}
                  >
                    내역다운로드
                    <img src={DOWNLOAD_ICON} alt="download_icon" />
                  </button>
                </div>
                <div css={filterDayDtWrap}>
                  <button
                    css={[BtnFullGraySquare, filterDayWrap]}
                    onClick={onClick1MonthHandler}
                  >
                    {LastMonth}
                  </button>
                  <button
                    css={[BtnFullGraySquare, filterDayWrap]}
                    onClick={onClickThisMonthHandler}
                  >
                    {Month}
                  </button>
                  <Form.Control
                    value={SearchStartDate}
                    type="date"
                    css={css`
                      margin-right: 10px;
                    `}
                    onChange={onSearchStartDateChangeHandler}
                  />
                  <h1
                    css={css`
                      margin-right: 10px;
                    `}
                  >
                    -
                  </h1>
                  <Form.Control
                    value={SearchEndDate}
                    type="date"
                    css={css`
                      margin-right: 10px;
                    `}
                    onChange={onSearchEndDateChangeHandler}
                  />
                  <button
                    css={[
                      BtnFullGraySquare,
                      css`
                        margin-left: 8px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 225.3%;
                      `,
                    ]}
                    onClick={onClickSearchDateHandler}
                  >
                    {/* {IsLoading && <span classNamefilter="spinner-border spinner-border-sm mr-1"></span>} */}
                    조회
                  </button>
                </div>
              </div>
              <div className="tb_filter" css={filterDayTbWrap}>
                <button
                  css={[BtnFullGraySquare, filterDayWrap]}
                  onClick={onClick1MonthHandler}
                >
                  {LastMonth}
                </button>
                <button
                  css={[BtnFullGraySquare, filterDayWrap]}
                  onClick={onClickThisMonthHandler}
                >
                  {Month}
                </button>
                <Form.Control
                  value={SearchStartDate}
                  type="date"
                  css={css`
                    margin-right: 10px;
                  `}
                  onChange={onSearchStartDateChangeHandler}
                />
                <h1
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  -
                </h1>
                <Form.Control
                  value={SearchEndDate}
                  type="date"
                  css={css`
                    margin-right: 10px;
                  `}
                  onChange={onSearchEndDateChangeHandler}
                />
                <button
                  css={[
                    BtnFullGraySquare,
                    css`
                      margin-left: 8px;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 225.3%;
                    `,
                  ]}
                  onClick={onClickSearchDateHandler}
                >
                  {/* {IsLoading && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                  조회
                </button>
              </div>
              <div
                css={css`
                  overflow-x: auto;
                `}
                className="charging"
              >
                <table
                  className="text-center"
                  css={css`
                    min-width: ${tb_point};
                    width: 100%;
                  `}
                >
                  {/* TODO table 열 넓이 고정 */}
                  <colgroup><col width="15px" /><col width="25%" /><col width="25%" /><col width="15%" /><col width="15%" /><col width="8%" /><col /></colgroup>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          checked={IsCheckAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>충전 시작</th>
                      <th>충전 종료</th>
                      <th>충전소명</th>
                      <th>충전기 관리번호</th>
                      <th>충전량</th>
                      <th>결제금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Charging.length === 0 ? (
                      <tr>
                        <td colSpan={8}>충전내역이 존재하지 않습니다.</td>
                      </tr>
                    ) : (
                      Charging.map((obj, index) => {
                        return (
                          <tr key={index}>
                            <td
                              css={css`
                                width: 1%;
                              `}
                            >
                              <Form.Check
                                type="checkbox"
                                onChange={handleSelectSingle}
                                id={obj.id}
                                checked={
                                  IsCheck.includes(obj.id) ? true : false
                                }
                              />
                            </td>
                            <td>
                              {obj.beginDt
                                ? formatYYYYMMDDHHmmss(obj.beginDt)
                                : "-"}
                            </td>
                            <td>
                              {obj.endDt
                                ? formatYYYYMMDDHHmmss(obj.endDt)
                                : "-"}
                            </td>
                            <td>{obj.stationNm ? obj.stationNm : "-"}</td>
                            <td>{obj.chrgrIdntfr ? obj.chrgrIdntfr : "-"}</td>
                            <td>{obj.elctcQy ? obj.elctcQy : "-"}</td>
                            <td
                              css={css`
                                color: ${typo_light_blue};
                                /* TODO 언더라인 제거 */
                                /* text-decoration-line: underline; */
                              `}
                            >
                              {/* TODO 결제금액란에 포인트 결제 내역도 추가 */}
                              {
                                obj.paidCardPrice
                                  ? moneyFormat(Number(obj.paidCardPrice)) + "원"
                                  : obj.paidPointPrice ? moneyFormat(Number(obj.paidPointPrice)) + "P" : "-"
                              }
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </TabContent>
            {totalPage > 1 && (
              <Pagenate
                currentPage={currentPage}
                totalPage={totalPage}
                onClickPageChange={onClickPageChangeHandler}
              />
            )}
          </Card.Body>
        </Card>
      </div>
      <div
        css={css`
          width: 100%;
          height: 100vh;
        `}
      >
        <Footer />
      </div>
      <ModalAlert
        show={modalShow}
        onClickHandler={() => setModalShow(false)}
        oneButtonTitle={"확인"}
        alertContent={ResMsg}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

const filterDownWrap = css`
  height: 28px;
  min-width: 100px;
  @media (min-width: ${tb_point}) {
    width: 136px;
  }
`;

const filterDayDtWrap = css`
  height: 28px;
  display: flex;
  ${mypage_filter_tb} {
    display: none;
  }
`;

const filterDayTbWrap = css`
  display: flex;
  ${mypage_filter_dt} {
    display: none;
  }
`;

const filterDayWrap = css`
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 225.3%;
  min-width: 50px;
`;

export default ChargingListPage;
