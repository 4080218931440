import React from 'react';
import { useState } from 'react';
import ModalNotFound from '../Modal/ModalNotFound';
import ModalRegisterReq from '../Modal/ModalRegisterReq';

function NotFound() {
    const [ModalShow, setModalShow] = useState(true);
    return (
        <React.Fragment>
            <ModalNotFound 
                show={ModalShow}
                onHide={() => setModalShow(false)}
            />   
        </React.Fragment>
    )
}

export default NotFound