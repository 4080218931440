import React from 'react';
import { useNavigate } from 'react-router-dom';
import TempPage from '../pages/TempPage';

const RegisterRoute = (props) => {
    // 결제카드 정보 확인 후 이동
    let navigate = useNavigate(); 
    const params = new URLSearchParams(window.location.search);
    const {component: RouteComponent} = props;
    const result = params.get("result");
    
    if (result) {
        // 본인인증 > 새창 
        // 결제카드 > 팝업
        let result_json = JSON.parse(result);
        if ("Resultcd" in result_json) {
            // 새창 > 본인인증 응답이 온 경우   
            return <TempPage />;
        } else if ("ResultCode" in result_json) {
            // 결제카드 등록 응답이 온 경우
            navigate('/register'); 
        }
    } 
    else {
        // 회원가입
        // 결제카드 > 팝업 > 회원가입 이동
        const kg_result = params.get("kg");

        if (kg_result) {
            // 결제카드 > 팝업 > 회원가입 이동
            let kg_result_json = JSON.parse(kg_result);

            if (kg_result_json.Resultcd === "0000") {
                // 성공
            }
        } 

        return <RouteComponent/>;
    };
    return <RouteComponent/>;
}

export default RegisterRoute;