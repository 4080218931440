/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Card, TabContent } from 'react-bootstrap';
import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer2';
import { black2, blue, green, primary, white } from '../../styles/colors';
import { reception_tab } from '../../configs/base';
import Tab from '../../components/Tab/Tab';
import SEOULEV_LOGO from '../../assets/images/logo_black.svg';
import SEOULEV_WHITE_LOGO from '../../assets/images/logo_white_blue.svg';
import HANDSHAKE_ICON from '../../assets/images/handshake_icon.svg';
import RECEPTION_ICON1 from '../../assets/images/reception_icon1.png';
import RECEPTION_ICON2 from '../../assets/images/reception_icon2.png';
import RECEPTION_ICON3 from '../../assets/images/reception_icon3.png';
import RECEPTION_ICON4 from '../../assets/images/reception_icon4.png';
import RECEPTION_ICON5 from '../../assets/images/reception_icon5.png';
import RECEPTION_ICON6 from '../../assets/images/reception_icon6.png';
import RECEPTION_ICON7 from '../../assets/images/reception_icon7.svg';
import RECEPTION_ICON8 from '../../assets/images/reception_icon8.svg';
import { BtnOutLineBlue, BtnOUtLineDarkBlue, TextStyle11, TextStyle3, TextStyle7 } from '../../styles/themes';
import { Link } from 'react-router-dom';

function ReceptionGuidePage() {
    return (
        <React.Fragment>
            <NavBar />
            <div className="reception">
                <Card css={css`border-radius: 10px;`}>
                    <Card.Body >
                        <h1 css={css`padding: 2.6vh 3.3vw;`}>설치안내</h1>
                        <div css={css`padding: 0 3.3vw;`}>
                            <Tab list={reception_tab} active={0} />
                        </div>
                        <TabContent>
                            <section className="section1">
                                <div>
                                    <div className="title_dt_div">
                                        <p className="title_reception">EXPERIENCE YOUR NEW MOBILITY WITH</p>
                                        <img src={SEOULEV_LOGO} alt="seoulev_logo" style={{ width: 164, height: 32 }} />
                                    </div>
                                    <div className="title_tb_div">
                                        <p className="title_reception">EXPERIENCE YOUR NEW MOBILITY</p>
                                        <div css={css`display:flex; justify-content: center;`}>
                                            <p className="title_reception">WITH</p>
                                            <img src={SEOULEV_LOGO} alt="seoulev_logo" style={{ width: 164, height: 32 }} />
                                        </div>

                                    </div>
                                </div>
                                <div css={css`text-align: center; margin-top: 3.8em;`}>
                                    <img src={HANDSHAKE_ICON} alt="handshake_icon" />
                                </div>
                                <div css={[TextStyle11, css`color: ${black2}; text-align: center; margin-top: 1em`]}>
                                    <h2>믿을 수 있는 기업</h2>
                                    <h2>서울도시가스그룹과 서울씨엔지가 함께합니다.</h2>
                                </div>
                            </section>
                            <section css={ContentWrap2}>
                                <div css={TitleWrap}>
                                    <p className="number">1</p>
                                    <h2 css={TextStyle3}>서울이브이는 업계 최고의 전기차 충전서비스를 제공합니다.</h2>
                                </div>

                                <div css={CardWrap}>
                                    <div className="div_card" css={css`margin-right: 4vw; text-align: center`}>
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON1} alt="recpetion_icon1" />
                                        </div>
                                        <p className="bold_text">맞춤형 설치</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            희망 설치 위치, 대수 적극 반영
                                        </div>
                                    </div>

                                    <div className="div_card" css={css`margin-right: 4vw;`}>
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON2} alt="recpetion_icon1" />
                                        </div>
                                        <p className="bold_text">할인 정책</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            제휴카드 할인<br />
                                            APP 포인트 적립 및 할인
                                        </div>
                                    </div>
                                    <div className="div_card">
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON3} alt="recpetion_icon1" />
                                        </div>
                                        <p className="bold_text">저렴한 충전요금</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            프로모션 요금 제공
                                        </div>
                                    </div>
                                </div>

                                <div css={[TitleWrap, css`margin-top: 60px;`]}>
                                    <p className="number">2</p>
                                    <h2 css={TextStyle3}>서울이브이는 신속하고 확실한 유지보수를 책임집니다.</h2>
                                </div>
                                <div css={CardWrap}>
                                    <div className="div_card" css={css`margin-right: 4vw;`}>
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON4} alt="recpetion_icon4" />
                                        </div>
                                        <p className="bold_text">상시 점검</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            상시 모니터링 및<br />
                                            주기적인 자체 현장 점검
                                        </div>
                                    </div>
                                    <div className="div_card" css={css`margin-right: 4vw;`}>
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON5} alt="recpetion_icon5" />
                                        </div>
                                        <p className="bold_text">고객 지원</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            24시간 콜센터 운영<br />
                                            영업배상 책임보험 가입<br />
                                            (대인/대물 10억 한도)
                                        </div>
                                    </div>
                                    <div className="div_card">
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON6} alt="recpetion_icon6" />
                                        </div>
                                        <p className="bold_text">신속 대응</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            고장접수 이후 24시간 이내<br />
                                            현장 출동 원칙
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section css={ContentWrap3}>
                                <div css={TitleWrap}>
                                    <p className="number">3</p>
                                    <h2 css={TextStyle3}>설치부터 유지보수까지, 무상으로 지원해 드립니다.</h2>
                                    <div css={css`display:flex; align-items: center; justify-content: center`}>
                                        <img src={SEOULEV_WHITE_LOGO} alt="seoulev_logo" style={{ width: 180, height: 20 }} />
                                        <p css={[TextStyle3, css`line-height: 36px;`]}>만의 맞춤 운영 컨설팅으로 지금 만나보세요!</p>
                                    </div>
                                </div>
                                <div css={CardWrap}>
                                    <div className="div_card_lg" css={css`margin-right: 3.9vw;`}>
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON7} alt="recpetion_icon7" />
                                        </div>
                                        <p className="bold_text">무상 지원</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            건물 자치 비용 부담 NO <br />
                                            수익사업 위한 외부 마케팅 NO
                                        </div>
                                    </div>
                                    <div className="div_card_lg">
                                        <div className="img_wrap">
                                            <img src={RECEPTION_ICON8} alt="recpetion_icon8" />
                                        </div>
                                        <p className="bold_text">맞춤형 운영 컨설팅</p>
                                        <div css={[TextStyle7, css`color: ${black2}`]}>
                                            기 설치 장비 위탁 운영 컨설팅 <br />
                                            (교체 또는 추가 설치)
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section5">
                                <Link to="/reception" css={css`height: 54px; width: 17vw;`}>
                                    <button style={{ fontWeight: "700" }} css={BtnOUtLineDarkBlue}>설치 문의하기</button>
                                </Link>
                            </section>
                        </TabContent>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const TitleWrap = css`
    text-align: center; 
    justify-content: center; 

    .number {
        font-family: 'Spoqa Han Sans Neo';
        font-weight: 300;
        font-size: 3.1em;
        line-height: 48px;

        margin-bottom: 1.5vh;
    }
`;

const CardWrap = css`
    display: flex;
    justify-content: center;
    margin-top: 2.5em;
    align-items: center;

    .div_card {
        background: ${white};
        border-radius: 20px;

        width: 13.7vw;
        // max-width: 262px;
        min-width: 150px;
        height: 268px;
        padding: 3%;
        padding-top: 40px;
    }

    .div_card_lg {
        background: ${white};
        border-radius: 20px;

        width: 13.7vw;
        // max-width: 262px;
        min-width: 205px;
        height: 230px;
        padding: 3%;
    }

    .img_wrap {
        min-height: 54px;
    }

    .bold_text {
        font-weight: 700;
        font-size: 1.4em;
        line-height: 32px;
        color: ${black2};
        margin: 16px 0 9px 0;
    }
`;

const ContentWrap2 = css`
    background: ${blue};
    padding: 7.11vh 7.54vw;
    color: ${white};
    text-align: center;
`;

const ContentWrap3 = css`
    background: ${primary};
    padding: 5.7vh 7.54vw;
    color: ${white};
    text-align: center;
`;

export default ReceptionGuidePage;
