import { privateApiGetParams, privateApiGet } from '../../apis/api';
import { GET_POINT_CARD_INFO, GET_POINT_LIST } from './types';

export const getPointCardInfo = (id) => {
    // 회원 포인트 내역 조회
    const request = privateApiGet(`/couponPoint/showCardInfo/${id}`).then(response => response);

    return {
        type: GET_POINT_CARD_INFO,
        payload: request
    }
}

export const getPointList = (data) => {
    // 회원 포인트 내역 조회
    const request = privateApiGetParams("/couponPoint/showPointRecord", data).then(response => response);

    return {
        type: GET_POINT_LIST,
        payload: request
    }
}
