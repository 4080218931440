import * as yup from "yup";
import * as messages from "../messages/messages";

export const updatePasswordSchema = yup.object({
    Password: yup
        .string()
        .required(messages.requiredMessage),
    UpdatePassword: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    UpdatePasswordConfirm: yup
        .string()
        .required(messages.requiredMessage)
        .oneOf([yup.ref('UpdatePassword'), null], "비밀번호가 일치하지 않습니다."),

}).required();
