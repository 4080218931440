import { Routes, Route } from "react-router-dom";
import FaqListPage from "./pages/Support/FaqListPage";
import FindLoginIdPage from "./pages/FindLoginId/FindLoginIdPage";
import FindPasswordPage from "./pages/FindPassword/FindPasswordPage";
import LoginPage from "./pages/Login/LoginPage";
import ChargingListPage from "./pages/Mypage/ChargingListPage";
import InfoPage from "./pages/Mypage/InfoPage";
import LeavePage from "./pages/Mypage/LeavePage";
import MembercardPage from "./pages/Mypage/MembercardPage";
import PasswordUpdatePage from "./pages/Mypage/PasswordUpdatePage";
import NoticeDetailPage from "./pages/Notice/NoticeDetailPage";
import NoticeListPage from "./pages/Notice/NoticeListPage";
import ReceptionGuidePage from "./pages/Reception/ReceptionGuidePage";
import ReceptionPage from "./pages/Reception/ReceptionPage";
import RegisterCompletePage from "./pages/Register/RegisterCompletePage";
import RegisterPage from "./pages/Register/RegisterPage";
import SearchStationsPage from "./pages/SearchStations/SearchStationsPage";
import ServiceGuidePage from "./pages/Support/ServiceGuidePage";
import PolicyDetailPage from "./pages/Policy/PolicyDetailPage";
import PaycardPage from "./pages/Mypage/PaycardPage";
import IntroducePage from "./pages/Introduce/IntroducePage2";
import PrivateRoute from "./middleware/PrivateRoute";
import NotFound from "./components/Error/NotFound";
import RegisterRoute from "./middleware/RegisterRoute";
import QRpayPage from "./pages/QRpay/QRpayPage";
import QRpayDetailPage from "./pages/QRpay/QRpayDetailPage";
import QRpayChargingInfoPage from "./pages/QRpay/QRpayChargingInfoPage";
import QRpayResultPage from "./pages/QRpay/QRpayResultPage";
import MainPage2 from "./pages/Main/MainPage2";
import QRplatformPage from "./pages/QRplatform/QRplatformPage";
import PointListPage from "./pages/Mypage/PointPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage2 />} />
      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/register"
        element={<RegisterRoute path="/register" component={RegisterPage} />}
      />
      <Route path="/register/complete" element={<RegisterCompletePage />} />

      <Route path="/find_login_id" element={<FindLoginIdPage />} />
      <Route path="/find_password" element={<FindPasswordPage />} />

      <Route path="/introduce" element={<IntroducePage />} />

      <Route path="/search_stations" element={<SearchStationsPage />} />

      <Route path="/reception" element={<ReceptionPage />} />
      <Route path="/reception_guide" element={<ReceptionGuidePage />} />

      <Route path="/support/notice" element={<NoticeListPage />} />
      <Route path="/support/notice/:id" element={<NoticeDetailPage />} />
      <Route path="/support/service_guide" element={<ServiceGuidePage />} />
      <Route path="/support/faq" element={<FaqListPage />} />
      <Route
        path="/mypage/charging"
        element={
          <PrivateRoute path="/mypage/charging" component={ChargingListPage} />
        }
      />
      <Route
        path="/mypage/point"
        element={
          <PrivateRoute path="/mypage/point" component={PointListPage} />
        }
      />
      <Route
        path="/mypage/info"
        element={<PrivateRoute path="/mypage/info" component={InfoPage} />}
      />
      <Route
        path="/mypage/info/password_update"
        element={
          <PrivateRoute
            path="/mypage/password_update"
            component={PasswordUpdatePage}
          />
        }
      />
      <Route
        path="/mypage/info/leave"
        element={<PrivateRoute path="/mypage/leave" component={LeavePage} />}
      />
      <Route
        path="/mypage/membercard"
        element={
          <PrivateRoute path="/mypage/membercard" component={MembercardPage} />
        }
      />
      <Route
        path="/mypage/paycard"
        element={
          <PrivateRoute path="/mypage/paycard" component={PaycardPage} />
        }
      />

      <Route path="/policy/service" element={<PolicyDetailPage />} />
      <Route path="/policy/privacy" element={<PolicyDetailPage />} />
      <Route path="/policy/service_location" element={<PolicyDetailPage />} />

      <Route path="/QRpay" element={<QRpayPage />} caseSensitive />
      <Route
        path="/QRpay/:qrcode"
        element={<QRpayDetailPage />}
        caseSensitive
      />
      <Route
        path="/QRpay/:qrcode/charging_info"
        element={<QRpayChargingInfoPage />}
        caseSensitive
      />
      <Route
        path="/QRpay/:qrcode/payment_result"
        element={<QRpayResultPage />}
        caseSensitive
      />

      <Route path="/QRplatform/:qrcode" element={<QRplatformPage />} caseSensitive />

      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
}

export default App;
