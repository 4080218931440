/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, TabContent } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ResponseResult } from '../../apis/response';
import Footer from '../../components/Footer/Footer2';
import NavBar from '../../components/NavBar/NavBar';
import Tab from '../../components/Tab/Tab';
import { mypage_tab } from '../../configs/base';
import { getUserMembercard } from '../../stores/actions/user_action';
import { stroke_gray1 } from '../../styles/colors';
import { MyPageWrap } from '../../styles/themes';
import { membercardFormat } from '../../utils/utils';
import { notFoundUserMembercard } from '../../validations/messages/messages';

function MembercardPage() {
    const dispatch = useDispatch();
    const user = useState(useSelector(state => state.user));
    const [Memberard, setMemberard] = useState({});

    useEffect(() => {
        // 멤버십카드 정보 받아옴

        dispatch(getUserMembercard(user[0].id)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    setMemberard(response.payload.data);
                } else {
                    if (response.payload.msg !== notFoundUserMembercard) {
                        alert(response.payload.msg);
                    }
                }
            }
        )
    }, []);

    return (
        <React.Fragment>
            <NavBar />
            <div css={MyPageWrap}>
                <Card>
                    <Card.Body>
                        <h1>마이페이지</h1>
                        <Tab list={mypage_tab} active={3} userId={1}></Tab>
                        <TabContent>
                            <div css={css`margin-top: 119px; border-top: 1px solid ${stroke_gray1}`}>
                                <div css={css`display: flex; font-weight: 400; font-size: 13px; line-height: 19px; justify-content: space-around; margin-top: 27px;`}>
                                    <h2>멤버십카드</h2>
                                    <h2>
                                        {
                                            Object.keys(Memberard).length === 0
                                                ? "등록하신 주소로 회원카드가 발송될 예정입니다."
                                                : Memberard.cardNo
                                                    // TODO 멤버십 카드 번호 전부 보여주기
                                                    ? membercardFormat(Memberard.cardNo)
                                                    : "-"
                                        }
                                    </h2>
                                </div>
                            </div>

                            {/* <div className="info">
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>회원 카드</th>
                                            <td>
                                                {
                                                    Object.keys(Memberard).length === 0 &&
                                                    "등록하신 주소로 회원카드가 발송될 예정입니다."
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div> */}
                        </TabContent>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default MembercardPage;
