import { apiGet, apiGetParams } from '../../apis/api';
import { GET_SEARCH_STATION, GET_STATION, GET_STATION_LIST } from './types';

export const getStationList = (data) => {
    // 충전소 목록 조회
    const request = apiGetParams("/map", data).then(response => response);
   
    return {
        type: GET_STATION_LIST,
        payload: request
    }
}

export const getStation = (stationIdntfr) => {
    // 충전소 상세 조회
    const request = apiGet("/map/show/"+stationIdntfr).then(response => response);

    return {
        type: GET_STATION,
        payload: request
    }
}

export const getSearchStation = (data) => {
    // 충전소 검색
    const request = apiGetParams("/map/search", data).then(response => response);

    return {
        type: GET_SEARCH_STATION,
        payload: request
    }
}