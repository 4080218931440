import { apiGet, apiGetParams } from '../../apis/api';
import { GET_FAQ_CATEGORY, GET_FAQ_LIST } from './types';

export const getFaqCategory = () => {
    // FAQ 카테고리 목록 조회
    const request = apiGet("/faq/getCategoryList").then(response => response);
   
    return {
        type: GET_FAQ_CATEGORY,
        payload: request
    }
}

export const getFaqList = (data) => {
    // FAQ 목록 조회
    const request = apiGetParams("/faq", data).then(response => response);

    return {
        type: GET_FAQ_LIST,
        payload: request
    }
}