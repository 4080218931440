import * as yup from "yup";
import * as messages from "../messages/messages";

export const phoneValidation = yup.object({
    phone: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요.")
}).required();

export const authCodeValidation = yup.object({
    AuthCode: yup
        .string()
        .required(messages.requiredMessage)
        .matches(/^.{6}$/, "6자리로 입력해주세요."),
}).required();
