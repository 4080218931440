export const requiredMessage = "필수 입력입니다.";
export const emailTypeMessage = "이메일 형식이 올바르지 않습니다.";
export const carNumberMessage = "차량번호가 올바르지 않습니다.";
export const onlyNumberPhoneMessage = "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요.";
export const maxTextMessage = "자리 이하로 입력해주세요.";
export const notFoundPolicy = "존재하지 않는 약관입니다.";
export const notFoundUserToPhone = "해당 휴대전화 번호를 가진 회원이 존재하지 않습니다.";
export const confirmPassword = "비밀번호가 일치하지 않습니다.";
export const reloginAlertMessage = "장시간 사용되지 않아 재로그인이 필요합니다.";
export const serverErrAlertMessage = "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.";
export const notFoundUserMembercard = "회원님의 멤버십카드 정보가 존재하지 않습니다.";
export const notFoundUserPaycard = "결제카드 정보가 존재하지 않습니다.";
export const passwordChangeRegexAlertMessage = "영문, 숫자, 특수문자를 조합해서 입력해주세요. (8-16자)";
export const paycardUpdateFailAlertMessage = "결제카드 변경에 실패하였습니다.";
export const paycardStoreFailAlertMessage = "결제카드 등록에 실패하였습니다.";