/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal } from "react-bootstrap";
import { button_text, inputbox_befor_typed } from '../../styles/colors';
import CLOSE_ICON from '../../assets/images/modal_close_icon.png';


export default function ModalPolicy(props) {
    const { policyTitle, content } = props;

    return (
        <Modal
            size="lg"
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            autoFocus
            fullscreen="md-down"
            onHide={props.onHide}
        >
        <div>
            <div css={css`display:flex; padding:2%;font-weight: 700; font-size: 16px; line-height: 150%; color: ${button_text}; justify-content: space-between;`}>
                {policyTitle}
                <div css={css`float: right`}>
                    <img src={CLOSE_ICON} onClick={props.onHide} alt="close_icon" css={css` float:right; &:hover{cursor:pointer}`}/>
                </div>
            </div>
        </div>
        <Modal.Body className="container1 text-center" css={css`padding: 2%; height:580px`}>
          
            <div className="input_div" css={css`height: 100%; overflow: auto; border-radius: 10px; border: 1px solid ${inputbox_befor_typed}; min-height: 88px; padding:20px`} dangerouslySetInnerHTML={{__html: content}}>
            </div>
        </Modal.Body>
      </Modal>
    );
};