/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { alert_red, alert_yellow, background_gray1, black, black2, blue, button_text, charger_gray2, disabled_gray1, disabled_gray2, favorites_yellow, favorite_unactived, footer_gray, footer_gray2, gray3, mypage_subtitle_blue, partners, primary, status_red, stroke_gray1, stroke_gray2, typo_light_blue, white } from './colors';
import * as mq from './media-queries';

// Button
// 비밀번호 변경 팝업
export const BtnOutLineBlue = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid ${typo_light_blue};
  border-radius: 10px;
  background: ${white};
  color: ${typo_light_blue};
  transition: 0.5s;

  &:hover{
    background: ${typo_light_blue};
    color: ${white};
  }

  &:disabled,
  &[disabled]{
    border: 1px solid ${favorite_unactived};
    background: ${white};
    color: ${favorite_unactived};
  }
`;

export const BtnOUtLineDarkBlue = css`
  width: 100%;
  height: 100%;
  border: 1px solid ${primary};
  border-radius: 5px;
  color: ${primary};
  background: ${white};
  &:hover{
    background: ${primary};
    color: ${white};
  }
`;

// 로그인
export const BtnFullBlue2 = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid ${typo_light_blue};
  border-radius: 10px;
  background: ${typo_light_blue};
  color: ${white};
  transition: 0.5s;
  
  &:disabled,
  &[disabled]{
    border: 1px solid ${typo_light_blue};
    background: ${white};
    color: ${typo_light_blue};
  }

  &:hover{
    background: ${white};
    color: ${typo_light_blue};
  }
`;

// 메인페이지 설치 신청하기
export const BtnFullDarkBlue = css`
  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${primary};
  border: 1px solid ${primary};
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: ${white};
  
  &:hover{
    border: 1px solid ${white};
    background: ${white};
    color: ${primary};
  }
`;

export const BtnFullBlueDark = css`
  display: flex;
  justify-content: space-between;

  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${primary};
  border: 1px solid ${primary};
  border-radius: 5px;
  color: ${white};
  
  &:hover{
    border: 1px solid ${black};
    background: ${black};
    color: ${white};
  }
`;

// 모바일 메인페이지 메뉴
export const BtnFullDarkBlueMenu = css`
  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${primary};
  border: 1px solid ${primary};
  color: ${white};
`;

export const BtnFullDark = css`
  padding: 0 11px 0 11px;
  width: 100%;
  height: 100%;
  background: ${black2};
  border: 1px solid ${black2};
  border-radius: 5px;
  color: ${white};

  &:hover{
    border: 1px solid ${gray3};
    background: ${gray3};
    color: ${white};
  }
`;

export const BtnFullWhite = css`
  padding: 10px;
  width: 100%;
  height: 100%;
  background: ${white};
  border: 1px solid ${white};
  border-radius: 5px;
  color: ${black2};

  &:hover {
    border: 1px solid ${footer_gray2};
    background: ${footer_gray2};
  }
`;

export const BtnFullWhiteBlue = css`
  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${white};
  border: 1px solid ${white};
  border-radius: 5px;
  color: ${black2};
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    visibility: hidden;
    width: 0;
    opacity: 0;
  }

  &:hover {
    justify-content: inherit;
    border: 1px solid ${primary};
    background: ${primary};
    color: ${white};

    img {
      visibility: visible;
      width: 20px;
      opacity: 1;
      transition: opacity 600ms;
    }

    span {
        transition: transform 600ms;
        transform:translateX(25px);
    }
  }

`;
export const BtnFullBlackBlue = css`
  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${black2};
  border: 1px solid ${black2};
  border-radius: 5px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    visibility: hidden;
    width: 0;
    opacity: 0;
  }

  &:hover {
    justify-content: inherit;
    border: 1px solid ${primary};
    background: ${primary};
    color: ${white};

    img {
      visibility: visible;
      width: 20px;
      opacity: 1;
      transition: opacity 600ms;
    }

    span {
        transition: transform 600ms;
        transform:translateX(25px);
    }
  }

`;

// 메인페이지 충전소 찾기
export const BtnFullWhiteShadow = css`
  padding: 12px;
  width: 100%;
  height: 100%;
  background: ${white};
  border: 1px solid ${white};
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: ${black2};

  &:hover{
    border: 1px solid ${primary};
    background: ${primary};
    color: ${white};

    img {
      transition: all 0.3s;
      width: 20px !important;
    }

    span {
      animation: moveButton 0.2s linear forwards;
      animation-direction: alternate;
    }
  }

  @keyframes moveButton {
    0% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(30px, 0px);
    }
  }
`;

export const TextStyle1 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
`;

export const TextStyle2 = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.03em;
`;

export const TextStyle3 = css`
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.01em;
`;

export const TextStyle4 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;

export const TextStyle5 = css`
  font-weight: 700;
  // font-size: 8px;
  font-size: 0.1em;
  line-height: 10px;
  letter-spacing: -0.01em;
`;

export const TextStyle6 = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
`;

// 설치문의
export const TextStyle7 = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;

// QR 완료
export const TextStyle8 = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const TextStyle9 = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
`;

export const TextStyle10 = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`;

export const TextStyle11 = css`
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
`;

// 아이디찾기, 공지사항 목록보기
export const BtnOutLineBlack = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid ${black};
  border-radius: 10px;
  background: ${white};
  transition: 0.5s;

  &:hover{
    background: ${black};
    color: ${white};
  }

  &:disabled,
  &[disabled]{
    border: 1px solid ${disabled_gray1};
    background: ${stroke_gray1};
    color: ${disabled_gray2};
  }
`;

// 팝업 RegisterReq 
export const BtnOutLineGray = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid ${stroke_gray2};
  border-radius: 10px;
  background: ${white};
  transition: 0.5s;

  color: ${stroke_gray2};

  &:hover {
    background: ${stroke_gray2};
    color: ${white};
  }
`;

// 마이페이지 비밀번호변경/회원탈퇴
export const BtnOutLineGray2 = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid ${favorite_unactived};
  border-radius: 5px;
  background: ${white};
  transition: 0.5s;

  color: ${button_text};

  &:hover {
    background: ${typo_light_blue};
    color: ${white};
  }
`;

export const BtnFullGray = css`
  width: 100%;
  height: 100%;
  background: ${background_gray1};
  border: 1px solid ${stroke_gray1};
  border-radius: 5px;
  color: ${black2};
  transition: 0.5s;

  &:hover {
    background: ${white};
  }
`;

// 마이페이지 내역다운로드
export const BtnFullGraySquare = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  background: ${stroke_gray1};
  color: ${button_text};
  transition: 0.3s;

  &:hover {
    background: ${typo_light_blue};
    color: ${white};
  }
`;

export const BtnOutLineTransparent = css`
  background-color:transparent;
  width: 96px;
  height: 28px;
  font-weight: 400;
  font-size: 12px;
  line-height: 225.3%;
  border: 1px solid #9D9D9D;
  border-radius: 5px;
  color: ${button_text};
`;

export const BtnFullBlue = css`
  // width: 124px;
  height: 48px;
  border-radius: 30px;
  background: ${typo_light_blue};
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${white};
  border: none;
`;

export const BtnQRBlue = css`
  background: ${primary};
  border: 1px solid ${primary};
  color: ${white};
  font-weight: 500;
  min-width: 100px;
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
`;

// Alert
export const AlertTheme = css`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  width: 500px;

  padding: 1rem 1rem;
  // margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 10px;
  // height: 88px;
`;

export const AlertClose = css`
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
`
export const Alert = {
  "info": css`
    background-color: ${charger_gray2};
    border: 1px solid ${typo_light_blue};
  `,
  "warnning": css`
    background-color: ${alert_yellow};
    border: 1px solid ${favorites_yellow};
  `,
  "error": css`
    background-color: ${alert_red};
    border: 1px solid ${status_red};
  `
}

// Alert
export const footerInfo = css`
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${footer_gray};
`;

export const FooterMobileInfo = css`
  font-weight: 400;
  font-size: 0.75em;
  line-height: 22px;
  text-align: center;
  color: ${footer_gray};
`;

export const ScrollWrap = css`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: move 0.5s infinite alternate;
  color: ${white};

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  @keyframes move {
    0% {
        transform: translate(-50%, -10px);
    }

    100% {
        transform: translate(-50%, -15px);
    }
  }
`;

export const MyPageWrap = css`
  padding: 52px 10%;
  .card {
    min-height: 721px;
    min-width: 615px;
    padding: 2% 5%;
    border: none;

    .card-body {

      h1 {
        padding-bottom: 2%;
        font-size:24px; 
        font-weight: 700;
      }
    
      .nav-tabs {
        margin: 32px 0 32px 0;
      }

      .filter {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
      }

      input[type="checkbox"] {
        :hover {
          cursor: pointer;
        }
      }

      .charging {
        .table>:not(caption)>*>* {
          border-bottom-width: 0;
        }

        .table>:not(:first-of-type) {
          border-top: 1px solid ${stroke_gray1};
        }

        table {
          thead {
            vertical-align: bottom;
            th {
              padding-bottom: 7px;
            }
          }
          tr {
            height: 64px;
          }

          th {
            font-weight: 700;
            font-size: 13px;
            line-height: 19px;
            color: ${mypage_subtitle_blue};
          }

          tbody { 
            min-height: 170px;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;

            tr {
              vertical-align: middle;
              border-top: 1px solid ${stroke_gray1};
              border-bottom: 1px solid ${stroke_gray1};
              :hover {
                background: ${background_gray1};
              }
            }
          }
        }
      }

      // 마이페이지 - 회원정보
      .info {
        .table>:not(caption)>*>* {
          border-bottom-width: 0;
        }

        table {
          input[type="text"]
          {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            outline: none;
          }

          select
          {
            width: 100%;
            height: 100%;
            // background: transparent;
            border: none;
            outline: none;

            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: ${black};
          }

          tbody {
            tr {
              vertical-align: middle;
              height: 60px;
              border-top: 1px solid ${stroke_gray1};
              border-bottom: 1px solid ${stroke_gray1};

              .disabled {
                background: ${background_gray1};
              }

              th {
                min-width: 100px;
                font-weight: 700;
                font-size: 13px;
                line-height: 19px;
                color: ${button_text};
                border-right: 1px solid ${stroke_gray1};
              }

              td {
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: ${black};
                padding: 0 24px;
              }
            }
          }
        }
      }
      
      .paycard {
        table thead th {
          border-bottom: 1px solid ${stroke_gray1};
          padding-bottom: 7px;
        }

        table {
          tbody {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: ${black};
            tr {
              height: 64px;
              border-bottom: 1px solid ${stroke_gray1};
              border-top: 1px solid ${stroke_gray1};
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
`;

export const PartnerWrap = css`
  background-color: ${partners};
  position: relative;
  width: 100%;
  height: 60vh;
`;

export const PartnerMobileWrap = css`
  background-color: ${partners};
  position: relative;
  width: 100%;
  height: auto;
  min-height: 950px;
`;

export const PartnerContent = css`
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    @media screen and (min-width: 1500px) {
        font-size: 68px;
    }

    font-weight: 700;
    font-size: 3.5vw;
    line-height: 84px;
    letter-spacing: -0.01em;
    color: ${blue};
  }

  h5 {
    @media screen and (min-width: 1300px) {
        font-size: 26px;
    }

    font-weight: 700;
    // font-size: 26px;
    font-size: 1.8em;

    line-height: 33px;
    letter-spacing: -0.03em;
    color: ${black2};
  }

  .logos {
    width: 100%;
    margin: 0;
    .logo_div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      div {
          width: 256px;
          text-align: center;
      }
    }
    img{
      display: block;
      width: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }
`;

export const PartnerMobileContent = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-height: 670px;

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
    letter-spacing: -0.01em;
    color: ${blue};
    margin-bottom: 10px;
  }

  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: ${black2};
    margin-bottom: 112px;
  }

  .logos {
    height: 100%;
    // margin-top: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        margin-bottom: 20px;
    }
  }
`;

export const footerStipulation = css`
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: ${black2};
  cursor: pointer;
`;

export const container = css`
  margin: auto;
`;

export const container1 = css`
  margin: auto;
  ${mq.dt} {
    max-width: 1270px;
  }
`;

export const contentsWrap = css`
  min-height: 800px;
  height:100%;
`;

export const mainWrap = css`
  margin: 0 auto;
  overflow: hidden;
`;

export const contents = css`
  position: relative;
`;

export const row = css`
  display: flex;
  width: 100%;
  padding-bottom: 1px;
`;

export const loginTheme = css`
  width: 100%;
  height: 100%;
  display: block;
  justify-content: center !important;
  text-align: center;
`;

export const justifyContentCenterTheme = css`
  justify-content: center;
`;

export const hide = css`
  display: none !important;
`;

export const show = css`
  display: block;
`;