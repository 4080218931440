/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Form, TabContent, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseResult } from '../../apis/response';
import ModalAddress from '../../components/Modal/ModalAddress';
import ModalAlert from '../../components/Modal/ModalAlert';
import ModalLeaveUser from '../../components/Modal/ModalLeaveUser';
import ModalPasswordChange from '../../components/Modal/ModalPasswordChange';
import NavBar from '../../components/NavBar/NavBar';
import Tab from '../../components/Tab/Tab';
import { mypage_tab } from '../../configs/base';
import { getCarList } from '../../stores/actions/car_action';
import { getUser, updateUserInfo } from '../../stores/actions/user_action';
import { stroke_gray1 } from '../../styles/colors';
import { BtnOutLineBlue, BtnOutLineGray2, MyPageWrap } from '../../styles/themes';
import { serverErrAlertMessage } from '../../validations/messages/messages';
import { updateUserInfoSchema } from '../../validations/schemas/UpdateUserInfoSchema';
import { ediDateNoFormat, formatPhoneNumber, makeEncryptData, makeMoid } from '../../utils/utils';
import { editUserDone, editUserIng } from '../../stores/actions/data_action';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer2';

function InfoPage() {
    // 주소 검색
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalShow, setModalShow] = useState(false);
    const [modalAddressShow, setModalAddressShow] = useState(false);
    const [modalPasswordShow, setModalPasswordShow] = useState(false);
    const [modalLeaveShow, setModalLeaveShow] = useState(false);
    const edit_data = useSelector(state => state.data.data);
    const [KgState, setKgState] = useState(false);
    const [KgPhone, setKgPhone] = useState("");

    const [Moid, setMoid] = useState("");
    const [EdiDate, setEdiDate] = useState("");
    const [EncryptData, setEncryptData] = useState("");

    const [IsChanging, setIsChanging] = useState(false);
    const [ResMsg, setResMsg] = useState();
    const [UserInfo, setUserInfo] = useState({});
    const [CarCompany, setCarCompany] = useState([]);
    const [Car, setCar] = useState([]);
    const [CarModel, setCarModel] = useState([]);
    const [UserCar, setUserCar] = useState({});
    const { handleSubmit, register, formState: { errors }, setValue, getValues } = useForm({
        resolver: yupResolver(updateUserInfoSchema),
        reValidateMode: 'onChange',
    });

    const [Loaded, setLoaded] = useState([false, false]);
    const { MCASH_PAYMENT } = window;

    useEffect(() => {
        let kg_result = searchParams.get("kg");
        window.history.replaceState({}, null, window.location.pathname);
        if (kg_result) {
            setIsChanging(true);
            let kg_result_json = JSON.parse(kg_result);

            if (Object.keys(edit_data).length > 0) {
                setValue("Email", edit_data.email, { shouldValidate: true });
                setValue("Rdnmadr", edit_data.rdnmadr, { shouldValidate: true });
                setValue("Adres", edit_data.adres, { shouldValidate: true });
                setValue("ZipCode", edit_data.zipCode, { shouldValidate: true });
                setValue("AdresDetail", edit_data.adresDetail, { shouldValidate: true });
                setValue("CarCompanyName", edit_data.carCompanyName, { shouldValidate: true });
                let editCar = {
                    ...UserCar,
                    nm: edit_data.carCompanyName,
                    id: String(edit_data.carModelId)
                }
                // console.log(editCar);
                setUserCar(editCar);
                setValue("CarNo", edit_data.carNo, { shouldValidate: true });
            }
            if (kg_result_json.Resultcd === "0000") {
                // 본인인증 성공
                setKgState(true);
                setKgPhone(kg_result_json.No);
                setValue("Phone", kg_result_json.No, { shouldValidate: true });
            }
            // TODO
            else {
                alert(kg_result_json.Resultmsg);
            }

            dispatch(editUserDone());
        }
    }, [state]);

    const onSubmitHandler = (data) => {
        let reqParams = {
            "id": parseInt(user.id),
            "email": data.Email,
            "phone": !KgState ? getValues("Phone") : KgPhone,
            "rdnmadr": data.Rdnmadr,
            "adres": data.Adres,
            "zipCode": data.ZipCode,
            "adresDetail": data.AdresDetail,
            "userCarId": data.UserCarId,
            "carModelId": parseInt(data.CarId),
            "carNo": data.CarNo.replace(" ", ""),
            "smsRcptnAgreYn": data.smsRcptnAgreYn === true ? "Y" : "N",
            "emlRcptnAgreYn": data.emlRcptnAgreYn === true ? "Y" : "N",
        }

        dispatch(updateUserInfo(reqParams)).then(
            response => {
                setModalShow(true);
                setResMsg(response.payload.msg);
            }
        )
    }

    const onChangeFormHandler = () => {
        if (!IsChanging) {
            setIsChanging(true);
        }
    }

    const onChangeHandler = () => {
        if (!IsChanging) {
            setIsChanging(true);
        }
    }

    const onClickUpdateHandler = () => {
        // 회원정보 변경 요청시
        setModalShow(false);
        window.location.reload();
    }

    const addressModal = (modal) => {
        setModalAddressShow(modalAddressShow);
    };

    const onAddressModalHandler = (event) => {
        if (!modalAddressShow) {
            setModalAddressShow(true);
        }
    }

    const onChangeCarCompanyHandler = (event) => {
        let cnt = 1;
        let carId = "";
        setCarModel(Car.filter((item, index) => {
            if (item.nm === event.currentTarget.value) {
                if (cnt === 1) {
                    carId = item.id;
                    // setValue("CarId", item.id, { shouldValidate: true });
                }
                cnt++;
                return item.nm === event.currentTarget.value;
            }
        }));

        setValue("CarId", carId, { shouldValidate: true });
    }

    useEffect(() => {
        if (Object.keys(UserInfo).length > 0) {
            if (UserInfo.phone !== getValues("Phone")) {
                if (!IsChanging) {
                    setIsChanging(true);
                }
            }

            if (UserInfo.rdnmadr !== getValues("Rdnmadr")) {
                if (!IsChanging) {
                    setIsChanging(true);
                }
            }
        }
    }, [getValues("Phone"), getValues("Rdnmadr")]);

    useEffect(() => {
        // user 정보 가져오기
        const loadAsync = async () => {
            const script = document.createElement('script');
            script.src = "https://auth.mobilians.co.kr/js/ext/ext_inc_comm.js";
            script.async = false;
            script.addEventListener('load', () => setLoaded([true, Loaded[1]]));
            document.body.appendChild(script);

            try {
                await dispatch(getUser(user.id)).then(
                    response => {
                        // console.log(response);
                        if (response.payload.result === ResponseResult.OK) {
                            setUserInfo(response.payload.data);
                            setValue("Phone", response.payload.data.phone, { shouldValidate: true });
                            setValue("Rdnmadr", response.payload.data.rdnmadr, { shouldValidate: true });

                            dispatch(getCarList()).then(
                                res => {
                                    // console.log(res);
                                    if (res.payload.result === ResponseResult.OK) {
                                        setCar(res.payload.data);
                                        setValue("CarId", response.payload.data.userCar.carModelId);
                                        if (response.payload.data.userCar.carModelId) {
                                            setUserCar(res.payload.data.find(x => x.id === response.payload.data.userCar.carModelId));
                                        }

                                        let carCompanyArray = [];
                                        res.payload.data.map((obj, index) => {
                                            if (carCompanyArray.indexOf(obj.nm) === -1) {
                                                carCompanyArray.push(obj.nm);
                                            }
                                        });
                                        setCarCompany(carCompanyArray);
                                    } else {
                                        alert(res.payload.msg);
                                    }

                                    setLoaded([true, true]);
                                }
                            )

                        } else {
                            alert(response.payload.msg);
                        }
                    }
                );
            } catch (e) {
                alert(serverErrAlertMessage);
            }
        }
        loadAsync();
    }, []);

    useEffect(() => {
        if (!Loaded[0] || !Loaded[1]) {
            return;
        } else {
            const moid = makeMoid();
            const ediDate = ediDateNoFormat();

            setMoid(moid);
            setEdiDate(ediDate);
            setEncryptData(makeEncryptData(ediDate, moid));

            setCarModel(Car.filter((item, index) => {
                return item.id === UserCar.id;
            }));
        }
    }, [Loaded]);

    const onConfirmClickHadnler = (e) => {
        e.preventDefault();
        let reqParams = {
            "id": parseInt(user.id),
            "email": getValues("Email"),
            // "phone": getValues("Phone"),
            "rdnmadr": getValues("Rdnmadr"),
            "adres": getValues("Adres"),
            "zipCode": getValues("ZipCode"),
            "adresDetail": getValues("AdresDetail"),
            // "carCompanyName": getValues("CarCompanyName"),
            "carCompanyName": getValues("CarCompanyName") === "null" ? UserCar.nm : getValues("CarCompanyName"),
            "carModelId": !getValues("CarId") ? UserCar.id : parseInt(getValues("CarId")),
            // "carModelId": parseInt(getValues("CarId")),
            "carNo": getValues("CarNo").replace(" ", "")
        }
        dispatch(editUserIng(reqParams));

        window.name = "op1";
        MCASH_PAYMENT(document.selfForm);
    }

    // return (Object.keys(UserInfo).length !== 0 && Car.length > 0 && Object.keys(UserCar).length !== 0) &&
    return (Object.keys(UserInfo).length !== 0
        //  && Object.keys(UserCar).length !== 0
    ) &&
        <React.Fragment>
            <NavBar />
            <div css={MyPageWrap}>
                <Card>
                    <Card.Body>
                        <h1>마이페이지</h1>
                        <Tab list={mypage_tab} active={2} userId={1}></Tab>
                        <TabContent>
                            <div className="filter" css={css`float:right`}>
                                {/* <Link to="/mypage/info/password_update"> */}
                                <button onClick={e => setModalPasswordShow(true)} css={[BtnOutLineGray2, css`width: 110px; height: 28px; font-weight: 400; font-size: 12px; line-height: 225.3%; margin-right: 12px;`]}>비밀번호 변경</button>
                                {/* </Link> */}
                                {/* <Link to="/mypage/info/leave"> */}
                                <button onClick={e => setModalLeaveShow(true)} css={[BtnOutLineGray2, css`width: 110px; height: 28px; font-weight: 400; font-size: 12px; line-height: 225.3%;`]}>회원탈퇴</button>
                                {/* </Link> */}
                            </div>
                            <div className="info">
                                <Form id="selfForm" onSubmit={onConfirmClickHadnler} name="selfForm" method="post" acceptCharset='euc-kr'>
                                    <input type="hidden" name="CASH_GB" id="CASH_GB" value="CI" />
                                    <input type="hidden" name="CI_SVCID" id="CI_SVCID" value={process.env.REACT_APP_KG_SERVICE_ID} />
                                    <input type="hidden" name="Cryptyn" id="Cryptyn" value="Y" />
                                    <input type="hidden" name="Keygb" id="Keygb" value={1} />
                                    <input type="hidden" name="CALL_TYPE" id="CALL_TYPE" value="P" />
                                    <input type="hidden" name="LOGO_YN" id="LOGO_YN" value="N" />
                                    <input type="hidden" name="CI_Mode" id="CI_Mode" value="61" />
                                    <input type="hidden" name="DI_CODE" id="DI_CODE" value="<%=DI_CODE%>" />
                                    <input type="hidden" name="Siteurl" id="Siteurl" value="<%=Siteurl%>" />
                                    <input type="hidden" name="SUB_CPID" id="SUB_CPID" value="<%=SUB_CPID%>" />
                                    <input type="hidden" name="Callback" id="Callback" size="30" value="<%=Callback%>" />
                                    <input type="hidden" name="Smstext" id="Smstext" size="30" value="<%=Smstext%>" />
                                    <input type="hidden" name="Lmstitle" id="Lmstitle" size="40" value="<%=Lmstitle%>" />
                                    <input type="hidden" name="Tradeid" id="Tradeid" size="30" value="<%=Tradeid%>" />
                                    <input type="hidden" name="PAY_MODE" id="PAY_MODE" size="30" value="10" />
                                    <input type="hidden" name="Okurl" id="Okurl" size="30" value={process.env.REACT_APP_API_URL + "/users/changePhone/result"} />
                                    <input type="hidden" name="Cryptokurl" id="Cryptokurl" size="30" value="Y" />
                                    <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                                    <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                                    <input type="hidden" name="MallUserId" maxLength={20} value="01011111111" placeholder="" />
                                    <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                                    <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                                    <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + "/users/register/result"} />
                                    <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                                    <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                                    <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                                    <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                                </Form>

                                <Form onSubmit={handleSubmit(onSubmitHandler)} onChange={onChangeFormHandler}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <th className="text-center">이름</th>
                                                <td className="disabled" colSpan={3}>
                                                    {UserInfo.nm}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">아이디</th>
                                                <td className="disabled" colSpan={3}>
                                                    {UserInfo.lgnId}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">이메일 <span className="text-danger">*</span></th>
                                                <td colSpan={4}>
                                                    <input type="text" {...register("Email")} placeholder="이메일" defaultValue={edit_data ? edit_data.email : UserInfo.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.Email?.message}
                                                    </Form.Control.Feedback>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">휴대전화번호 <span className="text-danger">*</span></th>
                                                <td className="disabled" colSpan={3}>
                                                    <div css={css`display:flex; justify-content: space-between; align-items: center;`}>
                                                        {/* TODO */}
                                                        {!KgState ? formatPhoneNumber(getValues("Phone")) : formatPhoneNumber(KgPhone)}
                                                        <input {...register("Phone")} type="hidden" defaultValue={!KgState ? formatPhoneNumber(UserInfo.phone) : formatPhoneNumber(KgPhone)} onChange={onChangeHandler} />
                                                        <div css={css`width: 120px; height:28px;`}>
                                                            <button form="selfForm" type="submit" css={[BtnOutLineGray2]}>휴대전화번호변경</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center" rowSpan={2}>주소 <span className="text-danger">*</span></th>
                                                <td className="disabled" colSpan={3}>
                                                    <div css={css`display:flex; justify-content: space-between; align-items: center;`}>
                                                        {getValues("Rdnmadr")}
                                                        <input type="hidden" {...register("Rdnmadr")} defaultValue={UserInfo.rdnmadr} onChange={onChangeHandler} />
                                                        <input type="hidden" {...register("Adres")} defaultValue={UserInfo.adres} onChange={onChangeHandler} />
                                                        <input type="hidden" {...register("ZipCode")} defaultValue={UserInfo.zipCode} onChange={onChangeHandler} />
                                                        <div css={css`width: 120px; height:28px;`}>
                                                            <button type="button" css={BtnOutLineGray2} onClick={onAddressModalHandler}>주소변경</button>
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.Rdnmadr?.message}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <input type="text" {...register("AdresDetail")} placeholder="상세주소를 입력해주세요." defaultValue={UserInfo.adresDetail} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.AdresDetail?.message}
                                                    </Form.Control.Feedback>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">차량정보 <span className="text-danger">*</span></th>
                                                <td css={css` border-right: 1px solid ${stroke_gray1}; width:40%;`}>
                                                    <Form.Group>
                                                        <Form.Select {...register("CarCompanyName")} onChange={onChangeCarCompanyHandler} defaultValue={UserCar && UserCar.nm}>
                                                            <option value="null" key="car_0">{UserCar && UserCar.nm}</option>
                                                            {
                                                                CarCompany.map((obj, index) => {
                                                                    return (
                                                                        <option value={obj} key={"car_" + index}>{obj}</option>
                                                                    );
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </td>
                                                <td css={css`width:40%;`}>
                                                    <Form.Group>
                                                        <Form.Select {...register("CarId")} defaultValue={UserCar && UserCar.id} className={errors.CarId?.message && "is-invalid"}>
                                                            {
                                                                CarModel.map((obj, index) => {
                                                                    return (
                                                                        <option value={obj.id} key={"car_model_" + index}>{obj.modelNm}</option>
                                                                    );
                                                                })
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.CarId?.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">차량번호 <span className="text-danger">*</span></th>
                                                <td colSpan={3}>
                                                    <input type="hidden" {...register("UserCarId")} defaultValue={UserInfo.userCar.id} />
                                                    <input type="text" {...register("CarNo")} placeholder="차량 번호를 입력해주세요." className={errors.CarNo?.message && "is-invalid"} defaultValue={UserInfo.userCar && UserInfo.userCar.carNo} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.CarNo?.message}
                                                    </Form.Control.Feedback>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">마케팅 수신 동의</th>
                                                <td>
                                                    <input type="checkbox" id="SMSagree" {...register("smsRcptnAgreYn")} label="개인정보 수집 및 이용에 동의합니다." defaultChecked={UserInfo.smsRcptnAgreYn === "Y" ? true : false} />
                                                    <label htmlFor="SMSagree" css={css`padding-left: 10px; &:hover{ cursor: pointer;}`}>
                                                        SMS 수신 동의
                                                    </label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id="Emailagree" {...register("emlRcptnAgreYn")} label="개인정보 수집 및 이용에 동의합니다." defaultChecked={UserInfo.emlRcptnAgreYn === "Y" ? true : false} />
                                                    <label htmlFor="Emailagree" css={css`padding-left: 10px; &:hover{ cursor: pointer;}`}>
                                                        Email 수신 동의
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div css={css`margin: 0 auto; width: 200px; height: 48px; margin-top: 32px;`}>
                                        <button css={BtnOutLineBlue} type="submit" disabled={!IsChanging}>회원정보 변경</button>
                                    </div>
                                </Form>
                            </div>
                        </TabContent>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
            <ModalAddress
                addressModal={addressModal}
                show={modalAddressShow}
                onHide={() => setModalAddressShow(false)}
                setModalAddressShow={setModalAddressShow}
                setValue={setValue}
            />
            <ModalAlert
                show={modalShow}
                onClickHandler={onClickUpdateHandler}
                oneButtonTitle={"확인"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
            <ModalPasswordChange
                show={modalPasswordShow}
                onHide={() => setModalPasswordShow(false)}
                id={parseInt(user.id)}
            />
            <ModalLeaveUser
                show={modalLeaveShow}
                onHide={() => setModalLeaveShow(false)}
                id={parseInt(user.id)}
            />

        </React.Fragment>
}

export default InfoPage;
