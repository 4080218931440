import { ResponseResult } from './../../apis/response';
import { LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN, REGIST_USER_ING } from './../actions/types';

const initialState = {};

export const userAction = function (
    state = initialState,
    action
) {
    switch (action.type) {
        case LOGIN_USER:
            if (action.payload.result === ResponseResult.OK) {
                return { id: action.payload.data.user.id, aat: action.payload.data.accessToken, art: action.payload.data.refreshToken }
            } else {
                return { ...state }
            }
        case LOGOUT_USER:
            window.location.href = process.env.REACT_APP_URL;
            return initialState
        case REFRESH_TOKEN:
            // console.log(action);
            return { ...state, aat: action.payload.accessToken, art: action.payload.refreshToken }
        case REGIST_USER_ING:
            // console.log(action.payload);
            return { ...state, data: action.payload }
        default:
            return state;
    }
};

export default userAction;