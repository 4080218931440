/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import FOOTER_LOGO from '../../assets/images/footer_logo.svg';
import FOOTER_LOGO_MOBILE from '../../assets/images/footer_logo.svg';
import { COMPANY_ADDR, COMPANY_CEO, COMPANY_EMAIL, COMPANY_ID, COMPANY_NAME, COPYRIGHT, HELP_CALL } from '../../configs/info';
import { black2, footer_gray, footer_gray2 } from '../../styles/colors';
import { DESKTOP, footer_point, footer_point_up, MOBILE, tb_point } from '../../styles/media-queries';
import { BtnFullBlueDark, footerInfo, FooterMobileInfo, footerStipulation, TextStyle1, TextStyle6 } from '../../styles/themes';
import ModalPolicy from '../Modal/ModalPolicy';
import LOGO from '../../assets/images/logo_black.svg';

import LINE from '../../assets/images/line_gray.png';
import RIGHT_ICON from '../../assets/images/Chevron right.png';

const Footer2 = () => {
    const [ModalPolicyShow, setModalPolicyShow] = useState(false);
    const [policyTitle, setPolicyTitle] = useState("");
    const [ModalContent, setModalContent] = useState("");
    const path = window.location.pathname;

    const onChangeFamilySiteHandler = (event) => {
        let url = event.currentTarget.value;

        if (url !== "null") {
            window.open(url, "_blank");
        }
    }

    return <>
            <footer css={[DESKTOP, FooterWarp]}>
                <div css={FooterContent}>
                    <div>
                        <img src={LOGO} alt="logo" style={{ marginTop: "1.15vh", width: 216, height: 24 }} />
                    </div>

                    <div css={FooterPadding}>
                        <Link to="/introduce" css={footerStipulation}>SEOULEV 스토리</Link>
                        <img src={LINE} alt="line" />
                        <Link to="/search_stations" css={footerStipulation}>충전소 찾기</Link>
                        <img src={LINE} alt="line" />
                        <Link to="/reception_guide" css={footerStipulation}>설치안내</Link>
                        <img src={LINE} alt="line" />
                        <Link to="/support/notice?currentPage=1" css={footerStipulation}>고객센터</Link>
                    </div>

                    <div css={FooterSelectBoxList}>
                        <Form.Select onChange={onChangeFamilySiteHandler} style={{ width: "251px" }}>
                            <option value="null">Family Site 바로가기</option>
                            <option value="https://www.seoulgas.co.kr/">서울도시가스</option>
                            <option value="http://www.seoulcng.co.kr/">서울씨엔지</option>
                        </Form.Select>
                        <div style={{ marginLeft: "8px", height: "44px", width: "200px" }}>
                            <button onClick={() => window.open("https://blog.naver.com/seoulevblog", "_black")} css={[BtnFullBlueDark, TextStyle1]}>
                                <span>서울EV 공식 블로그</span>
                                <img src={RIGHT_ICON} alt="arrow" />
                            </button>
                        </div>
                    </div>

                    <div css={FooterStipulationPadding}>
                        <Link to="/policy/service" css={footerStipulation}>이용약관</Link>
                        <img src={LINE} alt="line" />
                        <Link to="/policy/privacy" css={footerStipulation}>개인정보처리방침</Link>
                        <img src={LINE} alt="line" />
                        <Link to="/policy/service_location" css={footerStipulation}>위치기반서비스 이용약관</Link>
                    </div>
                    <div css={footerContentsWrapTb}>
                        <img src={FOOTER_LOGO} alt="footer_logo" css={css`
                        width:138px ; height:37px;
                        padding-right: 30px;`} />
                        <div css={[footerInfo, FooterInfoDesk]}>
                            <p>상호: {COMPANY_NAME} | 사업자등록번호: {COMPANY_ID} | 대표자: {COMPANY_CEO} | 주소: {COMPANY_ADDR} | 고객센터: {HELP_CALL} | E-mail: {COMPANY_EMAIL}</p>
                            <p>{COPYRIGHT}</p>
                        </div>
                        <div css={[footerInfo, FooterInfoTb]}>
                            <p>상호: {COMPANY_NAME} | 사업자등록번호: {COMPANY_ID} | 대표자: {COMPANY_CEO} | 주소: {COMPANY_ADDR}</p>
                            <p>고객센터: {HELP_CALL} | E-mail: {COMPANY_EMAIL}{COPYRIGHT}</p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer css={[MOBILE, path === "/" || path === "/introduce" || path.includes("/QRpay") || path.includes("/QRplatform") ? FooterMobileWarp : FooterTbWrap]}>
                <div css={FooterMobileContent}>
                    <div style={{ marginBottom: "6vh", marginTop: "10vh" }}>
                        <img src={LOGO} alt="logo" style={{ width: 216, height: 24 }} />
                    </div>
                    <div className="footer_menu">
                        <Link to="/introduce" css={TextStyle6}>SEOULEV 스토리</Link>
                    </div>
                    <div className="footer_menu">
                        <Link to="/search_stations" css={TextStyle6}>충전소 찾기</Link>
                    </div>
                    <div className="footer_menu">
                        <Link to="/reception_guide" css={TextStyle6}>설치안내</Link>
                    </div>
                    <div className="footer_menu" style={{ marginBottom: "4.7vh" }} >
                        <Link to="/support/notice?currentPage=1" css={TextStyle6}>고객센터</Link>
                    </div>
                    <div css={FooterSelectBoxMobileList}>
                        <div style={{ height: "44px", width: "251px", margin: "0 auto", marginBottom: "15px" }}>
                            <button onClick={() => window.open("https://blog.naver.com/seoulevblog", "_black")} css={[BtnFullBlueDark, TextStyle1]}>
                                <span>서울EV 공식 블로그</span>
                                <img src={RIGHT_ICON} alt="arrow" />
                            </button>
                        </div>
                        <Form.Select onChange={onChangeFamilySiteHandler} style={{ width: "251px", margin: "0 auto" }}>
                            <option value="null">Family Site 바로가기</option>
                            <option value="https://www.seoulgas.co.kr/">서울도시가스</option>
                            <option value="http://www.seoulcng.co.kr/">서울씨엔지</option>
                        </Form.Select>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div css={FooterStipulationMobilePadding}>
                            <Link to="/policy/service" css={footerStipulation}>이용약관</Link>
                            <p>|</p>
                            <Link to="/policy/privacy" css={footerStipulation}>개인정보처리방침</Link>
                            <p>|</p>
                            <Link to="/policy/service_location" css={footerStipulation}>위치기반서비스 이용약관</Link>
                        </div>
                    </div>


                    <div css={FooterLogoWrap}>
                        <img src={FOOTER_LOGO_MOBILE} alt="footer_logo" css={css`
                        // height: 37px;
                        width:138px ; height:37px;
                            `} />
                    </div>

                    <div css={FooterMobileInfo}>
                        <p>상호: {COMPANY_NAME} | 사업자등록번호: {COMPANY_ID} | 대표자: {COMPANY_CEO} | 주소: {COMPANY_ADDR} | 고객센터: {HELP_CALL} | E-mail: {COMPANY_EMAIL}{COPYRIGHT}</p>
                    </div>
                </div>
            </footer>
            {/* <footer css={footerWarp}>
                <div css={footerContentsWrapDt}>
                    <div css={footerLeftWrapDt}>
                        <img src={FOOTER_LOGO} alt="footer_logo" css={css`width: 138px; height: 37px;`}/>
                        <div css={footerInfo}>
                            <p>상호: {COMPANY_NAME} | 사업자등록번호: {COMPANY_ID} | 대표자: {COMPANY_CEO}</p>
                            <p>주소: {COMPANY_ADDR} | TEL: {COMPANY_CALL} | E-mail: {COMPANY_EMAIL}</p>
                            <p>{COPYRIGHT}</p>
                        </div>
                    </div>
                    
                    <div>
                        <a onClick={e=> {onClickPolicyHandler("이용약관")}} css={footerStipulation}>이용약관</a> | <a onClick={e=> {onClickPolicyHandler("개인정보처리방침")}} css={footerStipulation}>개인정보처리방침</a> | <a onClick={e=> {onClickPolicyHandler("위치기반서비스 이용약관")}} css={footerStipulation}>위치기반서비스 이용약관</a>
                        <Form.Group className="mt-2" css={css`width:215px; height:36px;  margin-left: auto;`}>
                            <Form.Select onChange={onChangeFamilySiteHandler}>
                                <option value="null">Family Site</option>
                                <option value="https://postbill.co.kr/">포스트빌</option>
                                <option value="https://www.welldonemall.com/">웰던몰</option>
                                <option value="https://nvpn.seoulgas.co.kr/">협력사 VPN</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                <div css={footerContentsWrapTb}>
                    <img src={FOOTER_LOGO} alt="footer_logo" css={css`width: 138px; height: 37px;`}/>
                    <div css={footerInfo}>
                        <p>상호: {COMPANY_NAME} | 사업자등록번호: {COMPANY_ID} | 대표자: {COMPANY_CEO} | 주소: {COMPANY_ADDR} |</p>
                        <p> TEL: {COMPANY_CALL} | E-mail: {COMPANY_EMAIL} | {COPYRIGHT}</p>
                    </div>
                </div>
                <div css={footerContentsWrapTb2}>
                    <div css={footerStipulationPadding}>
                        <a onClick={e=> {onClickPolicyHandler("이용약관")}} css={footerStipulaion}t>이용약관</a> | <a onClick={e=> {onClickPolicyHandler("개인정보처리방침")}} css={footerStipulation}>개인정보처리방침</a> | <a onClick={e=> {onClickPolicyHandler("위치기반서비스 이용약관")}} css={footerStipulation}>위치기반서비스 이용약관</a>
                    </div>
                    <Form.Group className="mt-2" css={css`width:215px; height:36px; margin-left: auto;`}>
                        <Form.Select onChange={onChangeFamilySiteHandler}>
                            <option value="null">Family Site</option>
                            <option value="https://postbill.co.kr/">포스트빌</option>
                            <option value="https://www.welldonemall.com/">웰던몰</option>
                            <option value="https://nvpn.seoulgas.co.kr/">협력사 VPN</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            </footer> */}
            <ModalPolicy
                show={ModalPolicyShow}
                onHide={() => setModalPolicyShow(false)}
                onClickHandler={e => setModalPolicyShow(false)}
                policyTitle={policyTitle}
                content={ModalContent}
            />
        </>
};

const FooterWarp = css`
    position: relative;
    background: ${footer_gray2};
    padding: 3% 15%;
    height: 492px;

    font-family: Spoqa Han Sans Neo;
`;

const FooterTbWrap = css`
    position: relative;
    background: ${footer_gray2};
    height: 100vh;
    padding: 3% 15%;
    text-align: center;
    min-width: ${tb_point};

    font-family: Spoqa Han Sans Neo;
`;

const FooterMobileWarp = css`
    position: relative;
    background: ${footer_gray2};
    height: 100vh;
    padding: 3% 15%;
    text-align: center;

    font-family: Spoqa Han Sans Neo;
`;

const FooterContent = css`
    text-align: center;
    width: 100%;
    height: 100%;
`;

const FooterMobileContent = css`
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    .footer_menu {
        padding-bottom: 2.1vh;
    }

    // div {
    //     text-align: center;
    // }
`;

const FooterInfoDesk = css`
    justify-content: center;
    @media (max-width: ${footer_point}) {
        display: none;
    }
`;

const FooterInfoTb = css`
    justify-content: center;
    @media (min-width: ${footer_point_up}) {
        display: none;
    }
`;

const footerContentsWrapDt = css`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${footer_point}) {
        display: none;
    }
    min-width: ${tb_point};
    padding: 31px 0 0;
`;

const footerContentsWrapTb = css`
    display: flex;
    justify-content: center;
`;

const footerContentsWrapTb2 = css`
    display: flex;
    // min-width: ${tb_point};
    @media (min-width: ${footer_point}) {
    display: none;
    }
`;

const FooterPadding = css`
    padding: 55px 0 61px 0;
    margin: 0 auto;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FooterMobilePadding = css`
    a {
        display: block;
        padding-bottom: 20px;
    }
`;

const FooterLogoWrap = css`
    padding-top: 6.2vh;
    padding-bottom: 3.2vh;
    margin: 0 auto;

    padding-bottom: 5%;
`;

const FooterStipulationPadding = css`
    margin: 0 auto;
    padding: 27px 0 73px 0;
    display: flex;
    justify-content: center;
    
    a {
        font-weight: 400;
        font-size: 0.9em;
        line-height: 1.3em;
        padding: 0 1%;
    }
`;

const FooterStipulationMobilePadding = css`
    display: flex;
    justify-content: space-around;
    align-content: start;
    margin-top: 4.4vh;
    width: 284px;

    a {
        font-weight: 400;
        font-size: 0.75em;
        line-height: 1.5em;
        color: ${footer_gray};
    }
`;

const FooterSelectBoxList = css`
    display: flex;
    justify-content: center;

    select {
        border-radius: 5px; 
        border: none;
        width: 25%;
        height: 44px;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;

        color: ${black2};

        &:hover {
            cursor: pointer;
        }
    }
`;

const FooterSelectBoxMobileList = css`
    select {
        border-radius: 5px; 
        border: none;
        width: 25%;
        height: 44px;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        
        color: ${black2};

        &:hover {
            cursor: pointer;
        }
    }
`;

const footerLeftWrapDt = css`
    display:flex; 
    justify-content: space-between; 
    width: 630px;
`;

export default Footer2;