/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import SEOULEV_LOGO from '../../assets/images/logo.png';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteUser, logoutUser } from '../../stores/actions/user_action';
import { ResponseResult } from '../../apis/response';
import ModalAlert from '../../components/Modal/ModalAlert';


function LeavePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const [modalShow, setModalShow] = useState(false);
    const [IsAgree, setIsAgree] = useState(false);
    const [ResMsg, setResMsg] = useState();
    const [IsReqOK, setIsReqOK] = useState(false);

    const onChangeAgreeHandler = () => {
        setIsAgree(!IsAgree);
    };

    const onClickLogoutHandler = () => {
        // 회원 탈퇴 성공시
        navigate("/");
        dispatch(logoutUser());
    }

    const onClickFailLeaveHandler = () => {
        // 회원 탈퇴 실패시
        setModalShow(false);
    }

    const onClickLeaveHandler = () => {
        dispatch(deleteUser(user.id)).then(
            response => {
                // console.log(response);
                setModalShow(true);
                if (response.payload.result === ResponseResult.OK) {
                    setIsReqOK(true);
                    setResMsg(
                        <React.Fragment>
                            <h2>회원 탈퇴가 완료되었습니다.</h2>
                            <p>그동안 SEOULEV를 이용해주셔서 감사합니다.</p>
                        </React.Fragment>
                    );
                } else {
                    // setResPath("/mypage/info/leave");
                    setResMsg(response.payload.msg);
                }
            }
        )
    }

    return (
        <React.Fragment>
            <Container className="d-flex justify-content-center">
                <div css={wrapStyle}>
                    <div css={css`padding-bottom: 2rem; text-align: center`}>
                        <img css={css`width:30vh`} src={SEOULEV_LOGO} alt="seoulev_logo" />
                    </div>
                    <Card css={css`padding:5%`}>
                        <Card.Body>
                            <div css={css`padding-top: 2rem; padding-bottom: 2rem; text-align:center`}>
                                SEOULEV 회원 탈퇴
                            </div>
                            <div>
                                <div css={css`font-size: 25px; padding-bottom: 2rem; text-align:center`}>
                                    회원 탈퇴에 앞서 <span className="text-danger">유의사항 및 안내</span>를 반드시 읽고 진행해주세요.
                                </div>
                                <div className='d-flex justify-content-center' css={css`padding-top: 2rem; padding-bottom: 2rem; font-size: 18px;`}>
                                    <ul css={css`width: 80%;`}>
                                        <li>* 탈퇴시 고객님께서 보유하셨던 포인트는 모두 소멸처리되며 환불처리가 되지 않습니다.</li>
                                        <li>* 결제할 잔액이 남아있거나 취소/환불 등이 진행중인 회원의 경우 관련 처리가 모두 완료된 이후에 탈퇴가 가능합니다.</li>
                                        <li>* 개인정보를 비롯하여 모든 데이터가 삭제되며 삭제된 데이터는 복구되지 않습니다.</li>
                                        <li>* 회원 탈퇴 후 재가입시에는 신규 가입으로 처리되며, 탈퇴 후 재가입에는 제약이 발생할 수 있습니다.</li>
                                    </ul>
                                </div>
                                <div>
                                    <Form.Check type="checkbox" name="IsAgree" id="IsAgree" checked={IsAgree} onChange={onChangeAgreeHandler} label="위 안내를 모두 확인했습니다." />
                                </div>
                                <Row className='text-center'>
                                    <Col sm={6}>
                                        <Link to="/mypage/info/:id">
                                            <Button variant="outline-dark" css={css`margin-right: 10px; width:100%;`}>탈퇴 취소</Button>
                                        </Link>
                                    </Col>
                                    <Col sm={6}>
                                        <Button css={css`width:100%;`} disabled={!IsAgree} onClick={onClickLeaveHandler}>확인</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
            <ModalAlert
                show={modalShow}
                onClickHandler={IsReqOK ? onClickLogoutHandler : onClickFailLeaveHandler}
                oneButtonTitle={"메인화면으로"}
                alertContent={ResMsg}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
};

const wrapStyle = css`
    width: 60%;;
`

export default LeavePage;