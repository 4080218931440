/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Card, Form, TabContent } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ResponseResult } from "../../apis/response";
import NavBar from "../../components/NavBar/NavBar";
import Pagenate from "../../components/Pagenate/Pagenate";
import Tab from "../../components/Tab/Tab";
import { mypage_tab } from "../../configs/base";
import {
  mypage_filter_dt,
  mypage_filter_tb,
  tb_point,
} from "../../styles/media-queries";
import { BtnFullGraySquare, MyPageWrap } from "../../styles/themes";
import {
  today,
  monthBefore,
  formatYYYYMMDDHHmmss,
  moneyFormat,
  last_month_end_day,
  last_month_start_day,
} from "../../utils/utils";
import { primary, typo_light_blue } from "../../styles/colors";
import Footer from "../../components/Footer/Footer2";
import {
  getPointCardInfo,
  getPointList,
} from "../../stores/actions/point_action";

function PointListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useState(useSelector((state) => state.user));
  const params = new URLSearchParams(window.location.search);
  const Month = today("mm") + " 월";
  const LastMonth = monthBefore("mm", 1) + " 월";

  const [Point, setPoint] = useState([]);
  const [cardInfo, setCardInfo] = useState(null);
  const [SearchStartDate, setSearchStartDate] = useState(
    today("yyyymm") + "-01"
  );
  const [SearchEndDate, setSearchEndDate] = useState(today());

  const [currentPage, setCurrentPage] = useState(params.get("page") ?? "1");
  const [totalPage, setTotalPage] = useState();
  const cPage = params.get("page") ?? "1";
  const [IsLoading, setIsLoading] = useState(false);

  const pointType = {
    PURCHASE_PAY: "포인트 충전",
    PURCHASE_COUPON: "쿠폰 포인트 적립",
    REWARD: "적립률 적립",
    USE: "포인트 사용",
    PURCHASECANCEL: "포인트 구매취소",
    REWARDCANCEL: "적립률 취소",
    ADMINPLUS: "관리자포인트 충전",
    ADMINMINUS: "관리자포인트 소멸",
    EXPIRE: "포인트 소멸",
  };

  // 기간 검색 관련
  const onClick1MonthHandler = () => {
    setSearchStartDate(last_month_start_day());
    setSearchEndDate(last_month_end_day());
  };

  const onClickThisMonthHandler = () => {
    setSearchStartDate(today("yyyymm") + "-01");
    setSearchEndDate(today());
  };

  const onSearchStartDateChangeHandler = (event) => {
    setSearchStartDate(event.target.value);
  };

  const onSearchEndDateChangeHandler = (event) => {
    setSearchEndDate(event.target.value);
  };

  const onClickSearchDateHandler = () => {
    setIsLoading(true);
    navigate(
      "?page=" + 1 + "&dts=" + SearchStartDate + "&dte=" + SearchEndDate
    );
  };

  // 페이지 클릭
  const onClickPageChangeHandler = (page) => {
    setCurrentPage(page);
    navigate("?page=" + page);
  };

  // 포인트 카드 번호 조회 && 전체 포인트 조회
  useEffect(() => {
    dispatch(getPointCardInfo(user[0].id)).then((response) => {
      if (response.payload.result === ResponseResult.OK) {
        setCardInfo(response.payload.data);
      } else {
        alert(response.payload.msg);
      }
    });
  }, []);

  useEffect(() => {
    if (cardInfo !== null) {
      let reqParams = {
        pointType: Object.keys(pointType).join(","),
        cardNumber: cardInfo.membership_card_number,
        page: currentPage,
        perPage: 10,
        dts: SearchStartDate,
        dte: SearchEndDate,
      };

      dispatch(getPointList(reqParams)).then((response) => {
        if (response.payload.result === ResponseResult.OK) {
          setPoint(response.payload.data.items);
          setTotalPage(parseInt(response.payload.data.last_page));
        } else {
          alert(response.payload.msg);
        }
        setIsLoading(false);
      });
    }
  }, [cardInfo]);

  useEffect(() => {
    if (cardInfo !== null) {
      let reqParams = {
        pointType: Object.keys(pointType).join(","),
        cardNumber: cardInfo.membership_card_number,
        page: cPage,
        perPage: 10,
        dts: SearchStartDate,
        dte: SearchEndDate,
      };

      dispatch(getPointList(reqParams)).then((response) => {
        if (response.payload.result === ResponseResult.OK) {
          setPoint(response.payload.data.items);
          // TODO
          // currentPage가 NaN값이 나와서 찾아보니 서버로부터 받아오는 값이 잘못됨을 확인
          // response 값으로는 current_page를 넘겨주는데, 여기서는 page를 받고 있었기 때문
          // page -> current_page로 바꿔서 값을 받아와서 currentPage 상태값에 넘겨줌으로서 문제 해결
          setCurrentPage(parseInt(response.payload.data.current_page));
          // setCurrentPage(parseInt(response.payload.data.page));
          setTotalPage(parseInt(response.payload.data.last_page));
        } else {
          alert(response.payload.msg);
        }
        setIsLoading(false);
      });
    }
  }, [currentPage, cPage, params.get("dts"), params.get("dte")]);

  return (
    <>
      <NavBar />
      <div css={MyPageWrap}>
        <Card>
          <Card.Body>
            <h1>마이페이지</h1>
            <Tab list={mypage_tab} active={1} userId={1}></Tab>
            <TabContent>
              <div className="filter">
                <div css={filterDownWrap}>
                  <p>보유 포인트</p>
                  <h1>{cardInfo ? moneyFormat(Number(cardInfo.total_point ?? 0)) : 0} P</h1>
                </div>
                <div css={filterDayDtWrap}>
                  <button
                    css={[BtnFullGraySquare, filterDayWrap]}
                    onClick={onClick1MonthHandler}
                  >
                    {LastMonth}
                  </button>
                  <button
                    css={[BtnFullGraySquare, filterDayWrap]}
                    onClick={onClickThisMonthHandler}
                  >
                    {Month}
                  </button>
                  <Form.Control
                    value={SearchStartDate}
                    type="date"
                    css={css`
                      margin-right: 10px;
                    `}
                    onChange={onSearchStartDateChangeHandler}
                  />
                  <h1
                    css={css`
                      margin-right: 10px;
                    `}
                  >
                    -
                  </h1>
                  <Form.Control
                    value={SearchEndDate}
                    type="date"
                    css={css`
                      margin-right: 10px;
                    `}
                    onChange={onSearchEndDateChangeHandler}
                  />
                  <button
                    css={[
                      BtnFullGraySquare,
                      css`
                        margin-left: 8px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 225.3%;
                      `,
                    ]}
                    onClick={onClickSearchDateHandler}
                  >
                    조회
                  </button>
                </div>
              </div>
              <div className="tb_filter" css={filterDayTbWrap}>
                <button
                  css={[BtnFullGraySquare, filterDayWrap]}
                  onClick={onClick1MonthHandler}
                >
                  {LastMonth}
                </button>
                <button
                  css={[BtnFullGraySquare, filterDayWrap]}
                  onClick={onClickThisMonthHandler}
                >
                  {Month}
                </button>
                <Form.Control
                  value={SearchStartDate}
                  type="date"
                  css={css`
                    margin-right: 10px;
                  `}
                  onChange={onSearchStartDateChangeHandler}
                />
                <h1
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  -
                </h1>
                <Form.Control
                  value={SearchEndDate}
                  type="date"
                  css={css`
                    margin-right: 10px;
                  `}
                  onChange={onSearchEndDateChangeHandler}
                />
                <button
                  css={[
                    BtnFullGraySquare,
                    css`
                      margin-left: 8px;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 225.3%;
                    `,
                  ]}
                  onClick={onClickSearchDateHandler}
                >
                  조회
                </button>
              </div>
              <div
                css={css`
                  overflow-x: auto;
                `}
                className="charging"
              >
                <table
                  className="text-center"
                  css={css`
                    min-width: ${tb_point};
                    width: 100%;
                  `}
                >
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="40%" />
                    <col width="20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>적립/이용일시</th>
                      <th>내용(적립 사유, 사용 충전소)</th>
                      <th>적립/사용 포인트</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Point.length <= 0 ? (
                      <tr>
                        <td colSpan={8}>포인트 내역이 존재하지 않습니다.</td>
                      </tr>
                    ) : (
                      Point.map((obj, index) => {
                        return (
                          <tr key={index}>
                            <td>{pointType[obj.point_type] ?? "구분없음"}</td>
                            <td>
                              {formatYYYYMMDDHHmmss(obj.created_at) ?? "-"}
                            </td>
                            <td>{obj.description ?? "-"}</td>
                            <td>{!obj.point ? "-" : moneyFormat(Number(obj.point))}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </TabContent>
            {totalPage > 1 && (
              <Pagenate
                currentPage={currentPage}
                totalPage={totalPage}
                onClickPageChange={onClickPageChangeHandler}
              />
            )}
          </Card.Body>
        </Card>
      </div>
      <div
        css={css`
          width: 100%;
          height: 100vh;
        `}
      >
        <Footer />
      </div>
    </>
  );
}

const filterDownWrap = css`
  height: 28px;
  min-width: 100px;
  p {
    font-size: 13px;
    color: ${primary};
    font-weight: bolder;
    padding-bottom: 10px;
    letter-spacing: -1px;
  }
  h1 {
    color: ${typo_light_blue};
  }
  @media (min-width: ${tb_point}) {
    width: 136px;
  }
`;

const filterDayDtWrap = css`
  height: 28px;
  display: flex;
  ${mypage_filter_tb} {
    display: none;
  }
`;

const filterDayTbWrap = css`
  display: flex;
  ${mypage_filter_dt} {
    display: none;
  }
`;

const filterDayWrap = css`
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 225.3%;
  min-width: 50px;
`;

export default PointListPage;
