export const color1 = "#FF6900";
export const color2 = "#F6993F";
export const color3 = "#6E73FF";
export const color4 = "#3596FF";
export const color5 = "#BBBDBF";
export const color6 = "#808184";
export const backgroundBlock = "#1c1c1c";

// 색상 정의
export const light_blue = "#27BDFF";
export const typo_light_blue = "#0094FF";
export const stroke_gray1 = "#EEEEEE";
export const status_green = "#82EF40";
export const status_red = "#FD6060";
export const favorites_yellow = "#FFD84E";
export const favorite_unactived = "#D9D9D9";
export const background_gray1 = "#FAFAFA";
export const mypage_subtitle_blue = "#15308A";
export const button_text = "#444444";
export const stroke_gray2 = "#9D9D9D";
export const white = "#FFFFFF";
export const inputbox_befor_typed = "#C1C1C1";
export const inputbox_stroke = "#CCCCCC";

// 추가 색상 정의
export const primary = "#1428A0";
export const blue = "#2085E5";
export const gray3 = "#828282";
export const partners = "#EFFBFF";
export const secondary = "#00B7E4";

// custom
export const footer_gray = "#3A3A3A";
export const footer_gray2 = "#F2F2F2";
export const black = "#000000";
export const introduce_gray = "#FBFBFB";
export const black2 = "#333333";
export const scroll_gray = "#F5F5F5";
export const charger_gray = "#FDFDFD";
export const charger_gray2 = "#EBF6FF";
export const red = "#FF4242";
export const disabled_gray1 = "#BFBFBF";
export const disabled_gray2 = "#757575";
export const green = "#41B3D3";
export const background_green = "#F5FDFF";

export const alert_yellow = "#FFF9E2";
export const alert_red = "#FFEFEF";
