import crypto from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';

export const UtctoLocaleFormmatter = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return year + "-" + (month >= 10 ? month : "0" + month) + "-" + (day >= 10 ? day : "0" + day);
};

export const formatYYYYMMDD = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return year + "-" + (month >= 10 ? month : "0" + month) + "-" + (day >= 10 ? day : "0" + day);
}

export const TodayFormatYYYYMMDDHHmmssSSS = () => {
    const date = new Date();

    const year = date.getFullYear();

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');

    const millisecond = date.getMilliseconds();
    // 무조건 String 으로 반환해야 함.
    return `${year}${month}${day}${hour}${minute}${second}${millisecond}`;
}

export const formatYYYYMMDDHHmmss = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return year + "-" + (month >= 10 ? month : "0" + month) + "-" + (day >= 10 ? day : "0" + day) + " " + (hour >= 10 ? hour : "0" + hour) + ":" + (minute >= 10 ? minute : "0" + minute) + ":" + (second >= 10 ? second : "0" + second);
}

export const last_month_start_day = () => {
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    if (lastDayOfMonth.getMonth() + 1 < 10) {
        return `${lastDayOfMonth.getFullYear()}-0${lastDayOfMonth.getMonth() + 1}-01`;
    } else {
        return `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth() + 1}-01`;
    }
}

export const last_month_end_day = () => {
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    if (lastDayOfMonth.getMonth() + 1 < 10) {
        return `${lastDayOfMonth.getFullYear()}-0${lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate()}`;
    } else {
        return `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate()}`;
    }
}

export const today = (format = "yyyymmdd") => {
    let today = new Date();
    let result = [];

    if (format.includes('yyyy')) {
        result.push(today.getFullYear());
    }

    if (format.includes('mm')) {
        result.push(today.getMonth() + 1 >= 10 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1));
    }

    if (format.includes('dd')) {
        result.push(today.getDate() >= 10 ? today.getDate() : "0" + today.getDate());
    }

    return result.join('-');
}

export const todayNoFoarmat = () => {
    let today = new Date();
    let result = "";

    result += today.getFullYear();
    result += today.getMonth() + 1 >= 10 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1);
    result += today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();

    return result;
};

export const ediDateNoFormat = () => {
    let today = new Date();
    let result = "";

    result += today.getFullYear();
    result += today.getMonth() + 1 >= 10 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1);
    result += today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();
    result += today.getHours() >= 10 ? today.getHours() : "0" + today.getHours();
    result += today.getMinutes() >= 10 ? today.getMinutes() : "0" + today.getMinutes();
    result += ('0' + today.getSeconds()).slice(-2);
    return result;
};

export const day7Before = (format = "yyyymmdd", date) => {
    let day = new Date(date);

    day.setDate(day.getDate() - 7);

    let result = [];

    if (format.includes('yyyy')) {
        result.push(day.getFullYear());
    }

    if (format.includes('mm')) {
        result.push(day.getMonth() + 1 >= 10 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1));
    }

    if (format.includes('dd')) {
        result.push(day.getDate() >= 10 ? day.getDate() : "0" + day.getDate());
    }

    return result.join('-');
}

export const monthBefore = (format = "yyyymmdd", numOfMonths, date) => {
    let day = new Date();
    day.setMonth(day.getMonth() - numOfMonths);

    let result = [];

    if (format.includes('yyyy')) {
        result.push(day.getFullYear());
    }

    if (format.includes('mm')) {
        result.push(day.getMonth() + 1 >= 10 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1));
    }

    if (format.includes('dd')) {
        result.push(day.getDate() >= 10 ? day.getDate() : "0" + day.getDate());
    }

    return result.join('-');
}

export const randomStr = (length) => {
    const characters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters[Math.floor(Math.random() * (charactersLength - 1))];
    }

    return result;
}

export const makeMoid = () => {
    let result = "billingKey" + todayNoFoarmat() + randomStr(20);
    return result;
}

export const makeEncryptData = (edidate, moid) => {
    // const hash = crypto.createHmac('sha256', edidate + process.env.REACT_APP_SMARTRO_MID + moid + "SMARTRO!@#", secret)
    // crypto.
    const hashtext = (crypto.SHA256(edidate + process.env.REACT_APP_SMARTRO_MID + moid + "SMARTRO!@#"));
    return Base64.stringify(hashtext);
}

export const makePayEncryptData = (edidate, amt) => {
    const hashtext = (crypto.SHA256(edidate + process.env.REACT_APP_SMARTRO_QR_MID + amt + process.env.REACT_APP_SMARTRO_QR_MERCAHNT));
    return Base64.stringify(hashtext);
}


export const base64Encoding = (string) => {
    return Base64.stringify(string);
}

export const getLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            const now = new Date();
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // console.log(position);
                    resolve({
                        err: 0,
                        time: now.toLocaleTimeString(),
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (err) => {
                    // console.log(err);
                    resolve({
                        err: -1,
                        latitude: -1,
                        longitude: -1,
                    });
                },
                { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 }
            );
        } else {
            reject({ error: -2, latitude: -1, longitude: -1 });
        }
    });
}

export const moneyFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const membercardSecret = (membercard) => {
    return membercard.substr(0, 4) + " - **** - **** - " + membercard.substr(12, 16);
}

// TODO 멤버십 카드 번호 전부 보여주기 포맷팅
export const membercardFormat = (membercard) => {
    // 정규식을 사용하여 숫자만 남기고 나머지 문자 제거
    const cleaned = String(membercard).replace(/\D/g, '');

    // 4자리씩 끊어서 하이픈(-) 추가 (짧은 경우 앞에서부터 반복)
    const formatted = cleaned.replace(/(\d{1,4})/g, (_, group) => group + '-').slice(0, -1);

    return formatted;
}

// TODO 휴대폰 번호 포맷팅
export const formatPhoneNumber = (phoneNumber) => {
    // 정규식을 사용하여 숫자만 남기고 나머지 문자 제거
    const cleaned = String(phoneNumber).replace(/\D/g, '');

    // 정규식을 사용하여 휴대폰 번호에 하이픈(-) 추가
    const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

    return formatted;
}