/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ResponseResult } from '../../apis/response';
import Footer from '../../components/Footer/Footer2';
import NavBar from '../../components/NavBar/NavBar';
import Pagenate from '../../components/Pagenate/Pagenate';
import Tab from '../../components/Tab/Tab';
import { support_tab } from '../../configs/base';
import { getFaqCategory, getFaqList } from '../../stores/actions/faq_action';
import { white } from '../../styles/colors';
import { BtnFullGray } from '../../styles/themes';

function FaqListPage() {
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [Category, setCategory] = useState(["전체"]);
    const [Faq, setFaq] = useState([]);
    const [SelectCategory, setSelectCategory] = useState("전체");

    const [currentPage, setCurrentPage] = useState(params.get("currentPage") ?? "1");
    const [totalPage, setTotalPage] = useState();
    const cPage = params.get("currentPage");

    // 카테고리 클릭
    const onClickCategoryHandler = (event) => {
        setCurrentPage(1);
        if (SelectCategory !== event.currentTarget.innerHTML) {
            setSelectCategory(event.currentTarget.innerHTML);
        }
    }

    // 페이지 클릭
    const onClickPageChangeHandler = (page) => {
        setCurrentPage(page);
        navigate("?currentPage=" + page);
    }

    useEffect(() => {
        // FAQ 목록 받아오기
        dispatch(getFaqCategory()).then(response => {
            if (response.payload.result === ResponseResult.OK) {
                setCategory(["전체", ...response.payload.data]);
            } else {
                alert(response.payload.msg);
            }
        });

        let reqParams = {
            "category": SelectCategory,
            "currentPage": 1
        }

        // FAQ 리스트 받아오기
        dispatch(getFaqList(reqParams)).then(
            response => {
                // console.log(response);
                if (response.payload.result === ResponseResult.OK) {
                    setFaq(response.payload.data.data);
                    setTotalPage(parseInt(response.payload.data.totalPage));
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }, [SelectCategory]);

    useEffect(() => {
        let reqParams = {
            "category": SelectCategory,
            "currentPage": currentPage
        }

        dispatch(getFaqList(reqParams)).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    setFaq(response.payload.data.data);
                    setCurrentPage(parseInt(response.payload.data.currentPage));
                    setTotalPage(parseInt(response.payload.data.totalPage));
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }, [currentPage, cPage])

    return (
        <React.Fragment>
            <NavBar />
            <div className="faq">
                <Card>
                    <Card.Body>
                        <div className="menu_tab">
                            <h1>고객센터</h1>
                            <Tab list={support_tab} active={2}></Tab>
                        </div>
                        <div className="content">
                            <div css={css`display: flex;`}>
                                {
                                    Category.map((value, index) => {
                                        return (
                                            <div key={index} css={css`min-width: 100px; height: 32px; padding-right: 8px;`}>
                                                <button css={[BtnFullGray, css`font-weight: 400; font-size: 12px; line-height: 12px;`, value === SelectCategory ? css`background: ${white}` : ""]} key={index} onClick={onClickCategoryHandler}>{value}</button>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <Accordion flush>
                                {
                                    Faq.length !== 0
                                        ? <div className="faq_list" css={css`margin-top:3%; max-height: 548px; overflow-y: auto;`}>
                                            {
                                                Faq.map((obj, index) => {
                                                    return (
                                                        <Accordion.Item eventKey={obj.id.toString()} key={index}>
                                                            <Accordion.Header>{obj.sj}</Accordion.Header>
                                                            <Accordion.Body css={css`min-height: 200px; line-height:1.5;`}>
                                                                <div dangerouslySetInnerHTML={{ __html: obj.cn }}>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    );
                                                })
                                            }
                                        </div>
                                        : <div css={NotExistDiv}>자주 하는 질문 내역이 존재하지 않습니다.</div>
                                }
                            </Accordion>
                            <Pagenate currentPage={currentPage} totalPage={totalPage} onClickPageChange={onClickPageChangeHandler} />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div css={css`width: 100%; height: 100vh`}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const NotExistDiv = css`
    margin-top: 3%;
    max-height: 548px;
    text-align: center;
`;
export default FaqListPage;