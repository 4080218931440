/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ResponseResult } from '../../apis/response';
import NavBar from '../../components/NavBar/NavBar';
import Tab from '../../components/Tab/Tab';
import { support_tab } from '../../configs/base';
import { getNotice } from '../../stores/actions/notice_action';
import { inputbox_stroke } from '../../styles/colors';
import { BtnOutLineBlack } from '../../styles/themes';
import { UtctoLocaleFormmatter } from '../../utils/utils';

function NoticeDetailPage() {
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Notice, setNotice] = useState();

    const onClickGoBackHandler = () => {
        navigate(-1);
    }

    useEffect(() => {
        // 공지사항 상세조회 API 호출
        dispatch(getNotice(id)).then(
            response => {
                if (response.payload.result === ResponseResult.OK) {
                    setNotice(response.payload.data);
                } else {
                    alert(response.payload.msg);
                }
            }
        )
    }, []);

    return (
        <React.Fragment>
            <NavBar />
            <div className="notice">
                <Card>
                    <Card.Body>
                        <div className="menu_tab">
                            <h1>고객센터</h1>
                            <Tab list={support_tab} active={0}></Tab>
                        </div>

                        <div className="content" css={css`display:flex; border: 1px solid ${inputbox_stroke}; border-radius: 10px; padding: 14px 25px; font-weight: 400; font-size: 14px; line-height: 20px;`}>
                            <div css={css`width: 50px; border-right: 1px solid ${inputbox_stroke}`}>
                                제목
                            </div>
                            <div css={css`padding-left: 11px;`}>
                                {Notice?.sj || ''}
                            </div>
                            <div css={css`margin-left: auto;`}>
                                {Notice?.regDt ? UtctoLocaleFormmatter(Notice?.regDt) : ''}
                            </div>
                        </div>
                        <div className="content" css={css`border: 1px solid ${inputbox_stroke}; border-radius: 10px; padding: 14px 25px;`}>
                            {
                                Notice &&
                                <div className="detail-content" dangerouslySetInnerHTML={{ __html: Notice.cn || '' }} css={cnStyle} />
                            }
                        </div>
                        <div className="content" css={css`width: 144px; height: 64px;`}>
                            <button type="button" css={BtnOutLineBlack} onClick={onClickGoBackHandler}>목록보기</button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}

const cnStyle = css`
    width:100%;
    line-height:1.5;
    img {
        max-width: 100% !important;
        height: auto !important;
    }
`;

export default NoticeDetailPage;